/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOGEVENT, MODULE, TASK } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import BossDialog from "../../components/BossDialog";
import { Schedule } from "@mui/icons-material";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { months } from "moment";

export const JobsScheduleEditForm = (props) => {

  const [scheduledataItem, setScheduleDataItem] = useState({
    Minutes: '*',
    Hours: '*',
    Days: '*',
    Months: '*',
    Weeks: '*',
  });

  const [dataItem, setDataItem] = useState({
    Name: '',
    Description: '',
    Command: '',
    Schedule: '',
    Task: { _id: TASK.AutoBackup, Description: Object.keys(TASK).find((x) => TASK[x] == TASK.AutoBackup) },
    ExecuteShell: '',
    Disable: false,
    EnableErrorLog: false,
    Archive: false
  });
  const [showForm, setShowForm] = useState(false);
  const lang = useTranslation();

  useEffect(() => {
    if (props.item.SID > 0) {
      setEditItem();
    }

  }, [])

  const handleScheduleChange = (e) => {
    setScheduleDataItem({ ...scheduledataItem, [e.target.name]: e.target.value });
  }
  const handleChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }
  const [showCommandInput, setShowCommandInput] = useState(false);

  function setEditItem() {
    let scheduleArray = props.item.Schedule?.split(" ");
    let data = {
      ...props.item,
      Name: props.item.copy ? 'copy of ' + props.item.Name : props.item.Name,
      Description: props.item.copy ? 'copy of ' + props.item.Description : props.item.Description,
      Command: props.item.Command ?? "",
      Disable: props.item.Disable ?? "",
      EnableErrorLog: props.item.EnableErrorLog,
      Archive: props.item.Archive
    }

    let ScheduleData = scheduleArray.length > 0 ? {
      Minutes: scheduleArray[0] ?? '*',
      Hours: scheduleArray[1] ?? '*',
      Days: scheduleArray[2] ?? '*',
      Months: scheduleArray[3] ?? '*',
      Weeks: scheduleArray[4] ?? '*',
    } : {}
    setScheduleDataItem(ScheduleData)
    setDataItem(data);
    setShowForm(true);

  }

  const isValid = () => {
    const regex = /^[0-9*]*$/;

    if (dataItem.Name == "" || dataItem.Name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.Task).length == 0) {
      toast.error(`${lang.please_select_task_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    console.log(dataItem.Task)
    if (dataItem.Task?.Description == TASK.RunAPI) {
      //console.log(dataItem.Task)
      if (dataItem.Command == "" || dataItem.Command == undefined) {
        toast.error(`${lang.please_enter_command_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }


    // {dataItem.Task?._id == TASK.RunAPI && 

    // if (scheduledataItem.Minutes === '' || !regex.test(scheduledataItem.Minutes)) {
    //   toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Minutes"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    // if (scheduledataItem.Minutes > 59 || scheduledataItem.Minutes < 0) {
    //   toast.error(`${"Minutes" + " " + lang.can_not_greater_than_error_message + ": 59"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }

    // if (scheduledataItem.Hours === '' || !regex.test(scheduledataItem.Hours)) {
    //   toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Hours"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    // if (scheduledataItem.Hours > 23 || scheduledataItem.Hours < 1) {
    //   scheduledataItem.Hours === '0' ? toast.error(`${"Hours" + " " + lang.can_not_less_than_error_message + ": 1"}`) : toast.error(`${"Hours" + " " + lang.can_not_greater_than_error_message + ": 23"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    // if (scheduledataItem.Days === '' || !regex.test(scheduledataItem.Days)) {
    //   toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Days"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    // if (scheduledataItem.Days > 31 || scheduledataItem.Days < 1) {
    //   dataItem.Days < 1 ? toast.error(`${"Days" + " " + lang.can_not_less_than_error_message + ": 1"}`) : toast.error(`${"Days" + " " + lang.can_not_greater_than_error_message + ": 31"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    // if (scheduledataItem.Months === '' || !regex.test(scheduledataItem.Months)) {
    //   toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Months"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    // if (scheduledataItem.Months > 12 || scheduledataItem.Months < 1) {
    //   scheduledataItem.Months < 1 ? toast.error(`${"Months" + " " + lang.can_not_less_than_error_message + ": 1"}`) : toast.error(`${"Months" + " " + lang.can_not_greater_than_error_message + ": 12"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    // if (scheduledataItem.Weeks === '' || !regex.test(scheduledataItem.Weeks)) {
    //   scheduledataItem.Weeks < 1 ? toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Weeks"}`) : toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Weeks"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    // if (scheduledataItem.Weeks > 7 || scheduledataItem.Weeks < 1) {
    //   scheduledataItem.Weeks < 1 ? toast.error(`${"Weeks" + " " + lang.can_not_less_than_error_message + ": 1"}`) : toast.error(`${"Weeks" + " " + lang.can_not_greater_than_error_message + ": 7"}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    return true;
  }



  const setScheduleByShortCut = async (scheduleAction = {}, startup = false) => {
    if (startup) {
      setScheduleDataItem({ ...scheduledataItem, Minutes: "*", Hours: "*", Days: "*", Months: "*", Weeks: "*" });
    }
    else {
      setScheduleDataItem({ ...scheduledataItem, ...scheduleAction });
    }
  }

  const handleSubmit = async () => {

    if (isValid()) {

      const saveData = {
        ...dataItem,
        _id: dataItem._id,
        Name: dataItem.Name,
        Description: dataItem.Description,
        Command: dataItem.Command ?? "",
        Schedule: ((scheduledataItem.Minutes ?? "*") + " " + (scheduledataItem.Hours ?? "*") + " " +
          (scheduledataItem.Days ?? "*") + " " + (scheduledataItem.Months ?? "*") + " " + (scheduledataItem.Weeks ?? "*")),
        Task: dataItem.Task,
        ExecuteShell: dataItem.ExecuteShell,
        Disable: dataItem.Disable,
        EnableErrorLog: dataItem.EnableErrorLog ?? false,
        Archive: dataItem.Archive ?? false,
      }
      console.log(saveData)
      const res = await API.saveData(ENTITYNAME.JobsSchedule, saveData);
      if (res.success) {
        let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_JOB_SCHEDULE : LOGEVENT.UPDATE_JOB_SCHEDULE, module: MODULE.JOBS_SCHEDULE, data: res.data, message: res.message };
        API.SaveLogs(logData);
        // props.refresh();
        props.cancelEdit();
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }

    }
  }

  return (
    <>
      {<BossDialog
        title={props.item.SID > 0 ? props.item.Name : `${lang.create_jobs_schedule_dialog_header}`}
        onClose={props.cancelEdit}
        width="650px"
      >
        <EditPageHeader title={(props.item.SID > 0 ? dataItem.Name : `${lang.new_title_editpageheader}`)} onSubmit={handleSubmit} onCancel={props.cancelEdit} />

        <Form
          initialValues={dataItem}
          render={(formRenderProps) => (
            <FormElement>
              <div className="row">
                <div className="col-12">

                  <div className="row mt-2">
                    <div className="col-6">
                      <label>{lang.name_label} *</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Name"} onChange={handleChange} value={dataItem.Name} />
                    </div>
                    <div className="col-6">
                      <label>{lang.description_label} *</label>
                      <input className="form-control form-control-sm" type="text" name={"Description"} onChange={handleChange} value={dataItem.Description} />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <label>{lang.execute_shell_label} *</label>
                      <textarea className="form-control form-control-sm" type="text" name={"ExecuteShell"} onChange={handleChange} value={dataItem.ExecuteShell} />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <label htmlFor="TabView">{lang.task_label} *</label>
                      <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={
                          Object.keys(TASK).map((obj) => {
                            return {
                              _id: TASK[obj],
                              Description: obj
                            }
                          })
                        }
                        name="Task"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.Task}
                        onChange={handleChange}
                      />
                    </div>
                    {/* <div className="col-6">
                      <label>{lang.command_label} *</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Command"} onChange={handleChange} value={dataItem.Command} />
                    </div> */}
                  </div>
                  {dataItem.Task?._id == TASK.RunAPI && <div className="row mt-2">
                    <div className="col-12">
                      <label>{lang.command_label} *</label>
                      <textarea className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "65px" }} name={"Command"} onChange={handleChange} value={dataItem.Command} />
                    </div>
                  </div>}
                  <div className="mt-3">
                    <label htmlFor="">{lang.quick_schedule_lebel}</label>
                  </div>
                  <div className="row mt-2">
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.startup_button_tooltip} name={lang.startup_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({}, true) }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.hourly_button_tooltip} name={lang.hourly_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ Minutes: "59" }) }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.daily_button_tooltip} name={lang.daily_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ Minutes: "0", Hours: "2" }); }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.weekly_button_tooltip} name={lang.weekly_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ Weeks: "7" }); }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.monthly_button_tooltip} name={lang.monthly_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ Months: "1" }); }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.yearly_button_tooltip} name={lang.yearly_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ months: "2" }); }} />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.minute_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Minutes"} onChange={handleScheduleChange} value={scheduledataItem.Minutes} />
                    </div>
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.hour_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Hours"} onChange={handleScheduleChange} value={scheduledataItem.Hours} />
                    </div>
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.day_Of_month_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Days"} onChange={handleScheduleChange} value={scheduledataItem.Days} />
                    </div>
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.month_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Months"} onChange={handleScheduleChange} value={scheduledataItem.Months} />
                    </div>
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.week_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Weeks"} onChange={handleScheduleChange} value={scheduledataItem.Weeks} />
                    </div>

                  </div>
                  <div className="row mt-3">
                    <div className="col-3" >
                      <Field
                        name={"EnableErrorLog"}
                        component={Checkbox}
                        label={lang.enable_error_log_lebel}
                        checked={dataItem.EnableErrorLog}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-3" >
                      <Field
                        name={"Disable"}
                        component={Checkbox}
                        label={lang.disable_lebel}
                        checked={dataItem.Disable}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-3" >
                      <Field
                        name={"Archive"}
                        component={Checkbox}
                        label={lang.archive}
                        checked={dataItem.Archive}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )}
        />
      </BossDialog>}
    </>)
};