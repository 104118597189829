/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME, LIVE_EVENT_GENDER } from "../../../framework/constant/constant";
import * as API from "../../../framework/API/api";
import { EditPageHeader } from "../../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../../locale/useTranslation";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import CollectionSelection from "../../../framework/forms/CollectionSelection";
import moment from "moment";
import TournamentTypeEditForm from "../../masters/TournamentTypeEditForm";
import { utility } from "../../../framework/utility/utilityProvider";
import BossDialog from "../../../components/BossDialog";
import { TournamentGroupEditForm } from "../TournamentGroupEditForm";
import { Description } from "@mui/icons-material";

export const TournamentEditForm = (props) => {

  const [dataItem, setDataItem] = useState({
    ...props.item,
    SID: props.item.SID ?? 0,
    Name: props.item.Name ?? '',
    EventCountry: props.item.EventCountry ?? '',
    TournamentID: props.item.TournamentID ?? '',
    TournamentType: props.item.TournamentType ?? {},
    StartDate: props.item.SID > 0 ? moment(new Date(props.item.StartDate)).format('YYYY-MM-DD') : '',
    EndDate: props.item.SID > 0 ? moment(new Date(props.item.EndDate)).format('YYYY-MM-DD') : '',
    Channels: props.item.Channels ?? [],
    PosterUrl: props.item.PosterUrl ?? '',
    Archive: props.item.Archive ?? false,
    Gender: props.item.Gender ? { _id: props.item.Gender, Description: utility.getKeyByValue(LIVE_EVENT_GENDER, props.item.Gender) } : { _id: LIVE_EVENT_GENDER.NA, Description: utility.getKeyByValue(LIVE_EVENT_GENDER, LIVE_EVENT_GENDER.NA) },
       
  });
  const [channel, setChannel] = useState([]);
  const [tournamentType, setTournamentType] = useState([]);
  const [posterOpen, setPosterOpen] = useState(false);
  const [showTournamentTypeEditForm, setShowTournamentTypeEditForm] = useState(false);
  const [tournamentGroup, setTournamentGroup] = useState([]);
  const [showTournamentGroupEditForm, setShowTournamentGroupEditForm] = useState(false);
  const [gender, setGender] = useState([])

  const lang = useTranslation();

  useEffect(() => {
    loadcombo();
  }, []);

  const isValid = () => {

    if (dataItem.Name == "" || dataItem.Name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.TournamentType).length == 0) {
      toast.error(`${lang.please_select_tournament_type}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.TournamentID == "" || dataItem.TournamentID == undefined) {
      toast.error(`${lang.please_enter_tournament_ID}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.StartDate == "" || dataItem.StartDate == undefined) {
      toast.error(`${lang.please_select_start_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.EndDate == "" || dataItem.EndDate == undefined) {
      toast.error(`${lang.please_select_end_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Channels.length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.EventCountry == "" || dataItem.EventCountry == undefined) {
      toast.error(`${lang.please_enter_event_country_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.TournamentGroup).length == 0) {
      toast.error(`${lang.please_select_tournament_group}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.Gender).length == 0) {
      toast.error(`${lang.please_select_gender_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;

  }


  const loadcombo = async () => {
    let channel = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    setChannel(channel.data);
    let tournamentType = await API.getDataLookup(ENTITYNAME.TournamentType, { sort: { Name: 1 } });
    setTournamentType(tournamentType.data);
    let tournamentGroup = await API.getDataLookup(ENTITYNAME.TournamentGroup, { sort: { Description: 1 } });
    setTournamentGroup(tournamentGroup.data);

    let genderRes = Object.keys(LIVE_EVENT_GENDER).map((x) => {
      return {
          _id: LIVE_EVENT_GENDER[x],
          Description: x
      }
  })
  setGender(genderRes)
  }

  const onchange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const handlePosterImageSelect = (dataList) => {
    setDataItem({ ...dataItem, PosterUrl: dataList[0].ImageUrl });
  }

  const handleSubmit = async () => {

    if (isValid()) {

      let localData = {
        SID: dataItem.SID,
        Name: dataItem.Name,
        StartDate: new Date(dataItem.StartDate).getTime(),
        EndDate: new Date(dataItem.EndDate).getTime(),
        Channels: dataItem.Channels.map(({ _id, SID, FullChannelName }) => ({ _id, SID, FullChannelName })),
        TournamentID: dataItem.TournamentID,
        TournamentType: { _id: dataItem.TournamentType._id, SID: dataItem.TournamentType.SID, Description: dataItem.TournamentType.Description },
        PosterUrl: dataItem.PosterUrl.length != 0 ? dataItem.PosterUrl :
          dataItem.TournamentType.Icon.includes('http') ? dataItem.TournamentType.Icon :
            'https://media.comicbook.com/files/img/default-movie.png',            
        EventCountry: dataItem.EventCountry,
        TournamentGroup: { _id: dataItem.TournamentGroup._id, SID: dataItem.TournamentGroup.SID, Description: dataItem.TournamentGroup.Description },
        Archive: dataItem.Archive ?? false,
        checkDuplicate: true,
        query: [["Name", "=", dataItem.Name]],
        Gender: dataItem.Gender._id
      
      }
      if(props.item._id){
        localData = {...localData, _id: props.item._id}
      }
      console.log(localData);
      
      delete localData.isSelected;
      let res = await API.saveData(ENTITYNAME.Tournament, localData);
      utility.deleteLocalStorageItem(ENTITYNAME.Tournament);
      if (res.success) {
        props.cancelEdit();
        //do not refresh update data by using slice
        props.refresh(res.data);
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  return (
    <>
      {<BossDialog
        title={props.item.SID > 0 ? props.item.Name : `${lang.tournament_dialog_header}`}
        onClose={props.cancelEdit}
        width={"50%"}
      >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
        <Form
          render={(formRenderProps) => (
            <FormElement>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="TabView">{lang.name_label} *</label>
                      <input className="pl-2 form-control form-control-sm" type="text" name={"Name"} onChange={onchange} value={dataItem.Name} />
                    </div>                    
                  </div>

                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.tournament_type_label} *</label>
                      <div style={{ display: 'flex' }}>
                        <DropDownList
                          style={{ backgroundColor: "white" }}
                          data={tournamentType}
                          name="TournamentType"
                          textField="Description"
                          dataItemKey="_id"
                          value={dataItem.TournamentType}
                          onChange={onchange}
                          validator={(value) => value ? "" : "Please select the value"}
                        />
                        <button type="button" title='Create Tournament Type' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                          style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { setShowTournamentTypeEditForm(true) }}><i className="fa-solid fa-plus fa-xs"></i>
                        </button>
                      </div>
                    </div>

                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.tournament_ID_label} *</label>
                      <input className="pl-2 form-control form-control-sm" type="text" name={"TournamentID"} onChange={onchange} value={dataItem.TournamentID} disabled={props.item.SID > 0 ? true : false} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3 ">
                      <label htmlFor="TabView">{lang.start_date_label} *</label>
                      <div className="form-group">
                        <input type="date" className="form-control form-control-sm" name="StartDate" value={dataItem.StartDate} onChange={onchange} />
                      </div>
                    </div>
                    <div className="col-3">
                      <label htmlFor="TabView">{lang.end_Date_label} *</label>
                      <div className="form-group">
                        <input type="date" className="form-control form-control-sm" name="EndDate" value={dataItem.EndDate} onChange={onchange} />
                      </div>
                    </div>
                    <div className="col-6">
                     <label htmlFor="TabView">{lang.gender_label} *</label>
                     <DropDownList
                     style={{ backgroundColor: "white" }}
                     data={gender}
                     name="Gender"
                     textField="Description"
                     dataItemKey="_id"
                     value={dataItem.Gender}
                     onChange={onchange}
                     />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.channel_label} *</label>
                      <MultiSelect
                        data={channel}
                        textField="FullChannelName"
                        value={dataItem.Channels}
                        onChange={onchange}
                        name={"Channels"}
                      />
                    </div>
                    <div className="col-5">
                      <label htmlFor="">Poster Url </label>
                      <input
                        name={"PosterUrl"}
                        className="form-control form-control-sm"
                        type={'text'}
                        value={dataItem.PosterUrl}
                        onChange={onchange}
                      />
                    </div>

                    <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      style={{ height: "30px", marginTop: "20px" }} onClick={(e) => { setPosterOpen(true); e.preventDefault(); }}>
                      {'...'}
                    </button>
                  </div>

                  <div className="row">
                  <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.event_country_level} *</label>
                      <input className="pl-2 form-control form-control-sm" type="text" name={"EventCountry"} onChange={onchange} value={dataItem.EventCountry} />
                    </div>

                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.tournament_group_label} *</label>
                      <div style={{ display: 'flex' }}>
                        <DropDownList
                          style={{ backgroundColor: "white" }}
                          data={tournamentGroup}
                          name="TournamentGroup"
                          textField="Description"
                          dataItemKey="_id"
                          value={dataItem.TournamentGroup}
                          onChange={onchange}
                          validator={(value) => value ? "" : "Please select the value"}
                        />
                        <button type="button" title='Create Tournament Type' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                          style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { setShowTournamentGroupEditForm(true) }}><i className="fa-solid fa-plus fa-xs"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-3  form-group">
                      <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onchange} />
                      <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )} />
      </BossDialog>}
      {posterOpen && <CollectionSelection entityname={ENTITYNAME.OttPosters} closeForm={() => { setPosterOpen(false) }} setDataList={handlePosterImageSelect} title={lang.select_portal_image_url_collection_header} mode={"single"} width={"50vw"} height={"60vh"} />}
      {showTournamentTypeEditForm && <TournamentTypeEditForm item={{ SID: 0 }} cancelEdit={() => setShowTournamentTypeEditForm(false)} refresh={() => loadcombo()} />}
      {showTournamentGroupEditForm && <TournamentGroupEditForm item={{ SID: 0 }} cancelEdit={() => setShowTournamentGroupEditForm(false)} refresh={() => loadcombo()} />}

    </>
  )
}