export const en_us = () => {
    return {
        dashboard: 'Dashboard',
        cms_dashboard: 'cms',
        content_dashboard: 'Content',
        sales_dashboard: 'Sales',
        booking_status_dashboard: 'Booking Status',
        booking_summary_dashboard: 'Booking Summary',
        contract_menu: 'Contract',
        contract_sub_menu: 'Contract',
        media_management_menu: 'Media Management',
        media_others_menu: 'Others',
        series_sub_menu: 'Parent Series',
        season_sub_menu: 'Series',
        program_sub_menu: 'Program',
        movie_sub_menu: 'Movie',
        trailer_sub_menu: 'Trailer',
        interstitial_sub_menu: 'Interstitial',
        commercial_sub_menu: 'Commercial',
        filler_sub_menu: 'Filler',
        music_sub_menu: 'Music',
        break_sub_menu: 'Break',
        media_episode_group_sub_menu: 'Media Episode Group',
        planning_menu: 'Planning',
        planning_sub_menu: 'Planning',
        formats_sub_menu: 'Formats',
        program_schedule_clash_sub_menu: 'Program Schedule Clash',
        scheduling_menu: 'Scheduling',
        curate_menu: 'Curate',
        timeline_sub_menu: 'Timeline',
        rails_sub_menu: 'Rails',
        pages_sub_menu: 'Pages',
        assets_sub_menu: 'Assets',
        tv_preview_sub_menu: 'TV Preview',
        web_preview_sub_menu: 'Web Preview',
        mobile_preview_sub_menu: 'Mobile Preview',
        ingestion_menu: 'Ingestion',
        posters_sub_menu: 'Posters',
        ingestion_template_sub_menu: 'Ingestion Template',
        partner_ingestion_sub_menu: 'Partner Ingestion',
        jobs_sub_menu: 'Jobs',
        sales_menu: 'Sales',
        agency_sub_menu: 'Agency',
        client_sub_menu: 'Client',
        campaign_sub_menu: 'Campaign',
        booking_sub_menu: 'Booking',
        booking_transfer_sub_menu: 'Booking Transfer',
        update_booking_line_number_sub_menu: 'Update Booking Line Number',
        sales_team_sub_menu: 'Sales Team',
        sales_executive_sub_menu: 'Sales Executive',
        reconciliation_menu: 'Reconciliation',
        as_run_reconciliation_sub_menu: 'As Run Reconciliation ',
        import_planning_sub_menu: 'Import Planning',
        import_asRun_sub_menu: 'Import AsRun',
        manage_asRun_sub_menu: 'Manage AsRun',
        export_menu: 'Export',
        epg_sub_menu: 'EPG',
        epg_template_sub_menu: 'EPG Template',
        secondary_event_menu: 'Secondary Event',
        prototype_sub_menu: 'Prototype ',
        styleSheet_sub_menu: 'StyleSheet',
        default_prototype_sub_menu: 'Default Prototype ',
        protoType_source_sub_menu: 'ProtoType Source',
        secondary_event_dynamic_rule_sub_menu: 'SecondaryEvent Dynamic Rule',
        billing_menu: 'Billing  ',
        billing_sub_menu: 'Billing ',
        generated_bills_sub_menu: 'Generated Bills ',
        masters_menu: 'Masters',
        master_menu_title: 'Master',
        language_sub_menu: 'Language',
        content_sub_menu: 'Content',
        assets_type_sub_menu: 'Assets Type',
        rails_type_sub_menu: 'Rails Type',
        posters_type_sub_menu: "Posters Type",
        users_sub_menu: 'Users',
        department_sub_menu: 'Department',
        role_sub_menu: 'Role',
        content_category_sub_menu: 'Content Category',
        region_sub_menu: 'Region',
        brand_sub_menu: 'Brand',
        product_sub_menu: 'Product',
        bank_account_sub_menu: 'Bank Account',
        tax_sub_menu: 'Tax',
        licensor_sub_menu: 'Licensor',
        channel_sub_menu: 'Channel',
        currency_sub_menu: 'Currency',
        time_range_sub_menu: 'Time Range',
        repeat_pattern_sub_menu: 'Repeat Pattern',
        invoice_content_sub_menu: 'Invoice Content',
        segemnt_type_sub_menu: 'Segment Type',
        variation_sub_menu: 'Variation',
        break_format_sub_menu: 'Break Format',
        payment_provider_sub_menu: 'Payment Provider',
        ott_period_type_sub_menu: 'Ott Period Type',
        payment_type_sub_menu: 'Payment Type',
        schedule_constraints_sub_menu: 'Schedule Constraints',
        monetization_menu: 'Monetization',
        ott_period_sub_menu: 'Period',
        subscriber_sub_menu: 'Subscriber',
        ott_campaign_sub_menu: 'Campaign',
        coupon_sub_menu: 'Coupon',
        data_migration_menu: 'Data Migration',
        form_fields_menu: 'Form Fields',
        save_button_tooltip: 'Save',
        save_button_text: 'Save',
        back_button_tooltip: 'Back',
        add_button_tooltip: 'Add',
        add_button_text: 'Add',
        add_multiple_button_text: 'Add Multiple',
        add_dialog_header: 'Add',
        new_button_dialog_header: 'New',
        new_title_editpageheader: 'New',
        edit_button_tooltip: 'Edit',
        update_button_tooltip: 'Update',
        remove_button_tooltip: 'Remove',
        delete_button_tooltip: 'Delete',
        select_button_tooltip: 'Select',
        select_button_text: 'Select',
        cancel_button_tooltip: 'Cancel',
        cancel_button_text: 'Cancel',
        preference_button_tooltip: 'Preference',
        options: 'options',
        refresh_button_tooltip: 'Refresh',
        archive: 'Archive',
        ExportBreaksOnly: 'Export Breaks Only',
        loading_pleasewait: 'Loading Please Wait ...',
        no_records_found: 'No Records Found!!',
        contract_number_label: 'Contract Number',
        platform_type_label: 'Platform Type',
        licensor_label: 'Licensor',
        payee_label: 'Payee',
        start_date_label: 'Start Date',
        end_Date_label: 'End Date',
        channel_label: 'Channel',
        platform_label: 'Platform',
        region_label: 'Region',
        exhibition_label: 'Exhibition',
        repeat_label: 'Repeat',
        play_time_number_label: 'Play Time Number',
        playTime_label: 'Play Time',
        is_unlimited_label: 'Unlimited',
        add_media_episode_button_tooltip: 'Add Media Episode',
        remove_media_episode_button_tooltip: 'Remove Media Episode',
        assetId_column: 'Asset Id',
        title_column: 'Title',
        media_category_column: 'Media Category',
        start_date_column: 'Start Date',
        end_date_column: 'End Date',
        is_unlimited_column: 'Is Unlimited',
        exhibition_column: 'Exhibition',
        repeat_column: 'Repeat',
        play_time_number_column: 'PlayTimeNumber',
        playTime_column: 'PlayTime',
        media_library_dialog_header: 'Media Library',
        summary: 'Summary',
        create_series_dialog_header: 'Create Parent Series',
        title_label: 'Title',
        content_label: 'Content',
        asset_id_label: 'Asset ID',
        description_label: 'Description',
        synopsis_label: 'Synopsis',
        islive_label: 'Live',
        seasons_title: 'Series',
        series_cardlist_header: 'SERIES',
        season_cardlist_header: 'EPISODE',
        sidefix_season_tooltip: 'Season',
        s_content_label: 'S. Content',
        season_no_label: 'Season No.',
        ep_content_label: 'Ep. Content',
        episodes_label: 'Episodes',
        no_of_episodes_label: 'No. of Episodes',
        duration_label: 'Duration (hh:mm:ss:ff)',
        playlist_duration_label: 'Duration (hh:mm:ss)',
        total_block_duration_label: 'Total Block Duration (hh:mm:ss:ff)',
        season_number_column: 'Season Number',
        no_of_episodes_column: 'No. Of Episodes',
        season_content_column: 'Season Content',
        episode_content_column: 'Episode Content',
        duration_column: 'Duration',
        timerangefrom_column: 'From',
        timerangeto_column: 'To',
        select_image_collection_header: 'Select Image',
        description_column: 'Description',
        meta_data_label: 'Meta Data',
        posters_label: 'Posters',
        publishing_label: 'Publishing',
        search_by_title: 'Search By Title',
        genre_label: 'Genre',
        language_label: 'Language',
        pg_rating_label: 'PG Rating',
        keywords_label: 'Keywords',
        add_poster_button_tooltip: 'Add Poster',
        poster_type_column: 'Poster Type',
        image_column: 'Image',
        edit_poster_dialog_header: 'Edit Poster',
        poster_type_label: 'Poster Type',
        url_label: 'URL',
        select_url_tooltip: 'Select URL',
        add_publishing_button_tooltip: 'Add Publishing',
        unlimited_rights_grid: 'Unlimited Rights',
        tba_label: 'TBA',
        tba_column: 'TBA',
        publishing_dialog_header: 'Publishing',
        platform_type_column: 'Platform Type',
        channel_column: 'Channel',
        source_column: 'Source',
        enter_title: 'Enter Title',
        create_season_dialog_header: 'Create Series',
        season_number_label: 'Season Number',
        series_label: 'Parent Series',
        starting_episode_label: 'Starting Episode',
        select_series_collection_header: 'Select Parent Series',
        video_label: 'Video',
        videos_label: 'Videos',
        add_video_button_tooltip: 'Add Video',
        video_type_column: 'Video Type',
        video_type_label: 'Video Type',
        url_column: 'URL',
        edit_video_dialog_header: 'Edit Video',
        create_video_dialog_header: 'Create Video',
        episode_title_label: 'Episode Title',
        episode_number_label: 'Episode Number',
        episode_number_labelshort: 'EpNo.',
        release_date_label: 'Release Date',
        version_label: 'Version',
        content_category_label: 'Content Category',
        format_label: 'Format',
        cast_and_crew_label: 'Cast and Crew',
        dynamic_fields_label: 'Dynamic Fields',
        censor_rating_label: 'Censor Rating',
        technical_prototype_label: 'Technical Prototype',
        production_year_label: 'Production Year',
        language_column: 'Language',
        synopsis_column: 'Synopsis',
        cast_column: 'Cast',
        name_column: 'Name',
        name_label: 'Name',
        cast_type_label: 'Cast Type',
        segment_type_column: 'Type',
        material_id_column: 'Material ID',
        actual_duration_column: 'Actual Duration',
        is_default_column: 'Is Default',
        is_hard_parted_column: 'Is Hard Parted',
        edit_version_dialog_header: 'Edit Version',
        create_version_dialog_header: 'Create Version',
        segment_type_label: 'Segment Type',
        material_id_label: 'Material ID',
        is_hard_parted_label: 'Is Hard Parted',
        is_default_label: 'Is Default',
        generate_segments_button_text: 'Generate Segments',
        auto_distribute_button_text: 'Auto Distribute',
        sequence_column: 'Sequence',
        media_id_column: 'Media ID',
        start_time_column: 'Start Time',
        end_time_column: 'End Time',
        previewed_column: 'Previewed',
        parent_content_category_column: 'Parent Content Category',
        select_format_collection: 'Select Format',
        select_content_category_collection_header: 'Select Content Category',
        manual_assetid_label: 'Manual Asset Id',
        media_category_label: 'Media Category',
        media_category_type_label: 'Media Category Type',
        tc_in_label: 'Tc In (hh:mm:ss:ff)',
        tc_out_label: 'Tc Out (hh:mm:ss:ff)',
        media_episode_group_dialog_header: 'Create Media Episode Group',
        program_group_dialog_header: 'Create Program Group',
        interstital_group_dialog_header: 'Create Interstital Group',
        media_category_type_column: 'Media Category Type',
        media_category_type_collection_header: 'Media Category Type',
        episode_dialog_header: 'Episode',
        sidefixmenu_rails_asset_tooltip: 'Asset',
        cardlist_rails_title: 'Assets',
        cardlist_rails_assets_title: 'Asset Library',
        rails_title_editpageheader: 'Rails',
        display_title_label: 'Display Title',
        rails_type_label: 'Type',
        rails_note_label: 'Note : User can select',
        publish_label: 'Publish',
        published_label: 'Published',
        unpublished_label: 'Unpublished',
        rails_p_label: 'P',
        rails_up_label: 'UP',
        ott_assets_collection_header: 'Ott Assets',
        options_actionbutton_menutext: 'Options',
        delete_actionbutton_option: 'Delete',
        moveup_actionbutton_option: 'Move Up',
        movedown_actionbutton_option: 'Move Down',
        sidefixmenu_rails_tooltip: 'Rail',
        cardlist_page_rails_title: 'Rails',
        cardlist_rails_page_title: 'Rail Library',
        theme_label: 'Theme',
        icon_url_label: 'Icon Url',
        please_fill_out_details_label: 'Please fill out this field.',
        assets_dialog_header: 'Create Assets',
        validation: 'Please select the value',
        asset_type_label: 'Asset Type',
        portrait_image_url_label: 'Portrait Image Url',
        landscape_image_url_label: 'Landscape Image Url',
        video_playback_url_label: 'Video Playback Url',
        select_portal_image_url_collection_header: 'Select Portrait Image Url',
        select_landscape_image_url_collection_header: 'Select Landscape Image Url',
        create_poster_dialog_header: 'Create Poster',
        image_url_label: 'Image Url',
        file_path_label: 'File Path',
        poster_note_label: 'Note : Image will be uploaded on save and URL will be assigned',
        template_name_label: 'Template Name',
        module_type_label: 'Module Type',
        ingestion_template_type_label: 'Ingestion Template Type',
        save_template_text: 'Save Template',
        send_text: 'SEND',
        send_button_tooltip: 'Send',
        params_label: 'Params',
        body_label: 'Body',
        response_label: 'Response',
        select_file_label: 'Select File',
        load_button_text: 'Load',
        ingestion_template_note_label: 'Well replace first param value with searchtext while saving',
        key_column: 'Key',
        value_column: 'Value',
        key_label: 'Key',
        value_label: 'Value',
        template_details_label: 'Template Details',
        template_label: 'Template',
        search_title_label: 'Search Title',
        ingest_button_text: 'Ingest',
        ingest_button_tooltip: 'Ingest',
        load_button_tooltip: 'Load',
        ingestion_details_label: 'Ingestion Details',
        pg_rating_column: 'PGRating',
        genres_column: 'Genres',
        show_response_label: 'Show Response',
        response_dialog_header: 'Response',
        eye_tooltip: 'Eye',
        agency_dialog_header: 'Create Agency',
        code_label: 'Code',
        address_label: 'Address',
        pincode_label: 'Pin Code',
        email_label: 'Email',
        credit_limit_label: 'Credit Limit',
        pan_label: 'PAN',
        gst_number_label: 'GST Number',
        default_tax_label: 'Default Tax',
        is_credit_unlimited_label: 'Is Credit Unlimited',
        client_dialog_header: 'Create Client',
        agency_label: 'Agency',
        campaign_number_label: 'Campaign Number',
        order_date_label: 'Order Date *',
        period_from_label: 'From',
        period_to_label: 'To',
        campaign_type_label: 'Type',
        deal_type_label: 'Deal Type',
        client_label: 'Client',
        location_label: 'Location',
        product_label: 'Product',
        commission_label: 'Commission',
        target_budget_label: 'Target Budget',
        target_spots_label: 'Target Spots',
        target_seconds_label: 'Target Seconds',
        currency_label: 'Currency',
        discount_label: 'Discount',
        tax_applicable_label: 'Tax Applicable',
        actual_booking_label: 'Actual Booking',
        commercial_label: 'Commercial',
        fct_label: 'FCT',
        billing_label: 'Billing',
        channel_tab_label: 'Channel',
        channel_name_column: 'Channel Name',
        epg_code_column: 'EPG Code',
        asrun_code_column: 'AsRun Code',
        content_column: 'Content',
        add_channel_button_tooltip: 'Add Channel',
        add_commercial_button_tooltip: 'Add Commercial',
        add_fct_button_tooltip: 'Add FCT',
        add_billing_button_tooltip: 'Add Billing',
        channel_collection_header: 'Channels',
        commercials_collection_header: 'Commercials',
        fct_column: 'FCT',
        from_date_column: 'From Date',
        to_date_column: 'To Date',
        campaign_type_column: 'Campagin Type',
        rate10sec_column: 'Rate10sec',
        days_column: 'Days',
        fct_dialog_header: 'FCT',
        billing_dialog_header: 'Billing',
        from_date_label: 'From Date',
        to_date_label: 'To Date',
        rate10sec_label: 'Rate10Sec',
        rate30sec_label: 'Rate30Sec',
        day_label: 'Day',
        select_all_label: 'Select All',
        sun_label: 'Sun',
        mon_label: 'Mon',
        tue_label: 'Tue',
        wed_label: 'Wed',
        thu_label: 'Thu',
        fri_label: 'Fri',
        sat_label: 'Sat',
        amount_column: 'Amount',
        amount_label: 'Amount',
        booking_label: 'Booking',
        campaign_label: 'Campaign',
        year_label: 'Year',
        months_label: 'Months',
        booking_collection_header: 'Select Campaign For Booking',
        from_label: 'From:',
        to_label: 'To:',
        total_spot_label: 'Total Spots',
        total_seconds_label: 'Total Seconds',
        total_budget_label: 'Total Budget:',
        download_template_tooltip: 'Download Template',
        file_tooltip: 'Choose File',
        line_no_column: 'Line No.',
        commercial_column: 'Commercial',
        program_column: 'Program',
        house_number_column: 'House Number',
        type_column: 'Type',
        deal_type_column: 'Deal Type',
        spot_rate_column: 'Spot Rate',
        position_column: 'Position',
        from_time_column: 'From',
        to_time_column: 'To',
        product_code_column: 'Product Code',
        total_spot_column: 'Total Spot',
        total_fct_column: 'Total FCT',
        total_amount_column: 'Total Amount',
        booked_spots_lable: 'Booked Spots :',
        current_spots_label: 'Current Spots : ',
        booked_seconds_label: 'Booked Seconds :',
        current_seconds_label: 'Current Seconds :',
        booked_amount_label: 'Booked Amount :',
        current_amount_label: 'Current Amount :',
        add_booking_dialog_header: 'Add Booking',
        type_label: 'Type',
        from_time_label: 'From Time',
        spot_per_day_label: 'Spot (Per Day)',
        spot_per_week_label: 'Spot (Per Week)',
        rate_label: 'Rate',
        estimate_code_label: 'Estimate Code',
        remark_label: 'Remarks',
        count_label: 'Count',
        transfer_from_label: 'Transfer From',
        transfer_to_label: 'Transfer To',
        transfer_button_tooltip: 'Transfer',
        transfer_button_text: 'Transfer',
        campaign_column: 'Campaign',
        booking_date_column: 'Booking Date',
        time_range_from_column: 'Time Range From',
        time_range_to_column: 'Time Range To',
        edit_actionbutton_menutext: 'Edit',
        select_all_Header_menutext: 'Select All Header',
        delete_actionbutton_menutext: 'Delete',
        editduration_actionbutton_menutext: 'Edit Duration',
        cut_actionbutton_menutext: 'Cut',
        update_booking_line_number_label: 'Update Booking Line Number',
        select_campaign_collection_text: 'Select Campaign',
        edit_campaign_collection_text: 'Edit Campaign',
        select_campaign_collection_tooltip: 'Select',
        already_scheduled_label: 'Already Scheduled',
        not_scheduled_label: 'Not Scheduled',
        edit_booking_dialog_header: 'Edit Booking',
        spot_rate_label: 'Spot Rate',
        time_range_from_label: 'TimeRange From',
        time_range_to_label: 'TimeRange To',
        auto_reconcile_button_tooltip: 'Auto Reconcile',
        auto_reconcile_button_text: 'Auto Reconcile',
        import_as_run_button_text: 'Import As Run',
        import_as_run_button_tooltip: 'Import As Run',
        reconciled_r_label: "R",
        reconciled_label: 'Reconciled',
        non_reconciled_label: 'Non Reconciled',
        non_reconciled_nr_label: 'NR',
        total_label: 'Total',
        total_commercial: 'Total Commercial:',
        sno_column: 'SNo.',
        slot_date_time_column: 'Slot Date Time',
        episode_number_column: 'Episode Number',
        category_column: 'Category',
        reconciled_status_column: 'Reconciled Status',
        is_reconciled_column: 'Is Reconciled',
        reconsilation_by_column: 'Reconsilation By',
        status_column: 'Status',
        ref_force_match_label: 'Force Match',
        force_match_label: 'Force Match',
        force_match_fm_label: 'FM',
        ref_force_unmatch_label: 'Force Unmatch',
        ref_cancel_label: 'Cancel',
        purge_button_tooltip: 'Purge',
        unpurge_button_tooltip: 'UnPurge',
        campaign_no_label: 'Campaign No',
        fic_dxb_0002486_label: 'FIC-DXB-0002486',
        total_spot_857_label: '857',
        total_amount_label: 'Total Amount',
        total_seconds_19935_label: '19935',
        total_cast_2478_label: '2478.00',
        purged_label: 'Purged',
        date_column: 'Date',
        is_purged_column: 'IsPurged',
        ref_purged_label: 'Purge',
        ref_unpurged_label: 'UnPurge',
        ref_calculate_spot_rate_label: 'Calculate Spot Rate',
        total_fct_label: 'Total FCT',
        total_spot_rate: 'Total Spot Rate',
        ref_calculate_fct_label: 'Calculate FCT',
        ref_change_time_range_label: 'Change Time Range',
        ref_transfer_label: 'Transfer',
        ref_update_estimate_code_label: 'Update Estimate Code',
        ref_change_commercial_label: 'Change Commercial',
        ref_change_spot_rate_label: 'Change Spot Rate',
        change_time_range_dialog_header: 'Change Time Range',
        start_time_label: 'Start Time',
        to_time_label: 'To Time',
        transfer_dialog_header: 'Transfer',
        transfer_to_date_label: 'Transfer To Date',
        change_spot_rate_dialog_header: 'Change Spot Rate',
        change_spot_rate_label: 'Change Spot Rate',
        change_commercial_dialog_header: 'Change Commercials',
        change_commercial_label: 'Change Commercial',
        update_product_estimate_code_dialog_header: 'Update Product Estimate Code',
        update_button_text: 'Update',
        update_all_button_text: 'Update All',
        add_file_label: 'Add File',
        import_file_label: ' Import Files',
        status_label: 'Status',
        file_name_column: 'File Name',
        message_column: 'Message',
        file_upload_tooltip: 'File Upload',
        import_file_name_column: 'Import File Name',
        comment_load_data_label: 'Click load to load data',
        program_label: 'Program',
        select_program_booking_collection_header: 'Select Program For Booking',
        export_report_dialog_header: 'Export EPG',
        import_template_dialog_heade: 'Import Template',
        filters_label: 'Filters',
        operator_label: 'Operator',
        operator_column: 'Operator',
        export_button_tooltip: 'Export',
        export_button_text: 'Export',
        export_playlist_button_tooltip: 'Export Playlist',
        export_and_sftp_playlist_button_tooltip: 'Export & SFTP Playlist',
        export_playlist_button_text: 'Export Playlist',
        up_button_tooltip: 'Up',
        down_button_tooltip: 'Down',
        export_template_editpageheader: 'Export EPG Template',
        save_template_as_label: 'Save Template As',
        module_name_label: 'Module Name',
        selected_Columns_label: 'Selected Columns',
        available_Column_label: 'Available Column',
        display_name_label: 'Display Name',
        add_blank_button_tooltip: 'Add Blank',
        add_blank_button_text: 'Add Blank',
        add_column_button_tooltip: 'Add Column',
        add_column_button_text: 'Add Column',
        available_Column: 'Available Column',
        display_column: 'Display Name',
        format_name_column: 'Format',
        id_label: 'ID',
        source_label: 'Source',
        segment_position_label: 'Segment Position',
        group_label: 'Group',
        select_media_episode_collection_header: 'Select Media Episode',
        start_type_label: 'Start Type',
        start_offset_label: 'Start Offset',
        time_hh_mm_ss_ff_label: 'hh:mm:ss:ff',
        negative_label: 'Negative',
        end_type_label: 'End Type',
        end_offset_label: 'End Offset',
        add_secondary_event_button_toottip: 'Add Secondary Events',
        text_number_column: 'Text Number',
        prefix_column: 'Prefix',
        rule_column: 'Rule',
        text_number_label: 'Text Number',
        prefix_label: 'Prefix',
        rule_label: 'Rule',
        style_sheet_editpageheader: 'Style Sheet',
        add_prototype_button_tooltip: 'Add Prototype',
        id_column: 'ID',
        protoType_source_column: 'ProtoType Source',
        group_column: 'Group',
        select_prototype_collection_header: 'Select Prototype',
        default_prototype_editpageheader: 'Default Prototype ',
        create_secondary_event_dynamic_rule_dialog_header: 'Create Secondary Event Dynamic Rule',
        formula_label: 'Formula',
        secondary_event_overview_dialog_header: 'Secondary Event Overview',
        start_type_column: 'Start Type',
        start_offset_column: 'Start Offset',
        end_type_column: 'EndType',
        end_offset_column: 'End Offset',
        confirm_button_text: 'Confirm',
        billing_period_label: 'Billing Period',
        upto_label: 'Upto',
        generate_bill_button_tooltip: 'Generate Bill',
        generate_bill_button_text: 'Generate Bill',
        agency_column: 'Agency',
        client_column: 'Client',
        comission_column: 'Commission %',
        from_column: 'From',
        to_column: 'To',
        total_budget_spot_column: 'Target Spots',
        total_booked_spot_column: 'Total Booked Spots',
        missing_spot_column: 'Missing Spots',
        reconciled_spots_column: 'Reconciled Spots',
        generated_bill_Spots_column: 'Generated Bill Spots',
        contact_label: 'Contect',
        contact_info_label: 'Contact Info',
        telephone_label: 'Telephone',
        address1_label: 'Address1',
        address2_label: 'Address2',
        agency_info_label: 'Agency Info',
        spots_label: 'Spots',
        amounts_label: 'Amounts',
        total_booked_label: 'Total Booked',
        missing_label: 'Missing',
        generated_bill_label: 'Generated Bill',
        payment_recived_label: 'Payment Recived',
        due_amount_label: 'Due Amount',
        credit_balance_label: 'Credit Balance',
        selected_campaign_info_label: 'Selected Campaign Info',
        booked_label: 'Booked',
        detail_for_selected_date_range_and_campaign_label: 'Detail For Selected Date Range And Campaign',
        invoice_denomination_dialog_header: 'Invoice Denomination',
        invoice_period_label: 'Invoice Period',
        invoice_campaign_brabd_label: 'Campaign Brands',
        select_period_label: 'Select Period',
        show_button_tooltip: 'Show',
        show_button_text: 'Show',
        details_label: 'Details',
        caption_column: 'Caption',
        spots_column: 'Spots',
        tax_column: 'Tax',
        tax_label: 'Tax',
        tax_not_applicable_label: 'Tax Not Applicable',
        discount_amount_label: 'Discount Amount',
        invoice_date_label: 'Invoice Date',
        bank_account_label: 'Bank Account',
        gross_total_label: 'Gross Total',
        tax_amount_label: 'Tax Amount',
        commission_amount_label: 'Commission Amount',
        target_amount_label: 'Target Amount',
        invoice_no_column: 'Invoice No.',
        campaign_no_column: 'Campaign No.',
        invoice_date_column: 'Invoice Date',
        period_from_column: 'Period From',
        period_to_column: 'Period To',
        validated_amount_column: 'Validated Amount',
        discount_percentage_column: 'Discount Percentage',
        discount_amount_column: 'Discount Amount',
        company_menu: 'Company',
        logo_url_label: 'Logo Url',
        logo_width_label: 'Logo Width',
        suffix_label: 'Suffix',
        next_number_label: 'Next Number',
        full_channel_name_label: 'Full Channel Name',
        short_channel_name_label: 'Short Channel Name',
        epg_code_label: 'EPG Code',
        as_run_code_label: 'As Run Code',
        automation_channel_name_label: 'Automation Channel Name',
        color_label: 'Color',
        automation_label: 'Automation',
        repeated_pattern_details_dialog_header: 'Repeated Pattern Details',
        add_repeat_button_tooltip: 'Add Repeat',
        create_repeat_pattern_editpage_header: 'Create Repeat Pattern',
        create_variation_editpage_header: 'Create Variation',
        create_break_format_editpage_header: 'Create Break Format',
        edit_variation_editpage_header: 'Edit Variation',
        edit_break_format_editpage_header: 'Edit Break Format',
        add_primary_pattern_button_tolltip: 'Add Primary',
        primary_events_no_column: 'Primary Events No',
        repeat_events_no_column: 'Repeat Events No',
        nominal_start_column: 'Nominal start',
        primary_events_no_label: 'Primary Events No',
        days_label: 'Days',
        times_label: 'Times',
        create_invoice_content_dialog_header: 'Create Invoice Content',
        number_length_label: 'Number length',
        label: 'Label',
        sequence_label: 'Sequence',
        entity_name_label: 'Entity Name',
        max_length_label: 'Max length',
        width_label: 'Width',
        required_label: 'Required',
        edit_label: 'Edit',
        slot_time_column: 'Slot Time',
        please_generate_segment_to_load_media_label: 'Please generate segment to load media !',
        filter_button_tooltip: 'Filter',
        search_content_dialog_header: 'Search Content',
        clear_button_tooltip: 'Clear',
        clear_button_text: 'Clear',
        search_button_tooltip: 'Search',
        search_button_text: 'Search',
        select_preference_for_dialog_header: 'Select column preference for ',
        available_label: 'Available',
        preference_label: 'Preference',
        note_preference: 'Note:-For Default Columns, Leave Preference Empty',
        house_number_label: 'House Number',
        category_type_label: 'Category Type',
        slot_time_label: 'Slot Time',
        actual_duration_label: 'Actual Duration',
        nominal_duration_label: 'Nominal Duration',
        segments_label: 'Segments',
        recursive_button_text: 'Recursive',
        recursive_dialog_header: 'Recursive',
        generate_button_toottip: 'Generate',
        generate_button_text: 'Excel',
        inclusion_range_label: 'Inclusion Range',
        episode_range_label: 'Episode Range',
        example_label: 'Example',
        exclusion_range_label: 'Exclusion Range',
        exclude_days_label: 'Exclude Days',
        format_icon_tooltip: 'Format',
        no_segment_icon_tooltip: 'No Segment',
        contract_icon_tooltip: 'Contract',
        slot_duration_title_label: 'Slot Duration',
        maximize_button_tooltip: 'Maximize',
        copy_Schedule_button_tooltip: 'Copy',
        planing_preference_button_tooltip: 'Planning Preference',
        export_planning_button_tooltip: 'Export Planning',
        selected_label: 'Selected',
        copied_label: 'Copied',
        select_channels_dialog_header: 'Select Channels',
        date_label: 'Date',
        copy_Schedule_dialog_header: 'Copy Schedule',
        copy_from_label: 'Copy From',
        copy_to_label: 'Copy To',
        selected_date_should_be_equal_error_message: 'Selected date range should be equal',
        from_channel_label: 'From Channel',
        activity_label: 'Activity',
        create_button_tootltip: 'Create',
        create_button_text: 'Create',
        planing_preference_dialog_header: 'Planning Preference',
        events_label: 'Events',
        columns_label: 'Columns',
        general_label: 'General',
        note_select_the_events_you_want_to_change: 'Select the events you want to change',
        events_type_lable: 'Events Type',
        foreground_Color_label: 'Foreground Color',
        background_color_label: 'Background Color',
        available_columns_label: 'Available Columns',
        view_type_label: 'View Type',
        show_format_label: 'Show Format',
        show_no_seg_label: 'Show No Seg',
        show_contract_label: 'Show Contract',
        day_Start_time_label: 'Day Start Time',
        prime_end_time_label: 'Prime end Time',
        prime_Start_time_label: 'Prime Start Time',
        episode_Column: 'Episode',
        season_Column: 'Season',
        versions_column: 'Versions',
        search_filter_dialog_header: 'Search Filter',
        release_year_label: 'Release Year',
        is_live_label: 'IsLive',
        save_as_template_label: 'Save As Template',
        show_search_options_media_liberary_tooltip: 'Show search options',
        place_holder_dialog_header: 'Place Holder',
        please_choose_one_publishing_rights_collection_header: 'Please choose one publishing rights',
        export_excel_dialog_header: 'Export Excel',
        export_excel_button_tooltip: 'Export Excel',
        export_csv_button_tooltip: 'Export CSV',
        export_excel_button_text: 'Export Excel',
        history_dialog_header: 'History',
        slot_date_column: 'Slot Date',
        exhibition_no_column: 'Exhibition No',
        repeat_no_column: 'Repeat No',
        planning_loaded_label_note: 'Planning Loaded',
        planning_loading_label_note: 'Planning Loading....',
        time_column: 'Time',
        prototypes_column: 'Prototypes',
        info_column: 'Info',
        cut_button_tooltip: 'Cut',
        paste_button_tooltip: 'Paste',
        download_pdf_button_tooltip: 'Download PDF',
        open_planning_button_tooltip: 'Open Planning',
        delete_all_footer_button_tooltip: 'Delete All Footer',
        delete_All_secondary_events_button_tooltip: 'Delete All Secondary Events',
        global_replacement_button_tooltip: 'Global Replacement',
        apply_style_sheet_button_tooltip: 'Apply Style Sheet',
        apply_button_tooltip: 'Apply',
        apply_button_text: 'Apply',
        schedule_preference_button_tooltip: 'Schedule Preference',
        auto_save_button_tooltip: 'Auto Save',
        secondary_events_label: 'Secondary Events',
        text_1_column: 'Text 1',
        text_2_column: 'Text 2',
        text_3_column: 'Text 3',
        prototypes_label: 'Prototypes',
        insert_break_actionbutton_menutext: 'Insert Break',
        delete_header_actionbutton_menutext: 'Delete Header',
        program_replacement_actionbutton_menutext: 'Program Replacement',
        attach_format_reload_actionbutton_menutext: 'Attach Format',
        history_actionbutton_menutext: 'History',
        select_program_to_replace_with_collection_title: 'Select program to replace',
        global_replacement_dialog_header: 'Global Replacement',
        replace_button_tooltip: 'Replace',
        replace_button_text: 'Replace',
        replacement_method_label: 'Replacement Method',
        include_isBreak_label: 'Include Break',
        replace_with_same_duration_only : 'Replace with same duration only',
        enable_cross_category_replacement : 'Enable Cross Category',
        replacement_material_label: 'Replacement Material',
        delete_all_button_tooltip: 'Delete All',
        delete_all_button_text: 'Delete All',
        group_all_button_tooltip: 'Group Media Episode',
        group_all_button_text: 'Group Media Episode',
        select_media_episode_dialoag_header: 'Select Media Episode',
        select_media_episode_group_dialoag_header: 'Select Media Episode Group',
        remove_filter_actionbutton_menutext: 'Remove Filter',
        filter_actionbutton_menutext: 'Filter',
        copy_actionbutton_menutext: 'Copy',
        paste_actionbutton_menutext: 'Paste',
        se_overview_actionbutton_menutext: 'SE Overview',
        global_replacement_actionbutton_menutext: 'Global Replacement',
        sales_team_label: 'Sales Team',
        department_label: 'Department',
        password_label: 'Password',
        phone_no_label: 'Phone No',
        pobox_label: 'PO Box',
        reporting_to_lable: 'Reporting To',
        role_label: 'Role',
        issalesexecutive_label: 'IsSalesExecutive',
        sales_executive_editpageheader: ' Sales Executive',
        email_column: 'Email',
        phone_no_column: 'Phone no',
        select_sales_executive_colllection_header: 'Select Sales Executive',
        select_collection_button_text: 'Select',
        export_planet_cast_button_tooltip: 'Export Planet Cast',
        check_list_button_tooltip: 'Check Lists',
        check_lists_dialog_header: 'Check Lists',
        success_label: 'Success',
        failure_lable: 'Failure',
        module_menu: 'Module',
        sales_team_dailog_header: 'Create Sales Team',
        module_dialog_header: 'Module',
        role_dialog_header: 'Role',
        admin_label: 'Admin',
        sales_label: 'Sales',
        media_management_label: 'Media Management',
        rights_manager_label: 'Rights Manager',
        role_editpageheader: 'Role',
        select_module_collection_header: 'Select Module',
        users_editpage_header: 'User',
        select_Role_collection_header: 'Select Role',
        module_column: 'Module',
        role_column: 'Role',
        add_role_collection_text: 'Add Role',
        add_role_button_text: 'Add From Role',
        add_module_collection_text: 'Add Module',
        sales_executive_editpage_header: 'Sales Executive',
        timeline_page_breadcrumb: 'Time Line',
        assets_type_page_breadcrumb: 'Assets Type',
        rails_type_page_breadcrumb: 'Rails Type',
        posters_type_page_breadcrumb: 'Posters Type',
        department_page_breadcrumb: 'Department',
        users_page_breadcrumb: 'Users',
        role_page_breadcrumb: 'Role',
        assets_page_breadcrumb: 'Assets',
        posters_page_breadcrumb: 'Posters',
        client_page_breadcrumb: 'Client',
        agency_page_breadcrumb: 'Agency',
        sales_team_page_breadcrumb: 'Sales Team',
        trailer_page_breadcrumb: 'Trailer',
        sales_executive_page_breadcrumb: 'Sales Executive',
        content_page_breadcrumb: 'Content',
        channel_page_breadcrumb: 'Channel',
        currency_page_breadcrumb: 'Currency',
        repeat_pattern_page_breadcrumb: 'Repeat Pattern',
        invoice_content_page_breadcrumb: 'Invoice Content',
        segment_type_page_breadcrumb: 'Segment Type',
        contract_page_breadcrumb: 'Contract',
        series_page_breadcrumb: 'Parent Series',
        season_page_breadcrumb: 'Series',
        programs_page_breadcrumb: 'Programs',
        episode_page_breadcrumb: 'Episode',
        movies_page_breadcrumb: 'Movies',
        interstitial_page_breadcrumb: 'Interstitial',
        commercial_page_breadcrumb: 'Commercial',
        music_page_breadcrumb: 'Music',
        filler_page_breadcrumb: 'Filler',
        break_page_breadcrumb: 'Break',
        group_page_breadcrumb: 'Group',
        program_group_page_breadcrumb: 'Program Group',
        interstital_group_page_breadcrumb: 'Interstital Group',
        schedule_constraints_page_breadcrumb: 'Schedule Constraints',
        campaign_page_breadcrumb: 'Campaign',
        utilisied_label: 'Utilisied',
        over_booked_label: 'Over Booked',
        counts_label: 'Counts',
        active_label: 'Active',
        top_10_agency_label: 'Top 10 Agency',
        top_10_client_label: 'Top 10 Client',
        pending_vs_on_air_booking_label: 'Pending vs OnAirBooking',
        revenue_by_location_label: 'Revenue By Location',
        revenue_by_months_label: 'Revenue By Months',
        booking_by_deal_type_label: 'Booking By DealType',
        time_range_label: 'Time Range',
        language_page_breadcrumb: 'Language',
        region_page_breadcrumb: 'Region',
        brand_page_breadcrumb: 'Brand',
        product_page_breadcrumb: 'Product',
        rails_page_breadcrumb: 'Rails',
        page_breadcrumb: 'Pages',
        content_caegory_page_breadcrumb: 'Content Category',
        ingestion_page_breadcrumb: 'Ingestion',
        jobs_page_breadcrumb: 'Jobs',
        prototype_page_breadcrumb: 'Prototype',
        prototype_style_sheet_page_breadcrumb: 'Prototype Style Sheet',
        default_prototype_style_sheet_page_breadcrumb: 'Default Prototype Style Sheet',
        prototype_source_page_breadcrumb: 'Prototype Source',
        export_template_page_breadcrumb: 'Export Template',
        form_fields_page_breadcrumb: 'Form Fields',
        secondary_event_dynamic_rule_page_breadcrumb: 'Secondary Event Dynamic Rule',
        format_page_breadcrumb: 'Format',
        time_range_page_breadcrumb: 'Time Range',
        company_page_breadcrumb: 'Company',
        bank_account_page_breadcrumb: 'Bank Account',
        tax_page_breadcrumb: 'Tax',
        licensor_page_breadcrumb: 'Licensor',
        delete_successfully_grid_data_success_toast_message: 'Delete Successfully',
        delete_all_successfully_grid_data_success_toast_message: 'All Delete Successfully',
        please_select_first_contract_collection_error_message: 'Please Select First!!',
        please_enter_contarct_number_contract_error_message: 'Please Enter Contract Number',
        please_select_platform_type_error_message: 'Please Select Platform Type',
        please_select_licensor_error_message: 'Please Select Licensor',
        please_select_payee_error_message: 'Please Select Payee',
        please_select_platform_error_message: 'Please Select Platform',
        please_select_channel_error_message: 'Please Select Channel',
        please_select_region_error_message: 'Please Select Region',
        please_select_content_error_message: 'Please Select Content',
        please_select_play_time_error_message: 'Please Select Play Time',
        please_select_media_category_type_error_message: 'Please Select Media Category Type',
        please_select_genres_media_episode_error_message: 'Please Select Genres',
        please_select_department_error_message: 'Please Select Department',
        please_enter_title_error_message: 'Please Enter Title',
        please_enter_description_error_message: 'Please Enter Description',
        please_enter_name_error_message: 'Please Enter Name',
        please_enter_phone_no_error_message: 'Please Enter Phone Number',
        please_enter_email_error_message: 'Please Enter Valid Email',
        please_select_roletype_error_message: 'Please Select RoleType',
        please_enter_user_name_users_error_message: 'Please Enter User Name',
        please_enter_password_users_error_message: 'Please Enter Password',
        invalid_duration_error_message: 'Please Enter Valid Duration',
        please_attach_atleast_one_media_episode_group_error_message: 'Please Attach atleast One Media',
        please_add_atleast_one_module_rights_users_error_message: 'Please add atleast one module rights',
        please_select_reportingto_user_error_message: 'Please Select Reporting To',
        please_select_sales_team_error_message: 'Please Select Sales Team',
        exhibition_should_not_be_zero_error_message: 'Exhibition should be greater than Zero',
        repeat_should_not_be_zero_error_message: 'Repeat should be greater than Zero',
        play_time_number_should_not_be_zero_error_message: 'Play Time Number should be greater than Zero',
        end_date_should_be_greater_than_start_date_error_message: 'End Date should be greater than Start Date',
        please_attach_atleast_one_media_contract_error_message: 'Please Attach atleast One Media',
        please_select_season_content_series_error_message: 'Please Select Season Content',
        value_is_not_greater_then_zero_season_number_series_error_message: 'Season number should be greater than zero',
        please_select_epiosde_content_series_error_message: 'Please Select Episode Content',
        value_is_not_greater_then_zero_episode_number_series_error_message: 'Episode number should be greater than zero',
        please_attach_series_season_error_message: 'Please Attach Series',
        please_add_atleast_one_season_series_error_message: 'Please Add atleast One Season',
        entering_same_season_number_twice_series_error_message: 'Entering same season number twice',
        enter_title_expender: 'Enter Title',
        please_select_poster_type_first_media_episode_poster_error_message: 'Please Select Poster type',
        please_select_url_first_media_episode_poster_error_message: 'Please Select URL first',
        please_select_the_value_in_start_date_media_episode_publishing_error_message: 'Please select the value in Start Date',
        please_select_the_value_in_end_date_media_episode_publishing_error_message: 'Please select the value in End Date',
        you_delete_this_deal_right_from_contract_only_media_episode_publishing_error_message: 'You delete this deal right from contract only !!',
        please_select_ottvideo_type_media_episode_video_error_message: 'Please Select Video Type',
        create_dialog_header: 'Create',
        synopsis_can_not_be_null_media_episode_Synopsis_error_message: 'Synopsis can not be Null',
        please_select_language_media_episode_Synopsis_error_message: 'Please Select language',
        please_enter_name_cast_crew_media_episode_error_message: 'Please Enter Name',
        please_select_casttype_cast_crew_media_episode_error_message: 'Please Enter Cast Type',
        please_select_segment_type_media_episode_version_error_message: 'Please Select Segment type',
        please_generate_segments_first_media_episode_version_error_message: 'Please Generate Segments',
        media_episode_entity_duration_segment_error_message: 'Actual Duration is null. Please update valid duration in the Duration to Apply segmentation',
        please_select_brand_media_episode_error_message: 'Please select Brand',
        please_select_product_media_episode_error_message: 'Please select Product',
        please_enter_value_media_episode_error_message: 'Please Enter Value',
        please_enter_valid_value_tcin_should_not_be_greater_than_tcout_media_episode_error_message: 'Please enter valid value, TcIn should not be greater than TcOut',
        error_while_generating_house_number_media_episode_error_message: 'Error While Generating House Number',
        already_exists_global_error_message: 'Already Exists !!',
        you_can_update_this_deal_right_from_contract_only_media_episode_publishing_error_message: 'You can update this deal right from contract only !!',
        this_segment_is_already_been_scheduled_segmnet_media_episode_error_message: 'This segment is already been scheduled',
        please_enter_title_display_rails_error_message: 'Please Enter Title Display',
        atleast_attach_one_ottassets_rails_error_message: 'Atleast attach one OttAssets',
        please_enter_enter_icon_uel_error_message: 'Please Enter Icon URL',
        create_label: 'Create',
        edit_lable: 'Edit',
        select_label: 'Select',
        please_select_media_category_type_first_media_episode_group_error_message: 'Please select Media Category Type first',
        please_select_role_users_error_message: 'Please Select Role',
        user_name_error_message: 'User/Sale Executive with same UserName',
        no_data_found_scheduling_reconciliation_error_message: 'No Data Found !',
        schedule_and_as_run_data_load_successfully_scheduling_reconciliation_success_message: 'Schedule and AsRun data loaded successfully',
        error_while_loading_as_run_data_scheduling_reconciliation_error_message: 'Error while loading AsRun data : ',
        auto_recocile_successfully_scheduling_reconciliation_success_message: 'Auto reconciled successfully !!',
        select_first_scheduling_reconciliation_error_message: 'Please Select Schedule and As run Item To Match !',
        already_reconsiled_scheduling_reconciliation_error_message: 'Already Reconsiled !',
        select_item_have_different_assetid_scheduling_reconciliation_error_message: 'Selected item have different AssetId !',
        force_unmatch_successfully_scheduling_reconciliation_success_message: 'Force Unmatched successfully !!',
        please_select_schedule_and_as_run_item_scheduling_reconciliation_error_message: 'Please select schedule and AsRun item !',
        select_item_is_not_reconsiled_scheduling_reconciliation_error_message: 'Selected item is not reconsiled !',
        select_schedule_and_asrun_item_not_reconciled_together_scheduling_reconciliation_error_message: 'Selected schedule and asrun item not reconciled together',
        select_schedule_item_already_billed_error_message: 'Selected schedule item is already billed.',
        saved_successfully_scheduling_reconciliation_success_message: 'Saved Successfully !!!',
        role_type_label: 'Role Type',
        please_load_first_scheduling_reconciliation_error_message: 'Please load first !!',
        tx_mode_label: 'TX Mode',
        segment_count_label: 'Segment Count',
        create_segment_type_dialog_header: 'Create Segment Type',
        please_enter_type_name_segment_type_error_message: 'Please Enter Type Name',
        please_select_tx_mode_segment_type_error_message: 'Please Select TX Mode',
        please_enter_segment_count_segment_type_error_message: 'Please Enter Segment Count',
        interstitial_group_sub_menu: 'Interstitial Group',
        program_group_sub_menu: 'Program Group',
        variation_page_breadcrumb: 'Variation',
        breakformat_page_breadcrumb: 'Break Format',
        payment_provider_page_breadcrumb: 'Payment Provider',
        ott_period_type_page_breadcrumb: 'Ott Period Type',
        payment_type_page_breadcrumb: 'Payment Type',
        ott_period_page_breadcrumb: 'Period',
        subscriber_page_breadcrumb: 'Subscriber',
        media_type_label: 'Media Type',
        atleast_attach_one_item_variation_error_message: 'Atleast attach one Item',
        please_select_media_type_error_message: 'Please Select Media Type',
        season_number_should_not_be_zero_error_message: 'Season Number should be greater than Zero',
        number_of_epiosde_should_not_be_zero_error_message: 'Number Of Episode should be greater than Zero',
        starting_epiosde_should_not_be_zero_error_message: 'Starting Episode should be greater than Zero',
        please_select_potraitimageurl_otteditform_error_message: 'Please Select Potrait Image URL',
        please_select_landscapeimageurl_otteditform_error_message: 'Please Select Landscape Image URL',
        include_pre_opening_label: 'Include Pre Opening',
        include_closing_label: 'Include Closing',
        break_number_column: 'Break Number',
        break_duration_column: 'Break Duration',
        commercial_duration_column: 'Commercial Duration',
        promo_duration_Column: 'Promo Duration',
        total_break_duration_label: 'Total Break Duration',
        segment_count_should_be_zero_error_message: 'Segment Count should be greater than one',
        invalid_slot_duration_error_message: 'Invalid Slot Duration',
        invalid_total_break_duration_error_message: 'Invalid Total Break Duration',
        break_not_distribute_breakformat_error_message: 'Breaks not distributed',
        slot_duration_should_Be_greater_than_total_break_duration_breakformat_error_message: 'Slot Duration should be greater than total Break Duration',
        break_count_segment_count_does_not_match_breakformat_error_message: 'Break Count and Segment Count does not match',
        please_enter_template_name_error_toast: 'Please Enter Template Name',
        please_select_module_type_error_message: 'Please Select Module Type',
        please_select_ingestion_template_type_error_message: 'Please Select Ingestion Template Type',
        saved_successfully_message: 'Saved Successfully',
        please_enter_code_error_message: 'Please Enter Code',
        please_select_agency_error_message: 'Please Select Agency',
        please_select_default_tax_error_message: 'Please Select Default TAX',
        please_select_atleast_one_Sales_executive: 'Please Select Atleast One Sales Executive',
        add_sales_executive_button_tooltip: 'Add Sales Executive',
        please_enter_prefix_error_message: 'Please Enter Prefix',
        please_enter_suffix_error_message: 'Please Enter Suffix',
        please_attach_atleast_one_media_error_message: 'Please attach atleast one media',
        please_enter_full_Channel_name_error_message: 'Please Enter Full Channel Name',
        please_enter_short_Channel_name_error_message: 'Please Enter Short Channel Name',
        please_select_atleast_one_repeat_pattern_error_message: 'Please select atleast one repeat !',
        primary_delete_succesfully_repeat_pattern_error_message: 'Primary delete succesfully',
        repeat_delete_succesfully_repeat_pattern_error_message: 'Repeat delete succesfully',
        please_select_atleast_one_days_repeat_pattern_error_message: 'Please select atleast one days !',
        please_enter_automation_channel_name_error_message: 'Please Enter Automation Channel Name',
        please_enter_epg_code_error_message: 'Please enter EPG Code',
        please_select_automation_error_message: 'Please Select Automation',
        please_enter_color_error_message: 'Please Enter Color',
        please_add_atleast_one_module_role_error_message: 'Please add atleast one module !',
        data_loaded_successfully_ingestion_template_message: 'Data Loaded Successfully',
        please_enter_positive_value_in_creditlimit_error_message: 'Please Enter positive value in CreditLimit',
        please_enter_gst_no_error_message: 'Please Enter GST Number',
        please_enter_campaign_no_error_message: 'Please Enter Campaign Number',
        please_select_client_error_message: 'Please Select Client',
        period_from_should_be_less_then_periodto_campaign_error_message: 'PeriodFrom should be less then PeriodTo',
        commission_exceeded_from_limit_campaign_error_message: 'Commission exceeded from limit.',
        discount_exceeded_from_limit_campaign_error_message: 'Discount exceeded from limit.',
        please_enter_target_budget_error_message: 'Please Enter Target Budget',
        please_enter_target_spots_error_message: 'Please Enter Target Spots',
        please_enter_target_seconds_error_message: 'Please Enter Target Seconds',
        please_attach_atleast_one_commercials_campaign_error_message: 'Please attach atleast one commercials',
        please_attach_atleast_one_channel_campaign_error_message: 'Please attach atleast one channel',
        please_select_campaign_type_error_message: 'Please Select Campaign Type',
        please_enter_rate10sec_error_message: 'Please Select Rate10Sec',
        please_enter_fct_error_message: 'Please Enter FCT',
        please_enter_from_date_error_message: 'Please Enter From Date',
        please_enter_to_date_error_message: 'Please Enter To date',
        please_enter_amount_error_message: 'Please Enter Amount',
        data_loading_error_message: 'Data Loading...',
        no_record_found_error_message: 'No Record Found !',
        total_sum_spot_rate_success_message: 'Total Sum Spot Rate',
        total_fct_success_message: 'Total FCT',
        booking_is_used_in_schedule_show_spot_error_message: 'Booking is used in schedule',
        changes_Will_Reflect_in_new_planned_items_only_segmenttype_error_message: 'Changes Will Reflect In New Planned Items Only',
        please_select_campaign_first_booking_error_message: 'Please Select Campaign First',
        error_reading_file_booking_error_message: 'Error Reading File',
        wrong_month_selected_booking_error_message: 'Wrong Month Selected',
        wrong_year_selected_booking_error_message: 'Wrong Year Selected',
        error_downloading_template_booking_error_message: 'Error Downloading Template',
        please_select_a_value_to_update_booking_error_message: 'Please Select a value to update',
        exceding_target_spots_limit_booking_error_message: 'Exceding target spots limit',
        exceding_target_budget_booking_error_message: 'Exceding target budget',
        commercials_not_updated_in_line_no_booking_error_message: 'Commercials Not Updated In Line No',
        exceding_target_seconds_booking_error_message: 'Exceding target seconds',
        booking_saved_booking_error_message: 'Booking Saved',
        no_data_found_booking_error_message: 'No Data Found',
        from_time_should_be_less_than_To_time_booking_error_message: 'From Time should be less then To Time',
        please_enter_spot_booking_error_message: 'Please Enter Spot',
        please_enter_rate_booking_error_message: 'Please Enter Rate',
        please_select_transfer_from_date_booking_transfer_error_message: 'Please select Transfer From Date',
        please_select_transfer_date_booking_transfer_error_message: 'Please select Transfer Date',
        please_select_item_booking_transfer_error_message: 'Please Select item first !',
        booking_transfer_succesfully_to_booking_transfer_error_message: 'Booking Transfer Succesfully To',
        exceding_target_spots_limit_update_booking_line_number_error_message: 'Exceding target spots limit',
        exceding_target_budget_update_booking_line_number_error_message: 'Exceding target budget',
        exceding_target_seconds_update_booking_line_number_error_message: 'Exceding target seconds',
        loading_data_update_booking_line_number_error_message: 'Loading data !!',
        data_loaded_update_booking_line_number_success_message: 'Data loaded successfully!!',
        loading_booking_update_booking_line_number_success_message: 'Loading Booking...',
        bill_is_already_generated_for_this_linenumber_update_booking_line_number_success_message: 'Bill is already generated for this LineNumber !!',
        please_select_file_error_message: 'Please select file',
        downlaod_file_error_message: 'Download File',
        no_import_type_defined_error_message: 'No Import Type Defined',
        upload_Successfully_import_success_message: 'Upload Successfully',
        asrun_Save_Successfully_import_success_message: 'AsRun Save Successfully',
        asrun_save_failed_import_error_message: 'AsRun Save Failed',
        update_failed_import_error_message: 'Upload Failed',
        assest_not_found_import_message: 'Assest Not Found',
        planning_imported_successfully_import_message: 'Planning Imported Successfully',
        import_planning_failed_import_message: 'Import Planning Failed.',
        read_to_upload_import_message: 'Read to Upload',
        please_select_from_date: 'Please select From Date',
        please_select_to_date_greater_than_from_date_error_message: 'Please select To Date greater than From Date',
        please_select_the_value_in_template_name_first_export_report_error_message: 'Please select the value in template name first !',
        please_select_the_value_in_format_first_export_report_error_message: 'Please select the value in format first',
        please_add_at_least_one_item_list_export_report_error_message: 'Please add at least one item list.',
        please_select_the_value_in_filters_first_export_report_error_message: 'Please select the value in filters first.',
        Please_select_the_value_in_Operator_first_export_report_error_message: 'Please select the value in Operator first.',
        value_can_not_be_null_export_report_error_message: 'Value Can not be Null',
        report_is_exported_successfully_message: 'EPG is exported successfully',
        please_select_valid_dates_export_template_error_message: 'Please select valid dates',
        exporting_data_please_wait_export_template_error_message: 'Exporting data please wait....',
        successfully_exported_export_template_message: 'Successfully Exported',
        no_data_found_to_export_template_message: 'No data found to export',
        please_select_the_value_in_available_column_export_template_error_message: 'Please select the value in Available Column',
        please_enter_display_name_error_message: 'Please Enter Display Name',
        please_enter_savetempleteas_export_template_error_message: 'Please Enter Save Templete As',
        please_add_column_atleast_one_item_export_template_error_message: 'Please Add atleast one Column',
        is_saved_successfully_export_template_message: ' is saved successfully',
        please_select_module_error_message: 'Please Select Module',
        please_enter_id_error_message: 'Please Enter ID',
        please_select_source_error_message: 'Please Select Source',
        please_select_atleast_one_secondary_events_error_message: 'Please select atleast one secondary events',
        invalid_startoffset_error_message: 'Invalid Start Offset',
        invalid_endoffset_error_message: 'Invalid End Offset',
        please_enter_text_no_error_message: 'Please Enter Text Number',
        please_select_the_value_in_rule_first_error_message: 'Please select the value in rule first',
        please_attach_atleast_one_prototype_stylesheet_error_message: 'Please attach atleast one prototype',
        please_enter_formula_error_message: 'Please Enter Formula',
        please_wait_loading_data_billing_error_message: 'Please wait, loading data...',
        data_loaded_successfully_billing_template_message: 'Data Loaded Successfully',
        please_select_a_billing_detail_first_billing_template_message: 'Please select a billing detail first !',
        no_reconciled_spots_found_for_selected_details_billing_template_message: 'No reconciled spots found for selected details !',
        please_select_bank_billing_error_message: 'Please Select Bank !',
        please_select_tax_billing_error_message: 'Please Select Tax !',
        please_select_brand_billing_error_message: 'Please Select Brand !',
        no_invoice_details_are_available_to_bill_billing_error_message: 'No invoice details are available to bill !',
        campaign_brands_label: 'Brand',
        all_label: 'All',
        selected_brand: 'Selected Brand',
        selected_channel: 'Selected Channel',
        brand_label: 'Brand',
        please_select_file_first_datamigration_error_message: 'Please select file first !!!',
        empty_media_grid_datamigration_error_message: 'Please load data in below grid to import !!',
        please_select_type_datamigration_error_message: 'Please select type !!',
        imported_successfully_datamigration_success_message: 'Imported Successfully',
        please_select_language_error_message: "Please Select Language",
        please_select_logo_url_first_error_mesage: 'Please select logo url first.',
        please_select_segment_type_and_channel_format_error_message: 'Please select segment type and channel',
        new_create_formfield_dialog_header: 'New Create',
        please_select_segment_type_format_error_message: 'Please Select Segment Type',
        not_allowed_for_segments_format_error_message: 'Not allowed for segments',
        cannot_delete_segments_format_error_message: 'Cannot delete segment',
        select_atleast_one_mediaDealRight_planner_error_message: 'Select atleast one Media Deal Right',
        first_copy_planner_items_you_want_to_paste_planner_error_message: 'First copy planner items you want to paste.',
        please_select_area_on_planner_where_to_paste_planner_error_message: 'Please select area on planner where to paste.',
        from_date_cant_be_greater_than_to_date_paste_planner_error_message: 'From date cant be greater than to date !',
        call_planner_error_message: 'call',
        data_copy_schedule_please_wait_copyschedule_error_message: 'Please wait....',
        copy_schedule_data_copyschedule_successfully_message: 'Copy schedule data copy successfully....',
        no_data_found_to_copy_schedule_error_message: 'No data found to copy schedule',
        applying_recursive_pattern_please_wait_planner_error_message: 'Applying Recursive Pattern, Please Wait...',
        recursive_pattern_applied_successfully_planner_success_message: 'Recursive Pattern Applied Successfully',
        pasted_pattern_planner_error_message: 'Pasted',
        items_pattern_planner_error_message: 'items',
        to_date_is_mandatory_recursive_error_message: 'To Date is Mandatory.',
        to_date_should_be_greater_than_base_program_start_date_recursive_error_message: 'To Date should be greater than Base Program Start Date',
        select_one_item_to_open_schedule_planner_helper_error_message: 'select one item to open schedule',
        copy_button_text: 'Copy',
        open_schedule_button_text: 'Open Schedule',
        paste_button_text: 'Paste',
        delete_button_text: 'Delete',
        error_fetching_channel_list_planning_wrapper_error_message: 'Error fetching channel list',
        please_select_planning_item_planning_wrapper_error_message: 'Please Select Planning Item',
        recursive_applied_successfully_planning_wrapper_message: 'Recursive Applied Successfully !!',
        select_program_group_label: 'Select Program Group',
        select_break_format__label: 'Select Break Format',
        please_enter_template_name_to_save_template_planningfilter_error_message: 'Please enter template name to save template',
        selected_content_text_planning_footer: 'Selected Content',
        copied_content_text_planning_footer: 'Copied Content',
        first_copy_planner_items_you_want_to_paste_planning_performance_error_message: 'First copy planner items you want to paste.',
        please_select_area_on_planner_where_to_paste_planning_performance_error_message: 'Please select area on planner where to paste.',
        select_atleast_one_mediadealright_planning_performance_error_message: 'select atleast one deal right',
        first_select_replace_items_global_replacement_error_message: 'First select replace items',
        please_select_content_to_delete_planning_performance_error_message: 'Please select content to delete',
        not_allowed_Control_with_schedule_planning_performance_error_message: 'Not Allowed, Control with schedule',
        entry_already_exist_try_with_different_values_collection_error_message: 'Entry already exist, Try with different values.',
        please_select_first_collection_error_message: 'Please Select First !',
        already_attached_collection_error_message: 'Already attached !!',
        export_current_booking_tooltip: 'Export Current Booking',
        export_saved_booking_tooltip: 'Export Saved Booking',
        please_select_poster_type_error_message: 'Please Select Poster Type',
        browser_button_text: 'Browse',
        file_selected_label: 'File Selected',
        only_jpg_jpeg_and_png_files_are_allowed_msg: 'Only jpg/jpeg and png files are allowed!',
        please_add_programs_format_error_message: 'Please add programs format',
        please_select_atleast_one_item: 'Please select atleast one item.',
        please_enter_season_number_error_message: 'Please Enter Season Number',
        repeat_pattern_label: 'Repeat Pattern',
        select_repeat_pattern_collection_header: 'Select Repeat Pattern',
        please_select_from_date_error_message: 'Please Select From Date',
        please_select_to_date_error_message: 'Please Select To Date',
        please_select_to_date_grater_then_from_date_error_message: 'Please select To Date greater than From Date',
        parent_content_category_label: 'Parent Content Category',
        rail_will_be_added_soon2_label: 'Rail will be added soon2!',
        rail_will_be_added_soon3_label: 'Rail will be added soon3!',
        no_select_image_error_message: 'No Select Image',
        please_enter_key_error_message: ' Please Enter Key',
        please_enter_value_error_message: 'Please Enter Value',
        please_wait_generating_bill: 'Generating Bill. Please Wait...',
        bill_generated_successfully: 'Bill Generated Successfully',
        please_select_template_error_message: 'Please Select Template',
        please_select_file_first_error_message: 'Please Select File First !',
        please_enter_first_error_message: 'Please Enter First !',
        please_select_channel_first: 'Please select channel first',
        please_select_start_date_error_message: 'Please Select Start Date',
        please_select_end_date_error_message: 'Please Select End Date',
        please_enter_valid_sequnce_number_error_message: 'Please Enter valid Sequnce Number',
        program_schedule_clash_breadcrumb: 'Program Schedule Clash',
        clash_channel_column: 'Clash Channel',
        clashed_time_Column: 'Clashed Time',
        source_time_Column: 'Source Time',
        no_data_found_error_message: 'No Data Found !',
        movie_name_column: 'Movie Name',
        create_ott_period_type_dialog_header: 'Create Ott Period',
        create_subscriber_dialog_header: 'Create Subscriber',
        play_type_label: 'Play Type',
        ott_period_type_label: 'Ott Period Type',
        please_enter_play_time_error_message: 'Play time should be greater than zero',
        please_select_play_type_error_message: 'Please Select Play Type',
        please_select_ott_period_type_error_message: 'Please Select Ott Period Type',
        ott_period_label: 'Ott Period',
        payment_type_label: 'Payment Type',
        trials_tab_label: 'Trials',
        fixed_trials_tab_label: 'Fixed Trials',
        reminders_tab_label: 'Reminders',
        payment_Provider_label: 'Payment Provider',
        send_label: 'Reminder',
        dayPrior_label: 'Prior Reminder Day',
        enable: 'Enable',
        trials_dialog_header: 'Trials',
        reminders_dialog_header: 'Reminders',
        add_trial_button_tooltip: 'Add Trial',
        add_reminders_button_tooltip: 'Add Reminders',
        paymentPlan_sub_menu: 'Payment Plan',
        please_select_Payment_Provider_error_message: 'Please Select Payment Provider',
        please_select_PlayType_error_message: 'Please Select Play Type',
        please_select_OttPeriod_error_message: 'Please Select Ott Period',
        please_select_Currency_error_message: 'Please Select Currency',
        please_select_PaymentType_error_message: 'Please Select Payment Type',
        ott_product_sub_menu: 'Product',
        ott_product_label: 'Product',
        sales_status_label: 'Sales Status',
        geo_Region_label: 'Geo Region',
        create_ott_product_type_dialog_header: 'Create Ott Product',
        please_select_geo_region_error_message: 'Please Select Geo Region',
        please_select_sales_status_error_message: 'Please Select Sales Status',
        please_select_Payment_Plan_error_message: 'Please Select Payment Plan',
        show_app_bar_label: 'Show App Bar',
        isHomePage_label: 'IsHomePage',
        route_type_label: 'Route Type',
        page_label: 'Page',
        rails_l_label: 'L',
        rails_uat_label: 'U',
        live_label: 'Live',
        uat_label: 'UAT',
        media_is_already_attached_with_another_asset_error_message: 'Media is already attached with another asset',
        add_payment_plan_tooltip: 'Add Payment Plan',
        trial_price_label: 'Trial Price',
        trial_days_label: 'Trial Days',
        sort_index_label: 'Sort Index',
        hide: 'Hide',
        select_payment_plan_collection: 'Select Payment Plan',
        payments_dialog_header: 'Payments',
        daysBefore_label: 'Days Before',
        subscription_id_label: 'SubscriptionID',
        package_label: 'Package',
        last_updated_label: 'Last Update',
        please_enter_subscription_id_error_message: 'Please Enter SubscriptionID',
        please_enter_package_error_message: 'Please Enter Package',
        please_select_status_error_message: 'Please Select Status',
        please_enter_last_update_error_message: 'Please Enter Last Update',
        please_select_route_type: "Please select route type",
        please_select_video_playback_url: 'Please select video Playback URL',
        please_select_page: 'Please Select Page',
        ott_campaign_page_breadcrumb: 'Campaign',
        edit_campaign_editpage_header: 'Edit Ott Campaign',
        add_rails_button_tootip: 'Add Rails',
        select_rails_collection: 'Select Rails',
        publish_column: 'Publsih',
        from_date_should_be_less_than_to_date_error_message: 'From date should be less than To date',
        please_select_rails_error_message: 'Please Select Atleast One Rail',
        ott_rails_type_column: 'Ott Rails Type',
        add_above_option: 'Add Above',
        add_below_option: 'Add Below',
        select_any_one_collection_selection_error_message: 'Select any one',
        please_attach_media_first: 'Please attach media',
        edit_actionbutton_option: 'Edit',
        profile_label: 'Profile',
        asset_label: 'Asset ID',
        job_id_label: 'Job ID',
        job_started_label: 'Started',
        job_finished_label: 'Finished',
        elapsed_label: 'Elapsed',
        job_input_file_label: 'Input File',
        media_type_column: 'Media Type',
        file_location_column: 'File Location',
        attach_media_button_tooltip: 'Attach Media',
        attach_media_button_text: 'Attach Media',
        select_media_collection_header: "Select Media",
        assets_label_ott_collection: 'Assets',
        select_assets_collection_header: "Select Assets",
        some_bookings_are_already_Scheduled_Could_not_transfer_error_message: 'Some bookings are already Scheduled, Could not transfer',
        jobs_title: 'Jobs',
        total_assets_label: 'Total Assets',
        makegoodspot_sub_menu: 'Make Good Spot',
        makegoodspot_title: 'Make Good Spot',
        makegoodspottransfer_dialog_header: 'Make Good Spot Transfer',
        select_item_date_between_from_and_to_error_message: 'Selected transfer date should be lie between campaign period from and to date ',
        please_transfer_error_date_greater_than_bookingdate_message: 'Please Select transfer date greater than from current booking date!',
        latest_jobs_label: 'Latest Jobs',
        invalid_date_range_copy_to_planning_error_message: 'Invalid Copy To Date Range',
        invalid_date_range_copy_from_planning_error_message: 'Invalid Copy From Date Range',
        date_range_between_seven_day_copy_from_planning_error_message: 'Copy From Date range should be within 7 days',
        date_range_between_seven_day_copy_to_planning_error_message: 'Copy To Date range should be within 7 days',
        height_label: "Height",
        create_ott_asset_label: 'Create Ott Asset',
        media_label: 'Media',
        recent_jobs_label: 'RECENT JOBS',
        please_select_type_error_message: 'Please Select Type',
        create_schedule_constraints_editpage_header: 'Create Schedule Constraints',
        schedule_constraints_error_message: 'Schedule Constraints',
        constraints_lable: 'Constraints',
        fill_under_run_button_tooltip: 'Fill Schedule Based On Variations and Default Format',
        resetautofill_button_tooltip: 'Reset Auto Fill',
        please_select_amount_error_message: 'Please Select Amount',
        please_enter_sort_index_error_message: 'Please enter sort index',
        please_enter_trial_price_error_message: 'Please enter trial price',
        please_enter_trial_days_error_message: 'Please enter trial days',
        please_select_reminder_error_message: 'Please select reminder',
        please_enter_days_before_error_message: 'Please enter days before',
        enable_reminder_label: 'Enable Reminder',
        coupon_page_breadcrumb: 'Coupon',
        discount_type_label: 'Discount Type',
        discount_value_label: 'Discount Value',
        create_coupon_dialog_header: 'Create Coupon',
        please_select_discount_type_error_message: 'Please Select Discount Type',
        percentage_can_not_be_greater_than_error_message: 'Percentage can not be greater than 100',
        enable_fixed_trial_label: 'Enable Fixed Trial',
        limited_period_label: 'Limited Period',
        valid_from_label: 'Valid From',
        valid_to_label: 'Valid To',
        please_select_valid_from_date_error_message: 'Please Select Valid From',
        please_select_valid_to_date_error_message: 'Please Select Valid To',
        discount_should_be_greater_then_zero_error_message: 'Discount should be greater than zero',
        please_select_valid_date_error_message: 'Please Select Valid Date',
        payment_plan_label: 'Payment Plan',
        coupon_label: 'Coupon',
        add_coupon_tooltip: 'Add Coupon',
        select_coupon_collection: 'Select Coupon',
        code_column: 'Code',
        discount_type_column: 'Discount Type',
        discount_value_column: 'Discount Value',
        audit_log_menu: 'Audit Log',
        audit_log_breadcrumb: 'Audit Log',
        genre_sub_menu_: 'Genre',
        media_category_sub_menu: 'Media Category',
        genre_page_breadcrumb: 'Genre',
        media_category_page_breadcrumb: 'Media Category',
        module_label: 'Module',
        event_label: 'Event',
        please_select_event_error_message: 'Please Select Event',
        download_button_tooltip: 'Download',
        date_time_label: 'DateTime',
        confirm_password_label: 'Confirm Password',
        password_do_not_match_error_message: 'Password Do Not Match',
        please_enter_confirm_password_users_error_message: 'Please Enter Confirm Password',
        atleast_attach_atleast_one_platform_error_message: 'Please select atleast one Platform',
        upload_file_button_tooltip: 'Upload File',
        role_tab_label: 'Role',
        add_channel_button_text: 'Add Channel',
        select_channel_collection_header: 'Select Channel',
        please_add_atleast_one_channel_users_error_message: 'Please add atleast one channel',
        please_select_order_date_error_message: 'Please Select Order Date',
        please_select_period_from_date_error_message: 'Please Select Period From',
        please_select_period_to_date_error_message: 'Please Select Period To',
        please_select_deal_type_error_message: 'Please Select Deal Type',
        please_select_location_error_message: 'Please Select Location',
        please_select_product_error_message: 'Please Select Product',
        ott_segment_sub_menu: 'Segment',
        payment_plan_page_breadcrumb: 'Payment Plan',
        ott_segment_page_breadcrumb: 'Segment',
        create_segment_dialog_header: 'Create Segment',
        segment_label: 'Segment',
        please_select_segment_error_message: 'Please Select Segment',
        monetization_dashboard: 'Monetization',
        segment_by_subscribe_label: 'Segment by Subscriber',
        segment_by_product_label: 'Segment by Product',
        products_column: 'Products',
        total_coupon_column: 'Total Coupon',
        total_subscriber_column: 'Total Subscriber',
        coupon_name_column: 'Coupon Name',
        pgrating_sub_menu: 'PG Rating',
        pgrating_page_breadcrumb: 'PG Rating',
        open_in_a_new_tab_label: 'Open in a new tab',
        column_preference_label: 'Column Preference',
        parent_label: 'Parent',
        overrun_underrun_label: 'OverRun & UnderRun',
        copy_count_label: 'Copy Count',
        selected_header_label: 'Selected Header',
        selected_count_label: 'Selected Count',
        platform_column: 'Platform',
        please_enter_old_password_users_error_message: 'Please Enter Old Password',
        old_password_does_not_match_error_message: 'Old password does not match',
        please_enter_new_password_error_message: 'Please Enter New Password',
        confirm_password_do_not_match_error_message: 'Confirm password do not match',
        old_password_label: 'Old Password',
        new_password_label: 'New Password',
        account_label: 'Account',
        change_password_label: 'Change password',
        log_out_label: 'Log out',
        media_by_category_menu: 'Media By Category',
        movie_label: 'Movie',
        season_label: 'Season',
        view_details_label: 'View Details',
        executive_label: 'Executive',
        subscriber_label: 'Subscriber',
        confirm_logout_label: 'Confirm Logout',
        are_you_sure_you_want_to_generate_payment: 'Are you sure you want to Generate Payment ?',
        yes_label: 'Yes',
        no_label: 'No',
        confirm_delete_label: 'Confirm Delete',
        are_you_sure_you_want_to_delete: 'Are you sure you want to delete ?',
        scheduling_label: 'Scheduling',
        planning_label: 'Planning',
        options_label: 'Options',
        un_archive_label: 'Un-Archive',
        create_from_label: 'Create From',
        category_label: 'Category',
        select_category_label: 'Select Category',
        grid_view_label: 'grid view',
        tile_view_label: 'tile view',
        linear_label: 'Linear',
        non_linear_label: 'Non-Linear',
        audit_log_label: 'Audit Log',
        entry_date_and_time_column: 'Entry Date Time',
        event_column: 'Event',
        user_column: 'User',
        close_button_tooltip: 'Close',
        apply_recursive_button_text_planning: 'Apply Recursive',
        history_button_text_planning: 'History',
        open_schedule_button_text_planning: 'Open Schedule',
        insert_program_group_label_planning: 'Insert Program Group',
        export_planner_button_text: 'Export Planner',
        export_epg_button_text: 'Export EPG ',
        planning_loaded_label: 'Planning Loaded',
        planning_loading_error_label: 'Planning Loading Error',
        error_fetching_planning_data_error_message: 'Error fetching planning data',
        planning_loading_label: 'Planning Loading',
        creating_label: 'Creating',
        successfully_created_group_label: 'Successfully created group!',
        pasted_label: 'Pasted ',
        items_label: ' items',
        last_updated_from_label_msg: 'Last Updated from ',
        cannot_delete_segment_label_msg: 'Cannot delete segment',
        cannot_delete_header_label_msg: 'Cannot delete header, Use delete header on header right click',
        select_item_to_error_message: 'Select item to',
        cannot_cut_footer_msg_scheduling: 'Cannot cut footer.',
        cannot_delete_multiple_footer_msg_scheduling: 'Cannot delete multiple footer.',
        cannot_delete_multiple_footer_please_select_one_footer_at_a_time_msg_scheduling: 'Cannot delete multiple footer, Please select one footer at a time.',
        no_item_selected_to_copy_msg_scheduling: 'No item selected to copy',
        cannot_copy_segment_msg_scheduling: 'Cannot copy - SEGMENT',
        cannot_copy_break_msg_scheduling: 'Cannot copy - Break',
        cannot_copy_header_msg_scheduling: 'Cannot copy - Header',
        deleting_footer_msg_scheduling: 'Deleting Footer',
        footer_deleted_msg_scheduling: ' footer Deleted ',
        no_footer_to_deleted_error_message: 'No footer to deleted',
        all_footer_deleted_error_message: 'All footer Deleted',
        there_are_some_at_server_side_please_contact_admin: 'There are some at server side, Please contact admin',
        loading_schedule_please_wait_msg_scheduling: 'Loading Schedule, Please wait...',
        loading_schedule_completed_msg_scheduling: 'Loading Schedule Completed !!',
        please_selct_one_item_msg_scheduling: 'Please selct one item',
        no_media_selected_msg_scheduling: 'No Media Selected',
        deleted_secondary_events_msg_scheduling: 'Deleted secondary events',
        all_secondary_events_deleted_successfully_msg_scheduling: 'All Secondary Events Deleted Successfully !!',
        style_sheet_applied_successfully_msg_scheduling: 'Style Sheet Applied Successfully !!',
        secondary_event_updated_msg_scheduling: 'Secondary event updated',
        no_secondary_events_error_message: 'No Secondary Events',
        select_target_item_from_schedule_error_message: 'Select target item from schedule',
        first_cut_or_copy_item_error_message: 'First cut or copy item.',
        please_select_Item_to_paste_error_message: 'Please select Item to paste.',
        Please_select_one_Item_where_need_to_paste_error_message: 'Please select one Item, where need to paste.',
        delete_msg: 'delete',
        cut_msg: 'delete',
        cannot_segment_please_unselect_segment_error_message: 'Segment Can\'t be deleted, Please unselect segment',
        cannot_delete_header_please_unselect_header_error_message: 'Header Can\'t be deleted, Please unselect header or use right click delete header option.',
        Confirmation_label: 'Confirmation',
        are_you_sure_you_want_to_delete_footer_label: 'Are you sure you want to delete footer?',
        Are_you_sure_you_want_to_items_label_confirm_alert: 'Are you sure you want to',
        cannot_select_footer_error_message: 'Cannot select footer.',
        cannot_multi_select_items_from_different_headers_error_message: 'Cannot multi select items from different headers.',
        please_select_first_error_message: 'Please select first.',
        cannot_select_items_from_different_headers_error_message: 'Cannot select items from different headers.',
        please_choose_right_media_categor_error_message: 'Please choose right media category',
        prototype_drop_not_allowed_for_headers_error_message: 'Prototype drop not allowed for headers',
        break_type_item_is_not_allowed_error_message: 'Break type item is not allowed.',
        cannot_move_segment_to_different_program_schedule_error_message: 'Cannot move segment to different program schedule',
        prototype_drop_not_allowed_for_headers_and_footers_error_message: 'Prototype drop not allowed for headers and footers',
        please_select_header_firsts_error_message: 'Please Select Header First',
        cannot_delete_selected_header_contains_booked_commercials_error_message: 'Cannot Delete !! Selected Header Contains Booked Commercials',
        cannot_delete_selected_header_contains_reconciled_items_error_message: 'Cannot Delete !! Selected Header Contains Reconciled Items',
        confirm_delete_header_confirm_alert_msg: 'Confirm Delete Header',
        are_you_sure_you_want_to_delete_confirm_alert_msg: 'Are you sure you want to delete ',
        select_one_program_to_replace_with_error_message: 'Select one program to replace with',
        header_from_confirm_alert_msg: ' header from ',
        please_select_schedule_item_error_message: 'Please select schedule item',
        not_allowed_on_this_postion_error_message: 'Not allowed on this postion',
        please_select_one_item_error_message: 'Please select one item',
        no_secondary_events_attached_to_delete_error_message: 'No secondary events attached to delete',
        prototype_drop_not_allowed_for_break_error_message: 'Prototype drop not allowed for break',
        added_secondary_events_success_message: 'Added secondary events',
        please_load_schedule_first_error_message: 'Please load schedule first.',
        variations_are_not_available_info_message: 'Variations are not available',
        process_completed_success_message: 'Process Completed',
        select_atleast_one_header_error_message: 'Select atleast one header',
        user_favourite_page_breadcrumb: 'Favourite',
        please_enter_template_name_to_save_template_error_message: 'Please enter template name to save template',
        rmn_label: 'RMN',
        please_enter_rmn_error_message: 'Please Enter RMN Number',
        expand_collapse_headers_label: 'Expand/Collapse Headers',
        pages_label: 'Pages',
        total_product_column: 'Total Product',
        update_synopsis: 'Update Synopsis',
        invalid_time_range: 'Invalid Time Range',
        schedule_automatic_break_tooltip: 'Create Automatic Breaks',
        select_icon_url_collection_header: 'Select Icon',
        planningdistributionformat_sub_menu: 'Planning Distribution Format',
        planningdistributionformat_page_breadcrumb: 'Planning Distribution Format',
        edit_planningdistributionformat_editpage_header: 'Edit Planning Distribution Format',
        create_planningdistributionformat_editpage_header: 'Create Planning Distribution Format',
        subtitle_label: 'Subtitle',
        subtitle_type_lable: 'Subtitle Type',
        please_select_segment_type_error_message: 'Please Select Segment Type',
        audio_track_label: 'Audio Track',
        track_number_label: 'Track Number',
        add_language_group_prototype_button_tooltip: 'Add Language Group',
        add_language_group_prototype_button_text: 'Add Language Group',
        please_enter_track_number_error_message: 'Please Enter Track Number',
        please_select_atleast_one_language_error_message: 'Please Select Atleast one Language',
        language_already_added_error_message: 'Language already added',
        track_number_already_added_error_message: 'Track number already added',
        please_select_atleast_one_rule_error_message: 'Please select atleast one rule',
        update_template_label: 'Update Template',
        media_category_type_sub_menu: 'Media Category Type',
        work_order_dashboard: 'WORK ORDER',
        todo_tile_label: 'To Do',
        in_progress_tile_label: 'In Progress',
        done_tile_label: 'Done',
        workflow_label: 'Workflow',
        notes_label: 'Notes',
        please_select_atleast_one_role_error_message: 'Please select atleast one role',
        workflow_dialog_header: 'Create Workflow',
        workorder_dialog_header: 'Create Work Order',
        roles_label: 'Roles',
        workflow_page_breadcrumb: 'Workflow',
        workflow_menu: 'Workflow',
        current_status_column: 'Current Status',
        created_date_column: 'Created Date',
        please_select_workflow_error_message: 'Please select workflow',
        media_column: 'Media',
        transmission_label: 'Transmission',
        transmission_column: 'Transmission',
        mamID_label: 'Mam ID',
        apply_distribution_button_text_planning: 'Distribute Planning',
        min_gap_label: 'Min Gap',
        apply_fix_gap_label: 'Apply Fix Gap',
        plan_till_label: 'Plan Till',
        max_placement_label: 'Max Placement',
        valid_days_label: 'Valid Days',
        retry_gap_label: 'Retry Gap',
        planning_distribution_label: 'Planning Distribution',
        please_enter_minimum_gap_error_message: 'Please Enter Minimum Gap',
        please_select_plan_till_date_error_message: 'Please Select Plan Till Date',
        please_enter_max_placement_error_message: 'Please Enter Max Placement',
        please_select_days_error_message: 'Please Select Days',
        please_enter_retry_gap_error_message: 'Please Enter Retry Gap',
        please_select_starting_role_error_message: 'Please select starting role',
        starting_role_label: 'Starting Role',
        plan_till_date_cannot_be_less_than_original_planned_item_error_message: 'Plan Till Date Cannot Be Less Than Original Planned Item',
        applying_planning_distribution_template_please_wait_planner_status_message: "Applying Planning Distribution Template, Please Wait...",
        planning_distribution_template_applied_success_planner_status_message: "Planning Distribution Template Applied Succesfully",
        note_for_parent_select_null_label: 'Note: for parent select null',
        payments_label: 'Payments',
        schedule_autofill_button_tooltip: "Auto Fill Booked Commercial",
        schedule_reset_all_header_to_nominal_start_time_button_tooltip: "Reset All Header Start Time To Planned Nominal Time",
        work_orders_label: 'WORK ORDERS',
        role_wise_work_orders_label: 'ROLE WISE WORK ORDERS',
        user_wise_work_orders_label: 'USER WISE WORK ORDERS',
        select_media_info_message: 'Select Media',
        history_label: 'HISTORY',
        booking_details_label: 'Booking Details',
        show_jobs_button_text: 'Show Jobs',
        please_check_label: 'Please check',
        categories_label: 'Categories',
        job_status_label: 'Job Status',
        invalid_start_date: 'Invalid Start date',
        invalid_end_date: 'Invalid End date',
        target_budget_should_be_greater_then_zero: 'Target Budget should be greater than Zero',
        target_spots_should_be_greater_then_zero: 'Target Spots should be greater than Zero',
        target_seconds_should_be_greater_then_zero: 'Target Seconds should be greater than negative value',
        please_select_date_format_error_message: 'Please Select Date Format',
        no_data_schedule_to_save_scheduling_reconciliation_error_message: 'No data found schedule',
        no_data_asrun_to_save_scheduling_reconciliation_error_message: 'No data found AsRun',
        reset_password_label: 'Reset Password',
        date_column_is_not_present_in_this_csv_file_label: 'Date column is not present in this csv file',
        time_column_is_not_present_in_this_csv_file_label: 'Time column is not present in this csv file',
        house_number_column_is_not_present_in_this_csv_file_label: 'HouseNumber column is not present in this csv file',
        user_favourite_add_button_title: 'Add Favourites',
        user_favourite_menu: 'Favourite',
        user_favorite_menu_dropdown_label: 'Menu',
        user_favorite_menu_text_label: 'Name',
        please_select_user_favourite_menu_error_message: 'Please Select Menu',
        please_select_user_favourite_name_error_message: 'Please Enter Name',
        vodtype_label: 'VOD Types',
        replace_existing_planning_label: 'Replace Existing Planning',
        region_column: 'Region',
        live_events_menu: 'Live Events',
        vodtype: 'VOD Type',
        version_sub_menu: 'Version',
        version_page_breadcrumb: 'Version',
        spot_can_not_be_negative_error_message: 'Spot can not be negative',
        rate_can_not_be_negative_error_message: 'Rate can not be negative',
        please_add_booking_first_booking_error_message: 'Please add booking first',
        transfer_from_and_transfer_to_are_same_date_error_message: 'Can not be transfer on same date',
        tournament_dialog_header: 'Create Tournament',
        live_match_dialog_header: 'Create Match',
        no_booking_data_error_message: 'No booking data found !',
        ott_assets_video: 'Video',
        ott_assets_poster: 'Poster',
        update_successfully_booking_success_toast_message: 'Booking update successfully',
        please_select_a_value_to_delete_booking_error_message: 'Please select a value to delete',
        ott_assets_video_url_error_message: 'Please Enter URL',
        LiveEventRound_sub_menu: 'Live Event Round',
        LiveEventType_sub_menu: 'Live Event Type',
        TournamentType_sub_menu: 'Tournament Type',
        commission_negative_value_campaign_error_message: 'Commission cannot be in negative',
        discount_negative_value_campaign_error_message: 'Discount cannot be in negative',
        invalid_spots_value_line_no_booking_error_message: 'Negative Spots Value In Line No',
        channels_not_updated_in_booking_error_message: 'Channel Is Not Updated In Line No',
        attach_media_label: 'Attach Media',
        media_episode_localization: 'Localization',
        media_episode_id_column: 'Media Episode ID',
        manage_content_template_sub_menu: 'Manage Content Template',
        fct_can_not_be_negative_error_message: 'FCT can not be negative',
        rate_10_sec_can_not_be_negative_error_message: 'Rate10Sec can not be negative',
        amount_can_not_be_negative_error_message: 'Amount can not be negative',
        booked_spots_scheduled_error_message: 'Some of the selected bookings are already used in schedule.',
        booking_line_no: 'Line Number',
        please_select_import_type_error_message: 'Please select Import Type',
        optimisation_sun_menu: 'Optimisation',
        optimisation_page_breadcrumb: 'Optimisation',
        booking_date_label: 'Booking Date',
        agency_name_column: 'Agency Name',
        assurance_type_column: 'Assurance Type',
        assured_value_column: 'Assured Value',
        please_select_booking_date_error_message: 'Please Select Booking Date',
        optimisation_label: 'Optimisation',
        please_show_atleast_one_item_error_message: 'Please show atleast one item !',
        please_enter_valid_percentage_error_message: 'Please Enter Valid Percentage',
        campaign_target_seconds_actual_seconds_booking_error: 'Target seconds cannot be less than total booked seconds',
        campaign_target_budget_actual_budget_booking_error: 'Target budget cannot be less than total booked budget',
        campaign_target_spots_actual_spots_booking_error: 'Target spots cannot be less than total booked spots',
        assured_value_is_greater_than_total_fct_error_message: 'Assured value is greater than total fct',
        assured_value_is_less_than_zero_error_message: 'Assured value is less than zero',
        booking_schedule_status: 'Booking Schedule Status',
        makegood_column: 'Make Good',
        booked_spots_unique_line_no_selection_error_message: 'Please select spots with same line no to update',
        booking_file_import_error_message: 'Unable to read data from file. Error - ',
        spot_status_column: 'Status',
        booking_transfer_from_date_greater_than_transfer_to_date_error_message: 'Transfer from date cannot be greater than transfer to date',
        spot_time_from_time_to_error_message: 'From time should be less than to time',
        material_type_label: 'Material Type',
        cancreatedeal_label: 'Can Create Deal',
        please_enter_material_type_error_message: 'Please Enter Material Type',
        please_select_media_category_error_message: 'Please Select Media Category',
        automation_type_label: 'Automation Type',
        download_button_text: 'Download',
        edit_black_out_date_button_tooltip: 'Edit Black Out Date',
        add_black_out_date_button_tooltip: 'Add Black Out Date',
        edit_black_out_date_dialog_header: 'Edit Black Out',
        add_black_out_date_dialog_header: 'Add Black Out',
        black_out_date_dialog_header: 'Black Out Date',
        please_add_atleast_one_black_out_date_error_message: 'Please add atleast one black out date',
        update_successfully_grid_data_success_toast_message: 'Update Successfully',
        black_out_channel_column: 'Black Out Channel',
        black_out_start_date_column: 'Black Out Start Date',
        black_out_end_date_column: 'Black Out End Date',
        black_out_date_should_be_within_publishing_dates_range_error_message: 'Blackout dates should be within publishing dates range',
        some_bookings_are_out_of_campaign_date_range_could_not_transfer_error_message: 'Some bookings are out of their campaing date range, Could not transfer',
        report_menu: 'Report',
        interstitial_history_sub_menu: 'Interstitial History',
        interstitial_history_report_dialog_header: 'Interstitial History Report',
        client_agency_booking_report_sub_menu: 'Client Agency Booking Report',
        primary_channel_column: 'Primary Channel',
        order_date_error_message: 'Order date should be less than Period From',
        please_select_tournament_type: 'Please select Tournament Type',
        please_enter_tournament_ID: 'Please enter Tournament ID',
        tournament_type_label: 'Tournament Type',
        tournament_ID_label: 'Tournament ID',
        as_run_label: 'AsRun',
        schedule_label: 'Schedule',
        update_booking_multiple_dialog_header: 'Update Booking',
        commercial_duration_lable: 'Duration',
        no_as_run_found_error_message: 'No AsRun Found',
        copy_from_and_copy_to_date_range_should_be_same: "(Copy From) selected days should be equal to (Copy To) selected days.",
        exclusive_label: 'Exclusive',
        exclusive_start_date_column: 'Exclusive Start Date',
        exclusive_end_date_column: 'Exclusive End Date',
        technical_details_prototype_sub_menu: 'Technical Details Prototypes',
        technical_details_prototype_page_breadcrumb: 'Technical Details Prototypes',
        audio_codec_page_breadcrumb: 'Audio Codec',
        video_codec_page_breadcrumb: 'Video Codec',
        delivery_method_page_breadcrumb: 'Delivery Method',
        video_aspect_ratio_page_breadcrumb: 'Video Aspect Ratio',
        video_type_page_breadcrumb: 'Video Type',
        audio_codec_sub_menu: 'Audio Codec',
        video_codec_sub_menu: 'Video Codec',
        delivery_method_sub_menu: 'Delivery Method',
        video_aspect_ratio_sub_menu: 'Video Aspect Ratio',
        video_type_sub_menu: 'Video Type',
        is_exclusive_column: 'Is Exclusive',
        ref_off_setting_label: 'Offset Setting',
        offset_time_minus_label: 'Offset Time Minus',
        offset_time_plus_label: 'Offset Time Plus',
        ignore_duration_lable: 'Igonre Duration',
        reference_no_label: 'Reference No',
        please_enter_reference_no_error_message: 'Please Enter Reference Number',
        create_technical_details_prototype_header_dialog: 'Create Technical Details Prototype',
        audio_codec_label: 'Audio Codec',
        video_codec_label: 'Video Codec',
        video_aspect_ratio_label: 'Video Aspect Ratio',
        delivery_method_label: 'Delivery Method',
        is_quality_checked_label: 'Is Quality Checked',
        enable_caption_label: 'Enable Caption',
        distributer_label: 'Distributer',
        please_select_audio_codec_error_message: 'Please Select Audio Codec',
        please_select_video_codec_error_message: 'Please Select Video Codec',
        please_select_delivery_method_error_message: 'Please Select Delivery Method',
        please_select_video_aspect_ratio_error_message: 'Please Select Video Aspect Ratio',
        please_select_video_type_error_message: 'Please Select Video Type',
        offsetsetting_dialog_header: 'Off Set Setting',
        please_enter_OffSet_Time_Minus_error_message: 'Please Enter OffSet Time Minus',
        please_enter_offSet_time_plus_error_message: 'Please Enter OffSet Time Plus',
        booking_schedule_status_sub_menu: 'Booking Schedule Status',
        edit_multiple_tooltip: 'Edit Multiple',
        select_commercial_error_message: 'Please Select Commercial',
        invalid_spotRate_error_message: 'Please Enter Valid Spot Rate',
        invalid_rate10Sec_error_message: 'Please Enter Valid Rate 10 Sec',
        please_select_atleast_one_value_to_update_error_message: 'Please select at least one value to update',
        rate_10_sec_column: 'Rate10Sec',
        missing_media_report_dialog_header: 'Missing Media Report',
        missing_media_report_sub_menu: 'Missing Media Report',
        schedule_date_label: 'Schedule Date',
        please_select_schedule_date_error_message: 'Please select schedule date',
        mam_assets_sub_menu: 'MamAsset',
        mamassets_page_breadcrumb: 'MamAssets',
        create_mamasset_dialog_header: 'Create MamAsset',
        please_enter_programeditstatus_error_message: 'Please Enter Program Edit Status',
        end_time_label: 'End Time',
        program_edit_status_label: 'Program Edit Status',
        tx_ready_label: 'TX Ready',
        subtitle_availble_label: 'Subtitle Available',
        censorship_report_available_label: 'Censorship Report Available',
        qcpass_label: 'QC Pass',
        please_enter_valid_end_time_message: 'Please Enter Valid End Time',
        please_enter_valid_actual_duration_message: 'Please Enter Valid Actual Duration',
        time_in_label: 'Time In',
        time_out_label: 'Time Out',
        commercial_limit_label: 'Commercial Limit',
        please_enter_valid_commercial_limit_error_message: 'Please Enter Valid Commercial Limit',
        contract_number_column: 'Contract Number',
        duration_should_be_greater_than_zero_error_message: 'Duration should be greater than zero',
        number_of_episode_can_be_greater_than_zero_error_message: `Number of Episode should be greater than zero`,
        staring_episode_can_be_greater_than_zero_error_message: `Starting Episode should be greater than zero`,
        segment_qc_page_header: ' Segment QC',
        segment_qc_button_tooltip: 'Segment QC',
        media_title_label: 'Media Title',
        qc_status_label: 'QC Status',
        frame_rate_label: 'Frame Rate',
        note_label: 'Note',
        please_select_qc_status_error_message: 'Please Select QC Status',
        please_enter_note_error_message: 'Please Enter Note',
        please_select_frame_rate_error_message: 'Please Select Frame Rate',
        please_enter_positive_value_in_pin_code_error_message: 'Please enter positive value in Pincode',
        please_enter_commission_error_message: 'Please Enter Commission',
        please_enter_discount_error_message: 'Please Enter Discount',
        please_configure_checklist_preference_error_message: 'Please configure checklist preference !!',
        preference_saved_successfully: 'Preference saved successfully !!',
        please_select_csv_file_error_message: 'Please select csv file !',
        cannot_delete_channel_campaign_already_has_booking_error_message: " Can not delete channel campaign already has booking",
        cannot_delete_commercial_campaign_already_has_booking_error_message: " Can not delete commercial campaign already has booking",
        ref_change_rate10sec_label: 'Change Rate 10 Sec',
        change_rate10sec_dialog_header: 'Change Rate 10 Sec',
        change_rate10sec_label: 'Change Rate 10 Sec',
        import_pdf_file: 'Import PDF file',
        please_select_atleast_one_media_category_type_error_message: 'Please select at least one media category type',
        please_select_episode_content_error_message: 'Please Select Epsiode Content',
        iso_code_label: 'ISO Code',
        google_api_code_label: 'Google API Code',
        create_language_dialog_header: 'Create Language',
        please_enter_iso_code_error_message: 'Please Enter ISO Code',
        please_enter_google_api_code_error_message: 'Please Enter Google API Code',
        click_here_to_check_ISO_code_error_message: 'Click here to check ISO Code',
        booking_update_successfully: 'Booking Updated Successfully',
        cast_Type_page_breadcrumb: 'Cast Type',
        cast_Type_sub_menu: 'Cast Type',
        spots_out_of_campaign_date_range_error_message: 'There are some spots out of campaign date range. Please check',
        import_pdf_booking_file: ' Import PDF Booking',
        import_booking_label: ' Import Booking',
        download_template_label: ' Download Template',
        export_saved_booking_label: ' Export Saved Booking',
        export_current_booking_label: ' Export Current Booking',
        planning_is_locked_on_some_days_in_this_date_range: 'Planning is locked on some days in this date range',
        payment_schedule_label: 'Payment Schedule',
        promotional_excerpts_label: 'Promotional Excerpts',
        governing_laws_label: 'Governing Laws',
        add_linear_page_header: 'Add Linear',
        add_linear_button_tooltip: 'Add Linear',
        amount_should_be_negative_error_message: 'Amount can not be negative',
        currency_column: "Currency",
        total_booked_spots_label: 'Booked Spots',
        total_booked_seconds_label: 'Booked Seconds',
        total_booked_amount_label: 'Booked Amount',
        booking_exceeded_target_budget: 'Exceeding Target Budget, Please refresh and try again',
        booking_exceeded_target_seconds: 'Exceeding Target Budget, Please refresh and try again',
        booking_exceeded_target_amount: 'Exceeding Target Budget, Please refresh and try again',
        broadcaster_id_label: 'Broadcaster ID',
        restriction_label: 'Restriction',
        create_restriction_dialog_header: 'Create Restriction',
        dynamic_rules_label: 'Dynamic Rules',
        censorship_details_label: 'Censorship Details',
        term_label: 'Terms',
        reason_label: 'Reason',
        hold_all_payment_label: 'Hold All Payment',
        generate_payment_button_text: 'Generate Payment',
        contract_payment_schedule_dialog_header: 'Contract Payment Schedule',
        generate_payment_schedule_dialog_header: 'Generate Payment Schedule',
        due_date_label: 'Due Date',
        interest_rate_label: 'Interest Rate',
        no_of_installment_label: 'No. Of Installment',
        recurring_label: 'Recurring',
        due_date_column: 'Due Date',
        due_amount_column: 'Due Amount',
        approve_status_column: 'Approve Status',
        amount_paid_column: 'Amount Paid',
        balance_amount_column: 'Balance Amount',
        please_select_recurring_error_message: 'Please Select Recurring',
        interest_rate_should_be_negative_error_message: 'Interest rate should be negative',
        no_of_installment_should_be_greater_than_zero_error_message: 'Number of installment should be greater than zero',
        generate_payment_label: 'Generate Payment',
        are_you_sure_you_want_to_generate_payment_schedule: 'Are you sure you want to Regenerate payment schedule',
        amount_paid_label: 'Amount Paid',
        approve_status_label: 'Approval Status',
        remark_column: 'Remark',
        edit_contract_title_button_text: ' Edit Contract Title',
        edit_black_out_date_button_text: ' Edit Black Out Date',
        delete_contract_title_button_text: ' Delete Contract Title',
        classification_label: 'Classification',
        cut_tc_in_label: 'Cut Tc In (hh:mm:ss:ff)',
        cut_tc_out_label: 'Cut Tc Out (hh:mm:ss:ff)',
        done_date_label: 'Done Date',
        result_label: 'Result',
        officer_label: 'Officer',
        cut_label: 'Cut',
        classification_column: 'Classification',
        cut_tc_in_column: 'Cut Tc In',
        cut_tc_out_column: 'Cut Tc Out',
        done_date_column: 'Done Date',
        reference_no_column: 'Reference No',
        result_column: 'Result',
        officer_column: 'Officer',
        cut_column: 'Cut',
        create_censorship_details_dialog_header: 'Create Censorship Details',
        please_enter_valid_value_cut_tcin_error_message: 'Please enter valid CutTcIn',
        can_not_delete_error_message: 'Can not delete dashboard right ',
        add_brand_button_tooltip: 'Add Brand',
        add_product_button_tooltip: 'Add Product',
        ratecard_page_breadcrumb: 'Rate Card',
        ratecard_sun_menu: 'Rate Card',
        create_ratecard_dialog_header: 'Create Rate Card',
        rate_10sec_label: 'Rate 10 Sec',
        please_enter_time_in_error_message: 'Please Enter Time In',
        please_enter_time_out_error_message: 'Please Enter Time Out',
        view_label: 'View',
        other_option_label: 'Other Option',
        prime_time_label: 'Prime Time',
        no_rights_scheduling_error_message: 'No Scheduling Rights',
        used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip: 'This is used to configure prefix suffix and incremental next number for asset id',
        sales_executive_label: 'Sales Executive',
        please_select_sales_executive_error_message: 'Please Select Sales Executive',
        material_content_page_breadcrumb: 'Material Content',
        material_content_sub_menu: 'Material Content',
        added_by_column: 'Added By',
        modified_by_column: 'Modified By',
        show_history_button_text: 'Show History',
        successfully_download_success_message: 'Successfully Download',
        top_5_sales_executive_label: 'Top 5 Sales Executive',
        add_job_url_button_tooltip: 'Add Job URL',
        checklist_preference_label: 'Checklist Preference',
        select_playback_url_header: 'Select Playback URL',
        duration_mismatch_error_message: 'Duration Mismatch',
        no_invoice_details_are_available_to_preview_bill_error_message: 'No invoice details are available to preview !',
        selected_commercial_label: 'Selected Commercial',
        no_data_schedule_to_download_error_message: 'No data schedule to download',
        from_date_can_not_be_less_than_campaign_start_date_error_message: 'From date can not be less than campaign start date',
        to_date_can_not_be_greater_than_campaign_end_date_error_message: 'To date can not be greater than campaign end date',
        cancel_invoice_waiting_message: 'Cancelling Invoice, Please Wait...',
        series_dialog_header: 'Series',
        manage_content_template_breadcrumb: 'Manage Content Template',
        ottmenu_sub_menu: 'Menu',
        ottmenu_page_breadcrumb: 'Menu',
        cardlist_pages_menu_title: 'Pages',
        select_pages_header_collection: 'Pages',
        ottmenu_title_editpageheader: 'Menu',
        sidefixmenu_rails_pages_tooltip: 'Pages',
        atleast_attach_one_ott_pages_rails_error_message: 'Atleast attach one page',
        menu_type_label: 'Menu Type',
        team_a_label: 'Team A ',
        team_b_label: 'Team B ',
        round_label: 'Round',
        bottom_menu_sub_menu: 'Bottom Menu',
        bottom_menu_page_breadcrumb: 'Bottom Menu',
        top_menu_sub_menu: 'Top Menu',
        top_menu_page_breadcrumb: 'Top Menu',
        please_select_asset_type_to_media_asset_error_message: 'Please select asset type to media asset',
        multi_versioning_tooltip_button: 'Multi Versioning',
        title_view_tooltip_button: 'Title View',
        close_title_view_tooltip_button: 'Close title view',
        sidefix_episode_tooltip: 'Episode',
        please_select_valid_start_date_error_message: 'Please Select Valid Start Date',
        please_select_valid_end_date_error_message: 'Please Select Valid End Date',
        please_select_exclusive_start_date_error_message: 'Please Select Exclusive Start Date',
        please_select_exclusive_end_date_error_message: 'Please Select Exclusive End Date',
        please_select_start_date_smaller_than_end_date_media_episode_publishing_error_message: 'Start date should be less than End date',
        amount_paid_can_not_be_negative_error_message: 'Amount paid can not be negative',
        print_button_tooltip: 'Print',
        print_telecast_certificate_in_pdf_button_tooltip: 'Print Telecast Certificate in PDF',
        print_telecast_certificate_in_excel_button_tooltip: 'Print Telecast Certificate in Excel',
        cancel_invoice_button_tooltip: 'Cancel Invoice',
        campagn_type_page_breadcrumb: 'Campaign Type',
        deal_type_page_breadcrumb: 'Deal Type',
        location_page_breadcrumb: 'Location',
        campaigntype_sub_menu: 'Campaign Type',
        dealtype_sub_menu: 'Deal Type',
        location_sub_menu: 'Location',
        ott_setting_sub_menu: 'Settings',
        ott_setting_page_breadcrumb: 'Settings',
        application_name_label: 'Application Name',
        logo_label: 'Logo',
        logo_position_label: 'Logo Position',
        pi_page_template_label: 'PiPage Template',
        show_bottom_menu_label: 'Show Bottom Menu',
        home_page_label: 'Home Page',
        show_hamburger_menu_label: 'Show Hamburger Menu',
        image_cdn_base_url_label: 'Image CDN Base URL',
        video_cdn_base_url_label: 'Video CDN Base URL',
        image_cdn_credentials_label: 'Image CDN CRED',
        video_cdn_credentials_label: 'Video CDN CRED',
        key_name_label: 'Key Name',
        key_value_label: 'Key Value',
        add_image_cdn_credentials_dialog_header: 'Add Image CDN CRED',
        add_video_cdn_credentials_dialog_header: 'Add Video CDN CRED',
        please_enter_key_name_error_messgae: 'Please Enter Key Name',
        please_enter_key_value_error_message: 'Please Enter Key Value',
        settings_label: 'Settings',
        please_enter_application_name_error_message: 'Please Enter Application Name',
        please_select_logo_error_message: 'Please Select Logo',
        please_select_logo_position_error_message: 'Please Select Logo Position',
        please_select_pi_page_template_error_message: 'Please Select PiPage Template',
        please_select_home_page_error_message: 'Please Select Home Page',
        please_select_theme_error_message: 'Please Select Theme',
        please_enter_image_cdn_base_url_error_message: 'Please Enter Image CDN Base URL',
        please_enter_video_cdn_base_url_error_message: 'Please Enter Video CDN Base URL',
        icon_label: 'Icon',
        please_select_icon_error_message: 'Please Select Icon',
        please_select_hamburger_menu_error_message: `Please select hamburger menu`,
        hamburger_menu_label: 'Hamburger Menu',
        select_package_header: 'Select Package',
        please_select_package_error_message: 'Please Select Package',
        vod_type_validation_error_message: `VOD type FREE can be apply with AVOD only`,
        server_health_sub_menu: 'Server Health',
        server_health_page_breadcrumb: 'Server Health',
        text_number_can_not_be_negative_error_message: 'Text number can not be negative',
        prelog_postlog_report_sub_menu: 'Pre/Post Log Report',
        pre_log_report_title_button: 'Prelog Report',
        post_log_report_title_button: 'Postlog Report',
        download_pre_log_report_tooltip_button: 'Download Prelog Report',
        download_post_log_report_tooltip_button: 'Download Postlog Report',
        credit_line_report_sub_menu: 'Credit Line Report',
        download_credit_line_report_tooltip_button: 'Download Credit Line Report',
        please_select_date_error_message: 'Please Select Date',
        logo_size_label: 'Logo Size',
        application_color_label: 'Application Color',
        purge_Spot_report_sub_menu: 'Purge Spot Report',
        download_purge_Spot_report_tooltip_button: 'Download Purge Spot Report',
        download_client_agency_booking_report_tooltip_button: 'Download Client Agency Booking Report',
        download_purge_spot_report_tooltip_button: 'Download Purge Spot Report',
        client_agency_booking_report_header: 'Client Agency Booking Report',
        pre_post_log_report_header: 'Pre/Post Log Report',
        credit_line_report_header: 'Credit Line Report',
        purge_spot_report_header: 'Purge Spot Report',
        interstitial_history_report_header: 'Interstitial History Report',
        download_interstitial_history_report_tooltip_button: 'Download Interstitial History Report',
        telecast_certificate_details_not_found_error_message: "Telecast Details Not Found",
        air_time_consumption_report_sub_menu: 'Air Time Consumption Report',
        download_air_time_consumption_report_tooltip_button: 'Download Air Time Consumption Report',
        air_time_consumption_report_header: 'Air Time Consumption Report',
        prototypecontent_sub_menu: 'Prototype Content',
        prototypecontent_page_breadcrumb: 'Prototype Content',
        create_prototypecontent_dialog_header: 'Create Prototype Content',
        please_enter_max_length_error_message: 'Please Enter Max Length',
        manual_prototype_id_label: 'Manual ID',
        prototype_id_label: 'ID',
        error_while_generating_prototype_number_error_message: 'Error While Generating Prototype Number',
        refresh_generated_segments_tooltip: 'Refresh Generated Segments',
        generated_segments_selected_segmentType_mismatch_error_message: 'Number of generated segments is not same as selected Segment Type',
        materialID_mediaID_mismatch_generated_segment_error_message: 'Material ID is not same as MediaID in generated segment ,Please refresh',
        please_select_subtitle_type_error_message: 'Please Select Subtitle Type',
        number_of_generated_segments_is_not_same_as_selected_segment_Type_error_message: 'Number of generated segments is not same as selected Segment Type',
        select_material_content_first_error_message: 'Select Material Content first',
        segment_already_exist_with_same_material_id_and_segment_type_error_message: 'Segment already exist with same Material ID and segment type',
        amazon_delivery_group_page_breadcrumb: 'Amazon Delivery Group',
        amazon_delivery_group_sub_menu: 'Amazon Delivery Group',
        amazon_delivery_group_edit_page_header: 'Amazon Delivery Group',
        tv_preview_label: 'TV Preview',
        mobile_preview_label: 'Mobile Preview',
        ditribute_by_percentage_label: 'Distribute By %',
        ditribute_by_percentage_error_message: 'Selected Commercial Distribution % Sum Should be 100',
        select_commercial_for_booking_error_message: 'Please select at least one commercial to book',
        distribute_selected_equal_lable: 'Distribute Selected Equally',
        feature_label: 'Features',
        select_secondary_event_error_message: 'Select secondary event first',
        delete_secondary_event_tooltip: 'Delete Selected Secondary Event',
        select_campaign_header_dialog: 'Select Campaign',
        select_pages_header_dialog: 'Select Pages',
        display_name_column: 'Display Name',
        spot_separation_label: 'Spot Separation',
        please_enter_valid_spot_separation_error_message: 'Please Enter Valid Spot Separation',
        ott_package_delivery_schedule_menu: 'Ott Package Delivery Schedule',
        service_menu: 'Service',
        ott_package_delivery_schedule_page_breadcrumb: 'Ott Package Delivery Schedule',
        schedule_delivery_button_tooltip: 'Schedule Delivery',
        schedule_delivery_button_text: 'Schedule Delivery',
        schedule_delivered_successfully_success_message: 'Schedule Delivered Successfully',
        schedule_auto_fill_interstitial_page_breadcrumb: 'Schedule Auto Fill Interstitial',
        schedule_auto_fill_interstitial_sub_menu: 'Schedule Auto Fill Interstitial',
        schedule_auto_fill_interstitial_edit_page_header: 'Schedule Auto Fill Interstitial',
        interstitial_label: 'Interstitial',
        filler_label: 'Fillers',
        music_label: 'Music',
        new_subscriber_in_week_label: 'New Subscribers In Week',
        no_pack_users_label: 'No Pack Users',
        users_subscription_expiring_in_current_week_label: 'Expiring User In Week',
        user_by_plan_label: 'User by Plan',
        media_asset_label: 'Media Asset',
        vod_label: 'VOD',
        dummy_page_breadcrumb: 'Dummy',
        dummy_sub_menu: 'Dummy',
        ref_attach_formats_label: 'Attach Format',
        format_attached_success_message: 'Format Attached Successfully',
        ref_delete_formats_label: 'Delete All Formats',
        formats_deleted_success_message: 'All Formats Delete Successfully',
        ad_tag_label: 'Ad Tag',
        insert_break_label: 'Insert Break',
        slot_duration_label: 'Slot Duration',
        please_enter_slot_duration_error: 'Please enter Slot Duration',
        actual_duration_slot_duration_format_error_message: 'Actual Duration can not be greater than Slot Duration',
        total_amount_is_smaller_than_amount_paid_error_message: 'Total amount is smaller than amount paid',
        is_dummy_label: 'IsDummy',
        downloading_log_report_label: 'Downloading Log Report ',
        bill_generate_label: 'Bill Generate',
        contract_run_report_button_tooltip: 'Contract Run Report',
        contract_run_report_label: 'Contract Run Report',
        total_run_column: 'Total Run',
        preview_button_tooltip: 'Preview',
        preview_image_title_label: 'Preview Image',
        poster_dimension_title_label: 'Poster Dimension',
        show_poster_dimension_title_label: 'Show Posters Dimension',
        mam_asset_report_sub_menu: 'Mam Asset Report',
        mam_asset_report_page_breadcrumb: 'Mam Asset Report',
        schedule_duration_column: 'Schedule Duration',
        mam_duration_column: 'Mam Duration',
        total_column: 'Total',
        schudule_column: 'Schedule',
        pending_colun: 'Pending',
        tomorrow_pending_booking_status_label: 'Tomorrow Pending Booking Status',
        pending_approval_sun_menu: 'Pending Approval',
        pending_approval_page_breadcrumb: 'Pending Approval',
        approve_button_text: 'Approve',
        reject_button_text: 'Reject',
        booking_status_updated_successfully: 'Booking Status Updated Successfully',
        total_segment_duration_lable: 'Total Segment Duration',
        other_label: 'Other',
        comment_label: 'Comment',
        please_enter_comment_error_message: 'Please Enter Comment',
        unpurged_label: 'UnPurged',
        please_add_deeplink_in_video_list_error_message: 'Please add deeplink in videos list',
        please_attach_atleast_one_video_error_message: 'Please attach atleast one video',
        please_attach_atleast_one_poster_error_message: 'Please attach atleast one poster',
        deeplink_url_label: 'Deeplink Url',
        rental_wholesale_price_label: 'Rental Wholesale Price',
        rental_retail_price_label: 'Rental Retail Price',
        actual_on_air_column: 'Actual On Air',
        nominal_on_air_column: 'Nominal On Air',
        parent_series_code_label: 'Parent series Code',
        series_code_label: 'Series Code',
        duration_only_label: 'Duration',
        reference_code_label: 'Reference Code',
        material_type_column: 'Material Type',
        break_configuration_page_breadcrumb: 'Break Configuration',
        break_configuration_sub_menu: 'Break Configuration',
        create_break_configuration_dialog_header: 'Create Break Configuration',
        please_select_atleast_one_allow_media_category_type_error_message: 'Please select at least one allow media category type',
        please_select_atleast_one_day_error_message: 'Please select atleast one day',
        please_enter_from_time_label: 'Please Enter From Time',
        please_enter_to_time_label: 'Please Enter To Time',
        fct_should_be_greater_than_zero_error_message: 'FCT should be greater than zero',
        from_time_should_be_less_than_To_time_error_message: 'From time should be less than to time',
        to_date_should_be_greater_than_from_date_error_message: 'To Date should be greater than From Date',
        fct_already_exists_error_message: 'FCT Already Exists in same Channel/Time Range/Days',
        fct_should_not_be_greater_than_campaign_target_seconds_error_message: 'FCT should not be greater than campaign target seconds',
        sort_by_label: 'Sort By',
        billing_summary_report_sub_menu: 'Billing Summary Report',
        no_scheduling_found_auto_fill_commercial_error: 'No scheduling found',
        no_break_item_found_auto_fill_commercial_error: 'No break items scheduled on this day',
        no_booked_spot_found_auto_fill_commercial_error: 'Booked Spots are Not Available',
        save_bill_button_text: 'Save Bill',
        save_bill_button_tooltip: 'Save Bill',
        preview_button_text: 'Preview',
        parent_series_lable: 'Parent Series',
        ott_setting_label: 'OTT SETTING',
        bottom_menu_label: 'Bottom Menu',
        series_only_label: 'Series',
        add_insert_break_label: 'Add Insert Break',
        playlist_lock_status_sub_menu: 'Playlist Lock Status',
        playlist_lock_page_breadcrumb: 'Playlist Lock Status',
        generate_pdf_message: 'Please Wait Generating Pdf...',
        generate_pdf_successfully_message: 'Generate Pdf Successfully',
        payment_received_label: 'Payment Received',
        payment_received_button_tooltip: 'Payment Received',
        payment_received_button_text: 'Payment Received',
        print_button_text: 'Print',
        to_txt_button_toolip: 'To TXT',
        to_txt_button_text: 'To TXT',
        pdf_button_text: 'PDF',
        excel_button_text: 'Excel',
        received_date_label: 'Received Date',
        payment_mode_label: 'Payment Mode',
        cheque_no_label: 'Cheque No.',
        payment_received_successfully: 'Payment Received Successfully',
        is_paymnet_received_column: 'Is Payment Received',
        payment_received_date_column: 'Payment Received Date',
        payment_mode_column: 'Payment Mode',
        cheque_no_column: 'Cheque No.',
        payment_already_received_error_message: 'Payment Already Received',
        please_select_received_date_error_message: 'Please Select Received Date',
        please_select_payment_mode_error_message: 'Please Select Payment Mode',
        please_enter_cheque_no_error_message: 'Please Enter Cheque No.',
        booked_commercial_label: 'Booked Commercial',
        booked_announcement_label: 'Booked Announcement',
        announcement_label: 'Announcements',
        export_commercial_break_playlist_button_tooltip: 'Export Commercial Break Playlist',
        credit_line_report_page_breadcrumb: 'Credit Line Report',
        brand_column: 'Brand',
        time_range_column: 'Time Range',
        schedule_spot_column: 'Schedule Spot',
        total_duration_column: 'Total Duration',
        booking_schedule_status_header: 'Booking Schedule Status',
        no_data_found_for_selected_filter_error_message: 'No Data Found For Selected Filters!',
        artist_label: 'Artist',
        please_enter_artist_error_message: 'Please Enter Artist',
        announcement_reconcile_sun_menu: 'Announcement Reconcile',
        announcement_reconcile_page_breadcrumb: 'Announcement Reconcile',
        edit_header_dialog: 'Edit',
        actual_on_air_label: 'ActualOnAir',
        reconciled_successfully_message: 'Reconciled Successfully',
        reconcile_label: 'Reconcile',
        please_enter_actualonair_error_message: 'Please Enter ActualOnAir',
        please_select_atleast_one_saleteam_error_message: 'Please select at least one sales team',
        export_announcement_tooltip: 'Export Announcement',
        export_jazzler_automation_playlist: 'Export Jazzler Commercial Break',
        point_of_contact_label: 'Point of Contact',
        please_enter_point_of_contact_error_message: 'Please Enter Point of Contact',
        please_enter_pin_code_error_message: 'Please Enter Pin Code',
        epg_menu: 'EPG',
        epg_channel_sub_menu: 'Channels',
        epg_channel_page_breadcrumb: 'Channels',
        create_channel_dialog_header: 'Create Channel',
        please_enter_image_url_error_message: 'Please Enter Image Url',
        faq_category_page_breadcrumb: 'Faq Category',
        faq_category_sub_menu: 'Faq Category',
        faq_page_breadcrumb: 'Faq',
        faq_sub_menu: 'Faq',
        please_select_faq_Category_error_message: 'Please Select Category',
        faq_category_label: 'Faq Category',
        question_label: 'Question',
        answer_label: 'Answer',
        please_enter_question_error_message: 'Please Enter Question',
        please_enter_answer_error_message: 'Please Enter Answer',
        select_a_category_column: 'Select a category',
        faqs_column: 'FAQs',
        faqs_dailog_header: 'FAQ',
        faqs_page_breadcrumb: 'FAQ',
        top_news_menu: 'Top News',
        top_news_page_breadcrumb: 'Top News',
        favorite_channel_sub_menu: 'Favorite Channel',
        favorite_channel_page_breadcrumb: 'Favorite Channel',
        create_favorite_channel_dialog_header: 'Create Favorite Channel',
        logo_column: 'Logo',
        please_select_atleast_one_channel_error_message: 'Please select atleast one channel',
        tutorial_videos_page_breadcrumb: 'Tutorial Videos',
        tutorial_videos_sub_menu: 'Tutorial Videos',
        summary_label: 'Summary',
        playback_url_label: 'Playback Url',
        create_tutorial_videos_dialog_header: 'Create Tutorial Videos',
        please_enter_summary_error_message: 'Please Enter Summary',
        please_enter_playback_url_error_message: 'Please Enter PlaybackUrl',
        create_top_news_dialog_header: 'Create Top News',
        source_id_label: 'Source Id',
        featured_image_url_label: 'Featured Image Url',
        image_caption_label: 'Image Caption',
        time_stamp_label: 'Time Stamp',
        pull_latest_top_news_button_tooltip: 'Pull Latest Top News',
        thumbnail_url_label: 'Thumbnail Url',
        please_enter_thumbnail_url_error_message: 'Please Enter Thumbnail Url',
        please_enter_source_id_error_message: 'Please Enter Source ID',
        please_enter_image_caption_error_message: 'Please Enter Image Caption',
        header_address_label: 'Header Address',
        footer_address_label: 'Footer Address',
        ok_label: 'OK',
        session_expired_please_login_again_label: 'Session Expired. Please login again',
        please_select_episodes_first: `Please select episodes first`,
        successfully_saved_updated_message: 'Successfully saved/updated !!!',
        please_select_all_platforms_message: "Please select all platforms to change the position",
        please_book_spots_within_campaign_date_message: 'Please book spots within campaign date range : ',
        invalid_file_format: `Invalid file format`,
        import_already_in_progress: 'Import Already in Progress. Process Aborted',
        invalid_template_file: 'Invalid template file !!',
        date_time_houseNumber_column_is_mandatory: 'Date, Time & HouseNumber Column is Mandatory In Import File',
        commercial_used_in_booking_cannot_change_duration_message: 'Commercial used in booking cannot change duration !!',
        please_enter_team: `Please enter Team`,
        please_select_round_error: 'Please select round',
        please_select_start_date_between_tournament_date_range_error: `Please select Start Date between tournament date range`,
        match_scheduled_successfully_message: 'Match scheduled successfully !!',
        some_spots_are_reconciled_bill_generated_selected_spots: `Some spots are Reconciled or Bill generated in selected spots`,
        media_master_report_sub_menu: 'Media Master Report',
        media_master_report_page_breadcrumb: 'Media Master Report',
        video_column: 'Video',
        product_column: 'Product',
        data_load_successfully_message: 'Data loaded successfully',
        please_enter_address_error_message: 'Please Enter Address',
        target_seconds_should_be_greater_then_zero_error_message: 'Target Seconds should be greater then zero',
        missing_seasone_detail_form_error_message: 'Missing Seasone Detail ',
        ott_menu: 'OTT',
        manage_content_menu: 'Manage Content',
        to_do_column: 'To Do',
        in_progress_column: 'In Progress',
        done_column: 'Done',
        work_order_button_tooltip: 'Work Order',
        show_spots_button_tooltip: 'Show Spots',
        show_spots_button_text: 'Show Spots',
        save_and_create_work_order_button_tooltip: 'Save and Create Work Orders',
        save_and_create_work_order_button_text: 'Save WO',
        planning_prime_time_sub_menu: 'Planning Prime Time',
        breaks_label: 'Breaks',
        add_line_button_tooltip: 'Add Line',
        add_line_button_text: 'Add Line',
        perday_label: 'Per Day',
        perweek_label: 'Per Week',
        booking_yearly_sub_menu: 'Yearly Booking ',
        booking_yearly_page_breadcrumb: 'Yearly Booking ',
        days_of_week_column: 'Days Of Week',
        edit_multiple_button_text: 'Edit Multiple',
        delete_multiple_button_tooltip: 'Delete Multiple',
        delete_multiple_button_text: 'Delete Multiple',
        announcements_label: 'Announcements',
        recorded_commercial_label: 'Recorded Commercial',
        read_outs_column: 'Read Outs',
        scripts_column: 'Scripts',
        booking_status_column: 'Booking Status',
        asset_id_column: 'Asset ID',
        delete_strand_label: 'Delete Strand',
        delete_strand_items_confirm_message: 'Delete Strand Items',
        press_yes_to_delete_all_the_strand_from_the_selected_slot_onwards_confirm_message: 'Press YES to delete all the strand from the selected slot onwards',
        show_media_label: 'Show Media',
        lock_unlock_label: 'Lock / Unlock',
        show_exhibitions_label: 'Show Exhibitions',
        expand_all_breaks_label: 'Expand All Breaks',
        mnimize_all_breaks_label: 'Minimize All Breaks',
        please_select_atleast_one_days_error_message: 'Please select atleast one day',
        session_time_out_label: 'Session Time Out',
        please_enter_session_time_out_error_message: 'Please Enter Session time Out',
        session_time_out_should_be_greater_than_one_hour_error_message: 'Session time out should be greater than one hour',
        non_reconciled_spot_column: 'Non-Reconciled Spots',
        time_in_should_be_less_than_time_out_error_message: 'Time In should be less than Time Out',
        promotional_screens_sub_menu: 'Promotional Screens',
        promotional_screens_page_breadcrumb: 'Promotional Screens',
        marketing_screens_sub_menu: 'Marketing Screens',
        marketing_screens_page_breadcrumb: 'Marketing Screens',
        frequency_type_label: 'Frequency Type',
        frequency_label: 'Frequency',
        enable_label: 'Enable',
        marketing_screens_editpageheader: 'Marketing Screens',
        promotional_screens_editpageheader: 'Promotional Screens',
        frequency_should_be_greater_than_zero_error_message: 'Frequency should be Greater Than Zero',
        verbiage_key_label: 'Verbiage',
        access_level_label: 'Access Level',
        ottcampaign_label: 'Ott Campaign',
        ott_campaign_dialog_header: 'Campaign',
        successfully_add_campaign_message: 'Successfully add campaign',
        rail_is_already_attach_in_selected_campaign_info_message: 'Rail is already attach in selected campaign',
        deattach_ott_campaign_dialog_header: 'DeAttach Campaign',
        deattach_successfully_message: 'Deattach successfully',
        confirm_deattach_label: 'Confirm Deattach',
        are_you_sure_do_you_want_to_deattach_campaign_label: 'Are you sure do you want to deattach campaign',
        ott_camapign_status_label: 'Camapign Status',
        expire_label: 'Expired',
        rails_dialog_header: 'Rails',
        please_select_provider_error_message: 'Please select Provider',
        are_you_sure_do_you_want_to_deattach_rail_label: 'Are you sure do you want to deattach rail',
        rail_is_already_attach_in_selected_page_info_message: 'Rail is already attach in selected Page',
        successfully_add_rails_message: 'Successfully add rails',
        provider_sub_menu: 'Provider',
        provider_page_breadcrumb: 'Provider',
        logourl_label: 'Logo URL',
        please_enter_logourl_error_message: 'Please Enter LogoURL',
        please_select_at_least_one_product_error_message: 'Please select atleast one Product',
        products_label: 'Products',
        active_ottcampaign_label: 'Active Campaign',
        total_active_subscriber_label: 'Total Active Subscribers',
        preview_menu: 'Preview',
        module_sub_menu: 'Module',
        module_page_breadcrumb: 'Module',
        path_label: 'Path',
        create_module_header_dialog: 'Create Module',
        select_product_header_dialog: 'Select Product',
        successfully_add_product_succes_message: 'Successfully add product',
        campaign_prefix_label: 'Campaign Prefix',
        please_enter_campaign_prefix_error_message: 'Please Enter Campaign Prefix',
        please_enter_valid_release_year_error_message: 'Please Enter Valid Release Year !',
        traffic_sub_menu: 'Traffic',
        adSales_sub_menu: 'Ad-Sales',
        curate_sub_menu: 'Curate',
        monetize_sub_menu: 'Monetize',
        sms_sub_menu: 'SMS',
        couponing_sub_menu: 'Couponing',
        print_pdf_button_tooltip: 'Print PDF',
        please_enter_code: 'Please enter Code',
        external_description_label: 'External Description',
        alternate_description_label: 'Alternate Description',
        please_select_access_level_error_message: 'Please Select Access Level',
        company_setting_label: 'Company Setting',
        invoice_label: 'Invoice',
        successfully_saved_message: 'Successfully Saved',
        export_edi_format_label: 'Export EDI Format',
        do_you_want_print_campaign_billing_lines_label: 'Do you want print campaign billing lines ?',
        cancel_invoice_label: 'Cancel Invoice',
        do_you_want_cancel_this_invoice_label: 'Do you want cancel this invoice ?',
        please_wait_cancel_invoice_message: 'Please wait cancel invoice...',
        please_select_menu_type_error_message: 'Please Select Menu Type',
        is_parent_menu_label: 'Is Parent Menu Item',
        submenu_label: 'Sub Menu',
        please_select_sub_menu_error_message: 'Please Select Sub Menu',
        warning_label: 'Warning',
        user_channel_warning_message: 'No channel rights attached to this user. Do you want to proceed ?',
        financial_year_page_breadcrumb: 'Financial Year',
        sales_target_page_breadcrumb: 'Sales Target',
        financial_year_dialog_header: 'Create Financial Year',
        sales_target_dialog_header: 'Create Sales Target',
        financial_year_sub_menu: 'Financial Year',
        sales_target_sub_menu: 'Sales Target',
        financial_year_label: 'Financial Year',
        quarter_label: 'Quarter',
        target_label: 'Target',
        please_select_financial_year_error_message: 'Please select Financial Year',
        please_enter_target_error_message: 'Please enter Target',
        please_select_quarter_error_message: 'Please select Quarter',
        please_update_target_details_error_message: 'Please update Target Details',
        please_update_target_wrong_distribution_error_message: 'Target should be equal to Distributed Targets',
        target_details_label: 'Target Details',
        sales_target_achived_label: 'Sales Executives Target / Target Achieved',
        total_target_label: 'Total Target :',
        distributed_target_label: 'Distributed Target :',
        please_enter_assetid_error_message: 'Please Enter Asset Id',
        end_time_should_be_greater_than_start_time_error_message: "End Time should be Greater than Start Time",
        please_select_vod_type_validation_error_message: 'Please Select VOD Type',
        sum_of_percentages_should_be_100_error_message: 'Sum of Percentages should be 100',
        campaign_actionbutton_option: 'Campaigns',
        upcoming_label: 'Upcoming',
        select_ott_assets_header_dialog: 'Select OTT Assets',
        ott_assets_label: 'Ott Asset',
        fast_segmentation_actionbutton_menutext: 'Fast Segmentation',
        is_negative_asrun_diff_label: 'Negative Asrun Diff',
        ott_enabled_label: 'Ott Enabled',
        create_provider_dialog_header: 'Create Provider',
        insert_dummy_label_planning: 'Insert Dummy',
        please_enter_duration: 'Please enter durations',
        sum_of_entered_duration_should_be_less_then_or_equal_to_media_duration: 'Sum of entered durations should be less then or equal to media duration',
        please_select_area_on_planner_to_insert_dummy_planning_performance_error_message: 'Please select area on planner to insert dummy.',
        insert_dummy_dialog_title: 'Insert Dummy',
        edit_dummy_actionbutton_menutext: 'Edit Dummy',
        upload_fail_error_message: 'Upload Failed',
        replace_actionbutton_menutext: 'Replace',
        media_not_valid_for_selected_channel_error_message: "Media Not Valid For Selected Channel",
        replacement_media_lost_error_message: 'Replacement Media Lost.',
        are_you_sure_you_want_to_logout: 'Are you sure you want to Log out ?',
        translate_dialog_header: 'Translate',
        text_area_label: 'Text Area',
        translate_button_name: 'Translate',
        missing_google_api_code_error_message: 'Missing Google API Code',
        please_enter_text_area_first_error_message: 'Please Enter Text Area First',
        please_translate_first_error_message: 'Please Translate First',
        confirm_saved_label: 'Confirm Saved',
        changes_you_made_may_not_be_saved_label: 'Changes you made may not be saved.',
        sign_in_label: 'Sign In',
        username_login_label: 'UserName',
        password_login_label: 'Password',
        please_enter_user_name_login_label: 'Please Enter User Name',
        enter_your_password_login_label: 'Enter Your Password',
        login_lable: 'Login',
        material_id_and_media_id_mismatch_please_re_generate_segments_first_error_message: 'Material ID and Media ID mismatch, Please re-generate segments first !',
        please_enter_material_id_error_message: 'Please Enter Material ID',
        poster_note_image_size_label: 'Note : Image size less than 1 MB',
        entry_already_exists_in_system_for_same_prefix_and_suffix_error_message: 'Entry already exists in system for same prefix.',
        loop_till_selected_end_date: 'Note: Check to Loop Till Selected End Date',
        please_select_material_content_error_message: 'Please Select Material Content',
        document_page_breadcrumb: 'Document',
        document_sub_menu: 'Document',
        document_type_label: 'Document Type',
        please_select_document_type_error_message: 'Please Select Document Type',
        please_enter_doc_error_message: 'Please Enter Doc',
        create_Document_dialog_header: 'Create Document',
        upload_documents_label: 'Upload Document',
        doc_label: 'Doc',
        please_upload_documents_error_message: 'Please Upload Documents',
        delete_selected_label: 'Delete Selected',
        delete_slot_and_push_forward_in_the_strand_label: 'Delete Slot and Push Forward in the Strand',
        delete_slot_and_pull_backward_in_the_strand_label: 'Delete Episode and Pull Backward in the Strand',
        delete_all_the_events_forward_in_the_strand_label: 'Delete All the Events Forward in the Strand',
        delete_episode_in_future_from_selected_slot_forward_label: 'Delete Episode in future from selected slot forward',
        to_enable_other_options_select_only_one_item_label: 'To enable other options select only one item.',
        selected_item_should_be_episode_planning_item_error_message: 'Recursive is for episode media category only.',
        selected_item_already_part_of_another_strand_error_message: 'Selected item is part of another strand',
        select_documents_header_dialog: 'Select Documents',
        revenue_share_label: 'Revenue Share',
        revenue_share_should_be_negative_error_message: 'Revenue Share should be negative',
        revenue_share_column: 'Revenue Share',
        screen_saver_page_breadcrumb: 'Screen Saver',
        screen_saver_sub_menu: 'Screen Saver',
        screen_saver_editpageheader: 'Screen Saver',
        old_value_label: 'Old Value',
        new_value_label: 'New Value',
        show_changes_label: 'Show Changes',
        planning_slot_sub_menu: 'Planning Slot',
        planning_slot_page_breadcrumb: 'Planning Slot',
        timerangefrom_should_be_greater_then_zero: 'TimeRangeFrom should be Greater than Zero',
        timerangefrom_should_be_less_then_timerangeto: 'TimeRangeFrom should Be Less than TimeRangeTo',
        timerangeTo_should_be_greater_then_zero: 'TimeRangeTo should be Greater than Zero',
        show_details_column: 'Show Details',
        show_all_label: 'Show All',
        isImportable_label: 'Importable',
        invalid_file_error_message: 'Invalid Selected File',
        enable_filter_label: 'Enable Filter',
        rails_label: 'Rails',
        promotional_page_page_breadcrumb: 'Promotional Page',
        promotional_page_sub_menu: 'Promotional Page',
        slug_label: 'Slug',
        thumb_img_label: 'Thumbnail Image',
        ban_img_label: 'Banner Image',
        app_img_label: 'Application Image',
        tags_label: 'Tags',
        show_buy_button_label: 'Show Buy Button',
        show_in_app_label: 'Show In App',
        please_enter_slug_error_message: "Please Enter Slug",
        promotional_page_editpageheader: "Promotional Page",
        comma_seprated_value_should_be_enter_label: "Comma Separated Value Should Be Enter",
        please_enter_next_number_error_message: 'Next Number should be greater than 0',
        please_enter_number_length_error_message: 'Number Length should be greater than 0',
        generate_button_tooltip: 'Download TC in Excel',
        generate_xml_message: 'Please Wait Generating Excel...',
        create_new_button_label: 'Create New',
        rating_label: 'Rating',
        please_enter_rating_error_message: 'Please Enter Rating between 1 - 10',
        sponsor_type_page_breadcrumb: 'Sponsor Type',
        sponsor_type_sub_menu: 'Sponsor Type',
        note_for_sponsor_type_select_null_label: 'Note: Rating should be between 1-10',
        please_enter_sponsor_type_error_message: 'Please Enter Sponsor Type',
        sponsor_type_label: 'Sponsor Type',
        please_enter_currency_error_message: 'Please Select Currency',
        default_currency_label: 'Default Currency',
        web_assets_sub_menu: 'Web Assets',
        web_assets_page_breadcrumb: 'Web Assets',
        web_assets_page_editpageheader: 'Web Assets',
        web_assets_label: 'Web Assets',
        logs_label: 'Logs',
        enable_audit_log_label: 'Enable Audit Log',
        please_select_web_assets_error_message: 'Please Select Web Assets',
        select_web_assets_dialog_header: 'Select Web Assets',
        select_media_dialog_header: 'Select Media',
        create_new_button_tooltip: 'Create New',
        create_new_button_text: 'Create New',
        select_rule_label: 'Select Filter',
        from_date_should_be_less_then_to_date_error_message: 'From Date Should Be Less Than To Date',
        show_repeat_pattern_detail_tooltip: 'Show Repeat Pattern Detail',
        please_select_repeat_pattern_error_message: 'Please Select Repeat Pattern First',
        account_name_label: 'Account Name',
        bank_name_label: 'Bank Name',
        account_number_label: 'Account Number',
        swift_code_label: 'Swift Code',
        iban_number_label: 'Iban Number',
        branch_label: 'Branch',
        bank_details_label: 'Bank Details',
        hero_banner_mobile_label: 'Hero Banner Mobile',
        hero_banner_tv_label: 'Hero Banner TV',
        square_label: 'Square',
        circle_label: 'Circle',
        landscape_label: 'Landscape',
        partner_ingestion_media_deal_right_confirmation_message: 'Are you sure you want to import media without deal rights ??',
        no_internet_connection_please_check_your_connection_and_try_again_label: 'No internet connection. Please check your connection and try again.',
        there_is_no_product_assigned_to_your_company_kindly_contact_team_BMS_label: 'There is no product assigned to your company Kindly contact team BMS',
        you_dont_have_any_module_access_please_contact_your_admin_label: `You don't have any module access Please contact your admin`,
        show_break_only_label: 'Show break only',
        locked_by_label: 'Locked By',
        auto_plot_interstitial_label: 'Auto Plot Interstitial',
        show_logs_label: 'Show Logs',
        default_label: 'Default',
        from_default_label: 'From Default',
        update_successfully_label: 'Update Successfully',
        show_provider_label: 'Show Provider',
        show_censor_rating_label: 'Show Censor Rating',
        show_episode_no_label: 'Show Episode No',
        pi_page_setting_sub_menu: 'Pi Page Setting',
        pi_page_setting_page_breadcrumb: 'Pi Page Setting',
        pi_label: 'PI SETTING',
        seo_label: 'SEO SETTING',
        meta_tag_label: 'Meta Tag',
        meta_robot_label: 'Meta Robot',
        meta_googlebot_label: 'Meta Googlebot',
        pi_page_setting_page_editpageheader: 'Pi Page Setting',
        show_description_label: 'Show Description',
        show_synopsis_label: 'Show Synopsis Label',
        show_production_year_label: 'Show Production Year',
        show_pg_rating_label: 'Show PG Rating',
        show_cast_and_crew_label: 'Show Cast and Crew',
        show_language_label: 'Show Language',
        show_genre_label: 'Show Genre',
        show_season_no_label: 'Show Season No.',
        meta_title_label: 'Meta Title',
        meta_description_label: 'Meta Description',
        select_Field_tooltip: 'Select Field',
        screenshot_label: 'Screenshot',
        theme_sub_menu: 'Theme',
        theme_page_breadcrumb: 'Theme',
        cta_details_label: 'CTA',
        general_details_label: 'General',
        tray_glow_details_label: 'Tray Glow',
        player_details_label: 'Player',
        theme_setting_dialog_header: 'Theme Setting',
        deal_rights_give_automatically_for_all_channels_label: 'Deal rights give automatically for all channels',
        add_channel_rights_button_tooltip: 'Add Channel Rights',
        you_are_not_authorized_to_edit_this_channel_error_message: 'You are not authorized to alter this channel',
        permalink_structure_label: 'Permalink Structure',
        plain_label: 'Plain',
        numeric_label: 'Numeric',
        post_name_label: 'Post Name',
        excrate_label: 'Exchange Rate',
        note_enter_exchange_rate_equal_to_usd_label: 'Note: Enter Exchange Rate Equals to USD',
        excrate_should_not_be_negative_error_message: 'Exchange Rate Should not be Negative',
        you_delete_this_deal_right_right_from_parent_series_only_media_episode_publishing_error_message: 'You delete this deal right right from parent series only...',
        you_delete_this_deal_right_right_from_series_only_media_episode_publishing_error_message: 'You delete this deal right right from series only...',
        agency_type_sub_menu: 'Agency Type',
        agency_type_label: 'Agency Type',
        you_are_not_authorized_to_edit_please_go_to_parent_series_error_message: 'You are not authorized to edit. Please go to parent series',
        you_are_not_authorized_to_edit_please_go_to_series_error_message: 'You are not authorized to edit. Please go to series',
        create_group_header_dialog: 'Create Group',
        episode_group_page_breadcrumb: 'Episode Group',
        delete_media_episode_button_tooltip: 'Delete Media Episode',
        rail_title_details_label: 'Rail Title',
        menu_details_label: 'Menu',
        segment_by_rc_label: 'Segment By RC',
        reference_code_column: 'Reference Code',
        two_epiosde_per_day_label: 'Two Episode/Day',
        open_theme_new_tab_label: 'Open Theme inNew Tab',
        refresh_theme_label: 'Refresh Theme',
        update_slot_duration_label: 'Update Slot Duration',
        duration_updated_success_message: 'Duration Updated Successfully',
        please_select_duration_error_message: 'Please Select Duration',
        restricted_genre_label: 'Restricted Genre',
        application_configuration_menu: 'Application Configuration',
        application_configuration_breadcrumb: 'Application Configuration',
        please_select_genre_error_message: 'Please Select Genre',
        font_size_should_be_greater_than_zero_error_message: 'Font Size should be greater than zero',
        data_already_exist_error_message: 'Data Already Exist',
        you_can_not_delete_in_edit_mode_error_message: 'You can not delete in edit mode',
        please_add_atleast_one_genre_value_error_message: 'Please add atleast one genre value',
        invalid_short_forms: 'Invalid Short Forms',
        invalid_tooltip: 'Invalid',
        save_draft_button_tooltip: 'Save Draft',
        save_draft_button_title: 'Save Draft',
        publish_button_text: 'Publish',
        program_group_is_mandatory_when_applying_recursive_on_movie_error_message: "Please select Program Group for Applying Recursive on movie/program",
        trigger_start_time_label: 'Trigger Start Time',
        trigger_end_time_label: 'Trigger End Time',
        please_select_all_data_while_importing_playlist: 'Please select all data while importing playlist',
        all_days_can_not_be_excluded_error_message: 'All days can not be excluded',
        dont_show_this_message_again: 'Don\'t show this message again',
        media_episode_group_grid_note: 'Note: While applying recursive the sequence will be same as sequence in above grid',
        please_wait_export_report_message: 'Please Wait Exporting Report...',
        segment_can_not_be_variation_error_message: "Segment can not be set as variation",
        can_edit_duration_of_segment_error_message: "Can edit duration of segment only",
        jobs_schedule_sub_menu: 'Jobs Schedule',
        jobs_schedule_page_breadcrumb: 'Jobs Schedule',
        command_label: 'Command',
        enable_error_log_lebel: 'Enable Error Log',
        hourly_button_tooltip: 'Hourly',
        hourly_button_title: 'Hourly',
        daily_button_tooltip: 'Daily',
        daily_button_title: 'Daily',
        weekly_button_tooltip: 'Weekly',
        weekly_button_title: 'Weekly',
        monthly_button_tooltip: 'Monthly',
        monthly_button_title: 'Monthly',
        yearly_button_tooltip: 'Yearly',
        yearly_button_title: 'Yearly',
        quick_schedule_lebel: 'Quick Schedule',
        create_jobs_schedule_dialog_header: 'Create Jobs Schedule',
        invalid_input_only_number_and_star_are_allow_error_message: 'Invalid input. Only numbers and * are allowed',
        please_enter_command_error_message: 'Please Enter Command',
        can_not_greater_than_error_message: 'can not greater than',
        variance_sub_menu: 'Variance',
        variance_label: 'Variance',
        tc_in_without_timecode_label: 'Tc In',
        tc_out_without_timecode_label: 'Tc Out',
        duration_without_timecode_label: 'Duration',
        minute_label: 'Minute (0-59)',
        hour_label: 'Hour (0-23)',
        day_Of_month_label: 'Day (1-31)',
        week_label: 'Week (1-7)',
        month_label: 'Month (1-12)',
        can_not_less_than_error_message: 'can not less than',
        archived_column: 'Archived',
        archived_label: 'Archived',
        please_upload_doc_error_message: 'Please Upload Doc',
        variation_type_column: 'Variation Type',
        create_variance_dialog_header: 'Create Variance',
        global_search_bar_placeholder : 'Find apps, content, and more. ( Ctrl + / )',
        note_variance_link_from_interstitial_edit_form_label: 'Note: You can link interstitial with this variance from interstitial edit form',
        duplicate_item_selected_error_message: 'Duplicate item selected',
        please_select_same_media_category_media_only_global_replacement_error : "Please select same media category media only",
        restore_schedule_draft_validation_error_message : "Cannot Restore Schedule Draft for Current Date and Days Before Current Date",
        schedule_draft_3_month_auto_delete_note : '*Note : Drafts older than 3 months will be automatically discarded. Save your draft in file if you want to keep them for longer time',
        delete_planning_log_message: 'Delete planning item selected',
        edited_using_form_log_message: 'Edited using form',
        add_new_planning_item_log_message: 'Add new planning item',
        edited_using_drag_drop_messgae: 'Edited using drag and drop',
        planning_logs_dialog_header: 'Planning Logs',
        slot_column: 'Slot',
        missing_slot_time_for_error_message: 'Missing Slot For - ',
        slot_label: 'Slot',
        edit_slot_dialog_header: 'Edit Slot',
        attached_media_label: ' MEDIA',
        time_bound_label : 'Time Bound',
        live_events_label: 'Live Events',
        tournament_label: 'Tournament',
        create_event_button_tooltip: 'Create Event',
        create_event_button_text: 'Create Event',  
        match_label: 'Match',
        venue_label: 'Venue',
        poster_url_label: 'Poster URL',
        please_select_tournament_error_message: 'Please Select Tournament',
        publish_match_header_dialog: 'Publish Match',
        un_publish_match_label: 'Un-Publish Match',
        publish_match_label: 'Publish Match',
        selected_days_label: 'Selected Days',
        copy_transfer_schedule_header_dialog: 'Copy / Transfer Schedule',
        created_date_label: 'Created date',
        countries_sub_menu: 'Countries',
        countries_page_breadcrumb: 'Countries',
        pincode_sub_menu: 'Pincode',
        pincode_page_breadcrumb: 'Pincode',
        states_sub_menu: 'States',
        states_page_breadcrumb: 'States',
        pincodes_label: 'Pincodes',
        states_label: 'States',
        countries_label: 'Countries',
        show_media_button_tooltip: 'Show Media',
        show_media_button_text: 'Show Media',
        create_genre_button_text: 'Create Genre',
        time_zone_page_breadcrumb: 'Time Zone',
        time_zone_sub_menu: 'Time Zone',
        is_negative_offset_default_label:'IsNegative Offset',
        offset_label:'Offset (hh:mm:ss)',
        please_enter_valid_start_time_message: 'Please enter valid Start Time',
        time_zone_label: 'Time Zone',
        date_time_column: 'Date Time',
        time_zone_column: 'Time Zone',
        insert_live_event_actionbutton_menutext: 'Insert Live Event',
        insert_live_event_actionbutton_menutext_tooltip: 'Insert Live Event',
        virtual_segmentation_actionbutton_menutext: 'Virtual Segmentation',
        virtual_segmentation_actionbutton_menutext_tooltip: 'Virtual Segmentation',
        edit_actionbutton_menutext_tooltip: 'Edit',
        select_all_Header_actionbutton_menutext_tooltip: 'Select All Header',
        delete_header_actionbutton_menutext_tooltip: 'Delete Header',
        insert_break_actionbutton_menutext_tooltip: 'Insert Break',
        program_replacement_actionbutton_menutext_tooltip: 'Program Replacement',
        expand_all_breaks_actionbutton_menutext_tooltip: 'Expand All Breaks',
        mnimize_all_breaks_actionbutton_menutext_tooltip: 'Minimize All Breaks',
        attach_format_reload_actionbutton_menutext_tooltip: 'Attach Format',
        fast_segmentation_actionbutton_menutext_tooltip: 'Fast Segmentation',
        remove_filter_actionbutton_menutext_tooltip: 'Remove Filter',
        filter_actionbutton_menutext_tooltip: 'Filter',
        revert_virtual_segmentation_actionbutton_menutext: 'Revert Virtual Segment',
        revert_virtual_segmentation_actionbutton_menutext_tooltip: 'Revert Virtual Segment',
        copy_actionbutton_menutext_tooltip: 'Copy',
        cut_actionbutton_menutext_tooltip: 'Cut',
        paste_actionbutton_menutext_tooltip: 'Paste',
        delete_actionbutton_menutext_tooltip: 'Delete',
        se_overview_actionbutton_menutext_tooltip: 'SE Overview',
        global_replacement_actionbutton_menutext_tooltip: 'Global Replacement',
        delete_secondary_events_actionbutton_menutext: 'Delete Secondary Events',
        delete_secondary_events_actionbutton_menutext_tooltip: 'Delete Secondary Events',
        internal_drag_live_event_error_message : 'Cannot change Live Event. Please Delete and Publish again !!',
        search_name_label: 'Search Name',
        imdb_details_label: 'IMDb Details',
        year_column: 'Year',
        imdb_id_column: 'IMDb ID',
        poster_column: 'Poster',
        imdb_label: 'IMDb',
        actor_label: 'Actor',
        rated_label: 'Rated',
        country_label: 'Country',
        director_label: 'Director',
        writer_label: 'Writer',
        awards_label: 'Awards',
        dvd_label: 'DVD',
        imdb_votes_label: 'IMDb Votes', 
        imdb_id_label: 'IMDb ID',
        production_label: 'Production',
        website_label: 'Website',
        box_office_label: 'Box Office',
        meta_score_label: 'Meta Score',
        release_label: 'Release',
        please_enter_search_name_error_message: 'Please Enter Search Name',
        rails_type_details_dialog_header: 'Rails Type Details',
        no_image_found_error_message: 'No image found',
        please_select_item_first_error_message: 'Please select item first !',
        update_imdb_label: 'Update IMDb',
        subscription_label : 'Subscription',
        revenue_label : 'Revenue',
        total_subscriber_label : 'Total Subscribers',
        new_subscriber_label: 'New Subscribers',
        total_deactive_subscriber_label : 'Deactive Subscribers',
        total_activation_label : 'Total Activations',
        expiring_users_label : 'Expiring Subscribers',
        revenue_by_month_label : 'Revenue By Month',
        revenue_by_platform_label : 'Revenue By Platform',
        revenue_by_payment_gateway_label : 'Revenue By Payment Gateway',
        revenue_by_currency_label : 'Revenue By Currency',
        customers_registration_count_graph : 'Subscribers Registration Count Month Wise',
        customers_registration_platform_wise : "Subscribers Registration Platform Wise",
        purchases_by_payment_gateway : "Purchases By Payment Gateway",
        subscriber_by_platform : 'Subscriber By Platform',
        maximum_devices_label : 'Maximum Devices',
        please_select_platform_type_error_msg : 'Please Select Platform',
        maximun_device_should_not_be_less_than_one : "Maximum Devices should not be less than 1",
        region_details_monetization_dashboard_label : 'PRODUCTS, COUPONS & SUBCRIBERS OF REGION',
        subscribers_by_plan_label: 'Subscribers by Plan',
        show_provider_icon_label: 'Show Provider Icon',
        subscriber_subscription_history : 'Subscription History',
        provider_label: 'Provider',
        auto_renew_subscription_label : 'Auto Renew Pack',
        subscriber_login_history : 'Login History',
        create_payment_provider_dialog_header: 'Create Payment Provider',
        currency_code_label: 'Currency Code',
        branding_name_label: 'Branding Name',
        branding_logo_label: 'Branding Logo',
        branding_color_label: 'Branding Color',
        message_label: 'Message',
        sand_box_key_id_label: 'Sandbox Key ID',
        sand_box_secret_key_label: 'Sandbox Secret Key',
        live_key_id_label: 'Live Key ID',
        live_secret_key_label: 'Live Secret Key',
        success_url_label: 'Success URL',
        failure_url_label: 'Failure URL',
        please_enter_live_key_id_error_message: 'Please Enter Live Key ID',
        please_enter_live_secret_key_error_message: 'Please Enter Live Secret Key',
        please_enter_key_id_error_message: 'Please Enter Key ID',
        please_enter_secret_key_error_message: 'Please Enter Secret Key',
        please_enter_currency_code_error_message: 'Please Enter Currency Code',
        please_enter_branding_name_error_message: 'Please Enter Branding Name',
        please_enter_branding_logo_error_message: 'Please Enter Branding Logo',
        please_enter_success_url_error_message: 'Please Enter Success URL',
        please_enter_failure_url_error_message: 'Please Enter Failure URL',
        locale_label: 'Locale',
        payment_gateway_url_label: 'Payment Gateway URL',
        please_enter_payment_gateway_url_error_message: 'Please Enter Payment Gateway URL',
        some_attached_rails_are_full_page_type_and_can_be_attached_independently_only_error_message: 'Some attached rails are full page type and can be attached independently only',
        session_terminated_label: 'Session Terminated',
        your_session_has_been_terminated_label: 'Your session has been terminated',
        next_publish_date_label: 'Next Publish Date',
        create_tournament_type_dialog_header: 'Create Tournament Type',
        create_live_event_type_dialog_header : 'Create Live Event Type',
        please_select_house_number_error_message: 'Please Select House Number',
        successfully_exported_success_message: 'Successfully Exported',
        please_enter_cast_and_crew_error_message : 'Enter Artist in Meta Data Cast and Crew',
        music_category_sub_menu: 'Music Category',
        music_category_page_breadcrumb: 'Music Category',
        create_music_category_dialog_header: 'Create Music Category',
        is_music_label: 'Is Music',
        select_music_category_dialog_header: 'Select Music Category',
        music_category_label: 'Music Category',
        available_music_dialog_header: 'Available Musics',
        please_select_music_category_error_message: 'Please Select Music Category',
        music_menu: 'Music',
        more_button_tooltip: 'More',
        more_button_text: 'More',
        text_color_label: 'Text Color',
        program_time_consumption_report_sub_menu: 'Program Time Consumption Report',
        program_time_consumption_report_page_breadcrumb: 'Program Time Consumption Report',
        music_report_sub_menu: 'Music Report',
        music_report_page_breadcrumb: 'Music Report',
        total_runs_column: 'Total Runs',
        total_run_time_column: 'Total Run Time',
        translate_synopsis_level:'Translate Synopsis',
        tx_mode_sub_menu: 'Tx Mode',
        tx_mode_page_breadcrumb: 'Tx Mode',
        series_library_label : 'Series Library',
        digital_sign_menu: 'Digital Sign',
        model_sub_menu: 'Model',
        model_page_breadcrumb: 'Model',
        dimensions_label: 'Dimensions',
        model_number_label: 'Model Number',
        resolution_label: 'Resolution',
        display_shape_label: 'Display Shape',
        brightness_label: 'Brightness',
        display_size_label: 'Display Size',
        mounting_type_label: 'Mounting Type',
        create_model_dialog_header: 'Create Model',
        display_shape_sub_menu: 'Display Shape',
        display_shape_page_breadcrumb: 'Display Shape',
        resolution_sub_menu: 'Resolution',
        resolution_page_breadcrumb: 'Resolution',
        please_select_brand_error_message: 'Please Select Brand',
        please_enter_screen_dimensions_error_message: 'Please Enter Screen Dimensions',
        please_enter_model_number_error_message: 'Please Enter Model Number',
        please_enter_mounting_type_error_message: 'Please Enter Mounting Type',
        please_select_module_resolution_error_message: 'Please Select Module Resolution',
        please_select_display_shape_error_message: 'Please Select Display Shape',
        please_enter_brightness_error_message: 'Please Enter Brightness',
        please_enter_display_size_error_message: 'Please Enter Display Size',
        digital_sign_brand_sub_menu: 'Brand',
        digital_sign_brand_page_breadcrumb: 'Digital Sign Brand',
        create_node_dialog_header : 'Create Node',
        parent_node_label : 'Parent Node',
        digital_sign_node_page_breadcrumb : 'Digital Sign Node',        
        node_sub_menu: 'Node',
        add_sign_board_button_tooltip : 'Add Sign board',
        digital_sign_board_page_breadcrumb: 'Digital Sign Board',
        digital_sign_board_sub_menu : 'Board',
        model_label : 'Model',
        node_label : 'Node',
        warranty_start_date_label : 'Warranty Start Date',
        warranty_End_date_label : 'Warranty End Date',
        sign_board_id_label : 'Sign Board ID',
        model_column : 'Model',        
        sign_board_id_column : 'Sign Board ID',
        warranty_date_column : 'Warranty Date',
        delete_sign_board_button_tooltip : 'Delete Sign Board',
        date_wise_sheet_label: 'Date Wise Sheet',
        tx_status_sub_menu: 'Tx Status',
        tx_status_page_breadcrumb: 'Tx Status',
        update_txstatus_actionbutton_menutext: 'Update TX Status',
        add_comment_actionbutton_menutext : 'Add Comments',
        update_comment_actionbutton_menutext: 'Update Comment',
        tx_status_label : "TX Status",
        digital_sign_model_page_breadcrumb: 'Digital Sign Model',
        please_select_resolution_error_message: 'Please Select Resolution',
		please_enter_dimensions_error_message: 'Please Enter Dimensions',
        digital_sign_board_label : 'Digital Sign Board',
        warranty_end_date_label: 'Warranty End Date',
        create_board_dialog_header: 'Create Board',
        please_select_model_error_message: 'Please Select Model',
        please_enter_sign_board_id_error_message : 'Please Enter Sign Board ID',
        create_resolution_dialog_header: 'Create Resolution',
        length_label: 'Length',
        please_enter_length_error_message: 'Length should be greater than 0',        
        please_width_length_error_message: 'Width should be greater than 0',
        confirm_delete_all_label: 'Confirm Delete All',
        are_you_sure_do_you_want_to_delete_all_label: 'Are you sure do you want to delete all',
        serial_number_label: 'Serial Number',
        please_enter_serial_number_error_message:'Please Enter Serial Number',
        aspect_ratio_label: 'Aspect Ratio',
        width_should_be_greater_than_zero_error_message: 'Width should be greater than zero',
        height_should_be_greater_than_zero_error_message: 'Height should be greater than zero',
        playlist_sub_menu: 'Playlist',
        playlist_page_breadcrumb: 'Playlist',
        nodes_lable: 'Nodes',
        digital_sign_overlay_label: 'Overlay',
        can_not_add_same_parent_node_error_message: `Can't add same parent node`,
        publish_selected_match_label : 'Publish Selected Match',
        choose_another_parent_node_error_message: 'choose another parent node',
        digital_sign_overlay_page_breadcrumb: 'Digital Sign Overlay',
        overlay_page_breadcrumb: 'Overlay',
        digital_sign_overlay_sub_menu:'Overlay',
        digital_sign_board_overlay_editpage_header: 'Board Overlay',
        shape_label:'Shape',
        position_label: 'Position',
        text_label:'Text',        
        font_weights_label: 'Font Weight',
        font_color_label : 'Font Color',
        please_enter_shape_error_message:'Please Enter Shape',
        font_family_label:'Font Family',
        overlay_type_label: 'Overlay Type',
        font_size_label:'Font Size',
        border_radius_label: 'Border Radius',
        opacity_label: 'Opacity',
        text_marquee_label: 'Text Marquee',
        opacity_note: 'Note: Opacity should be between 0 to 1',
        margin_lrtb_text_label: "Text Margin",        
        width_percentage_should_be_greater_than_zero_and_less_than_hundred_error_message: 'Width % should be greater than 0 and less than 100',
        height_percentage_should_be_greater_than_zero_and_less_than_hundred_error_message: 'Height % should be greater than 0 and less than 100',
		asset_bundle_sub_menu:'Asset Bundle',
        asset_bundle_page_breadcrumb:'Asset Bundle',        
        edit_asset_bundle_editpage_header: 'Edit Asset Bundle',        
        create_asset_bundle_editpage_header: 'Create Asset Bundle',
        add_ott_asset_button_tooltip: 'Add Asset',
        channel_number_label: 'Channel Number',
        create_tx_mode_dialog_header: 'Create Tx Mode',
        is_type_live_label: 'Type Live',
        sub_genre_label : 'Sub Genres',
        current_active_version_label: 'Current Active Version',
        disable_live_event_section_label : 'Disable Live Event Section',
        export_segmentation_tooltip_button: 'Export Segmentation',
        text_position_label: 'Text Position',
        text_opacity_label: 'Text Opacity',
        digital_sign_board_overlay_page_breadcrumb: 'Digital Sign Board Overlay',
        please_enter_valid_numbers_in_duration : 'Please enter valid numbers in duration',
        text_shape_label: 'Text Shape',
        layer_label: 'Layer',
        spotify_label: 'Spotify',
        add_blank_label: 'Add Blank',
        sync_reference_code_message: 'It will update linked episode reference codes using series code as prefix followed by episode number.',
        geners_label: 'Geners',
        sub_geners_label: 'Sub Geners',
        languages_label: 'Languages',
        downloading_please_wait_message: 'Downloading, Please Wait...',
        format_schedule_sub_menu: 'Format Schedule',
        format_schedule_page_breadcrumb: 'Format Schedule',
        create_schedule_format_dialog_header: 'Create Schedule Format',
        select_format_dialog_header: 'Select Format',
        please_select_format_error_message: 'Please Select Format',
        please_enter_valid_from_error_message: 'Please Enter Valid From',
        please_enter_valid_to_error_message: 'Please Enter Valid To',
        please_select_items_to_update_message: 'Please select items to update',
        update_successfully_message: 'Update Successfully',
        please_select_pg_rating_error_message: 'Please Select PG Rating',
        please_select_censor_rating_error_message: 'Please Select Censor Rating',
        please_select_languages_error_message: 'Please Select Languages',
        please_select_categories_error_message: 'Please Select Categories',
        please_select_genres_error_message: 'Please Select Genres',
        please_select_sub_genres_error_message: 'Please Select Sub Genres',
        please_enter_production_year_error_message: 'Please Enter Production Year',
        please_select_technical_prototype_error_message: 'Please Select Technical Prototype',
        please_enter_keywords_error_message: 'Please Enter Keywords',
        variation_label: 'Variation',
        execute_button_tooltip: 'Execute',
        execute_button_text: 'Execute',
        download_summary_tooltip: 'Download Summary',
        from_date_should_be_current_date_error_message: 'From Date should be Current date/Future date',
        next_number_can_not_be_negative_or_zero_error_meessage: 'Next Number can not be negative or zero',
        number_length_should_be_between_1_to_10_error_message: 'Number Length should be between 1 to 10',
        create_fast_playlist_dialog_header: 'Create Fast Playlist',
        digital_sign_screen_group_page_breadcrumb : 'Screen Group',
        digital_sign_screen_group_sub_menu : 'Screen Group',
        activate_screen_button_tooltip: 'Activate Screen',
        activate_button_text: 'Activate',
        activate_button_tooltip: 'Activate',
        activate_screen_dialog_header: 'Activate Screen',
        please_enter_screen_name_error_message: 'Please Enter Screen Name',
        please_enter_otp_error_message: 'Please Enter OTP',
        show_segmentation_actionbutton_menutext : 'Show Segmentations',
        show_segmentation_actionbutton_menutext_tooltip : 'Show Segmentations',
        screen_page_breadcrumb: 'Screens',
        create_screen_group_dialog_header: 'Create Screen Group',
        screen_sub_menu: 'Screens',
        attach_screen_group_dialog_header: 'Attach Screen Group',
		digital_sign_content_page_breadcrumb : 'Content',
        digital_sign_content_sub_menu: 'Content',
        create_content_dialog_header:'Create Content',        
        please_select_content_type_error_message: 'Please Select Content Type',
        please_select_url_error_message: 'Please Select URL',
        content_type_label: 'Content Type',
        duration_type_label: 'Duration',
        playlist_label: 'Playlist',
        screen_group_label: 'Screen Group',
        player_label: 'Player',
        orientation_label: 'Orientation',
        playing_now_label: 'Playing Now',
        restart_label: 'Restart',
        restart_app_label: 'Restart App',
        cpu_usage_label: 'CPU Usage',
        do_you_really_want_to_delete_question: "Do you really want to delete ?",
        percentage_label : 'Percentage',
        digital_sign_content_label : "Content",
        digital_sign_dashboard_sub_menu: 'Dashboard',
        digital_sign_dashboard_page_breadcrumb: 'Dashboard',
        new_screen_group_button_title: 'New Screen Group',
        new_playlist_button_title: 'New Playlist',
        new_content_button_title: 'New Content',
        new_screen_button_title: 'New Screen',
        add_screen_group_dialog_header: 'Add Screen Group',
        playlist_dialog_header: 'Playlist',
        same_screen_group_error_message: 'Can\'t add same screen group',
        add_more_label: 'Add more',
        thumbnail_column : "Thumbnail",
        overlays_column : "Overlays",
        email_address_label: 'Email Address',
        sign_up_label: 'Sign Up',
        company_name_label: 'Company Name',
        country_code_label: 'Country Code',
        industries_label: 'Industries',
        network_screen_size_label: 'Network Screen Size',
        how_did_you_found_us_label: 'How did you found us',
        please_enter_password_error_message: 'Please Enter Password',
        please_enter_company_name_error_message: 'Please Enter Company Name',
        please_select_country_code_error_message: 'Please Select Country Code',
        please_enter_phone_error_message: 'Please Enter Phone',
        please_select_industry_error_message: 'Please Select Industry',
        please_select_network_screen_size_error_message: 'Please Select Network Screen Size',
        please_select_how_did_you_found_us_error_message: 'Please Select How did you found us',
        sign_up_dialog_header: 'Sign Up',
        login_label: 'Log In',
        do_not_have_an_account_label: 'Don\'t have an account?',
        forgot_password_label: 'Forgot password?',
        screen_players_label: 'SCREEN/PLAYERS',
        username_label: 'Username',
        manage_underoverrun_schedule_menu_text: 'Fill Under/Overrun',
        instance_label: 'Instance',
        instances_sub_menu: 'Instances',
        instances_page_breadcrumb: 'Instances',
        forgot_your_password_label: 'Forgot your password?',
        enter_email_note: 'Enter your email and link we\'ll send new generated password on your email',
        remember_password_label: 'Remember Password ? ',
        reset_password_button_text: 'Reset Password',
        progress_column : 'Progress',
        select_interstitial_group_dialoag_header: 'Select Interstitial Group',
        interstitial_group_button_tooltip: 'Interstitial Group',
        auto_planning_tooltip : "Auto Planning",
        day_part_sub_menu: 'Day Part',
        day_part_page_breadcrumb: 'Day Part',
        create_day_part_dialog_header: 'Create Day Part',
        priority_label: 'Priority',
        please_enter_from_time_error_message: 'Please Enter From Time',
        please_enter_to_time_error_message: 'Please Enter To Time',
        please_enter_priority_error_message: 'Please Enter Valid Priority',
        please_select_valid_days_error_message: 'Please Select Valid Days',
        please_enter_youtube_link_error_message: 'Please Enter Youtube Link',
        link_label: 'Link',
        add_new_button_tooltip: 'Add New',
        attach_screens_column: 'Attach Screens',
        isAllChannel_label: 'AllChannel',
        day_part_label: 'Day Part',
        please_select_day_part_error_message: 'Please Select Day Part',
        please_enter_reference_code_error_message : "Please enter Reference Code",
        company_label: 'Company',
        please_enter_correct_phone_number_error_message: 'Please Enter Correct Phone Number',
        header_address_font_size_label: 'Header Address Font Size',
        spots_details_font_size_label: 'Spots Details Font Size',
        digital_sign_company_plans_page_breadcrumb: 'Company Plans',
        myrepeatpattern_labe:'Self Created',
        style_sheet_dialog_header: 'Style Sheet',
        digital_sign_company_plans_sub_menu: 'Company Plans',
        download_the_app_label: 'Download the App',        
        in_house_label: 'In House',
        digital_sign_admin_dashboard_menu: 'Admin Dashboard',
        digital_sign_admin_dashboard_page_breadcrumb: 'Admin Dashboard',
        last_five_user_login_label: 'Last 5 User login',
        create_content_button_tooltip: 'Create Content',
        create_genre_button_tooltip: 'Create Genre',
        create_sub_genre_button_tooltip: 'Create Sub Genre',
        digital_sign_plans_sub_menu: 'Plans',
        digital_sign_plans_page_breadcrumb: 'Plans',
        edit_image_url_dialog_header: 'Edit Image Url',
        attach_screen_dialog_header: 'Attach Screen',
        attachments_label: 'Attachments',
        file_attachment_button_tooltip: 'File Attachment',
        file_column: 'File',
        file_attachment_dialog_header: 'File Attachment',
        version_column: 'Version',
        targets_label: 'Targets',
        weightage_label: 'Weightage',
        weightage_column: 'Weightage',
        play_count_label: 'Play Count',
        play_count_column: 'Play Count',
        isplaybycount_label: 'Play By Count',
        isplaybycount_column: 'Play By Count',
        weightage_should_be_less_than_fifty_error_message: 'Weightage should be less than 50',
        please_enter_correct_weightage_error_message: 'Please Enter Correct Weightage',
        please_enter_correct_play_count_error_message: 'Please Enter Correct Play Count',
        play_count_should_be_less_than_fifty_error_message: 'Play Count should be less than 50',
        isfetchfromlibrary_label: 'Fetch From Library',
        create_instance_dialog_header: 'Create Instance',
        base_url_label: 'Base Url',
        db_name_label: 'DB Name',
        digisign_base_url_label: 'Digisign Base Url',
        socket_base_url_label: 'Socket Base Url',
        storage_destination_label: 'Storage Destination',
        cloud_name_label: 'Cloud Name',
        api_key_label: 'Api Key',
        api_secret_label: 'Api Secret',
        access_key_id_label: 'Access Key',
        secret_access_key_label: 'Secret Access key',
        bucket_name_label: 'Bucket Name',
        please_enter_base_url_error_message: 'Please Enter Base Url',
        please_enter_bdname_error_message: 'Please Enter BD Name',
        please_enter_digisign_base_url_error_message: 'Please Enter Digisign Base Url',
        please_enter_socket_base_url_error_message: 'Please Enter Socket Base Url',
        quality_label: 'Quality',
        quality_should_be_less_than_100_error_message: 'Quality should be less than 100',
        file_label: 'File',
        keep_org_label: 'Keep Original',
        cdn_url_label: 'Cdn Url',
        please_enter_cdn_url_error_message: 'Please Enter Cdn Url',
        please_cloud_name_error_message: 'Please Enter Cloud Name  ',
        please_api_key_error_message: 'Please Enter Api Key',
        please_api_secret_error_message: 'Please Enter Api Secret',
        please_access_keyid_error_message: 'Please Enter Access Key Id',
        please_secret_access_key_error_message: 'Please Enter Secret Access Key ',
        please_region_error_message: 'Please Enter Region ',
        please_bucket_name_error_message: 'Please Enter Bucket Name',
        column_label : 'Column',
        please_select_column_error_message : "Please Select Column",
        please_select_operator_error_message : "Please Select Operator",
        fill_gaps_label : "Fill Gaps",
        digital_sign_content_group_sub_menu: 'Content Group',
        digital_sign_content_group_page_breadcrumb: 'Content Group',
        content_group_editpage_header: 'Create Content Group',
        add_content_button_tooltip: 'Add Content',
        digital_sign_add_content_dialog_header: 'Add Content',
        please_attach_atleast_one_content_error_message: 'Please Attach Atleast One Content',
        digital_sign_content_group_label: 'Content Group',
        minimum_gap_label : "Minimum Gap (in hours)",
        segment_for_channel_rights_label: 'Some segments will be disabled if you do not have channel rights for the same.',
        already_have_an_account_label: 'Already have an account?',
        min_gap_error_message : "Minimun Gap cannot be negative",
        min_gap_invalid_error_message : "Please enter valid Minimun Gap ",
        set_as_default_label : 'Set As Default',
        minimum_gap_in_primary_event_label: 'Min Gap (in Days)',
        is_tx_status_label: 'Tx Status',
        tx_status_column: 'Tx Status',
        restricted_pg_rating_label: 'Restricted Pg Rating',
        marker_label: 'Marker',
        in_label: 'In',
        out_label: 'Out',
        marker_duration_label: 'Duration',
        time_code_markers : 'TimeCode Markers',
        duration_label_tooltip : '(hh:mm:ss:ff)',
        create_marker_button_tooltip : 'Create Marker',
        please_enter_valid_value_in_error_message : 'Please enter valid In',
        please_enter_valid_value_out_error_message : 'Please enter valid value, In should not be greater than Out',
        in_column: 'In',
        out_column: 'Out',
        Tcm: 'Tcm',        
        please_select_time_code_marker_error_message: 'Please Select Time Code Marker',
        ott_platform_sub_menu: 'Platform',
        ott_platform_page_breadcrumb: 'Platform',
        module_restrictions_label: 'Module Restrictions',
        action_label: 'Action',
        please_select_segment_header: 'Please Select Segment',
        restriction_actions_label : "Restriction Actions",
        no_permission_to_delete_header_info : 'You do not have permission to delete header.',
        please_contact_admin_message : "Please Contact Admin",
        privacy_policy_label: 'Privacy Policy',
        ott_assets_used_in_archive_confirmation_message: "This Asset is used in",
        ott_assets_archive_confirmation_message: "Do you still want to Archive it?",
        ingestion_report_sub_menu: 'Ingestion Report',
        ingestion_report_page_breadcrumb: 'Ingestion Report',
        cast_and_crew_column: 'Cast And Crew',
        censor_rating_column: 'Censor Rating',
        production_year_column: 'Production Year',
        activity_dropdown_label: 'Activity',
        asset_type_column: 'Asset Type',
        provider_column: 'Provider',
        access_level_column: 'Access Level',
        vod_type_column: 'VodType',
        potrait_image_url_column: 'Potrait Image Url',
        landscape_image_url_column: 'Landscape Image Url',
        playlist_import_buffer_label : 'Playlist Import Buffer',
        is_negative_playlist_import_buffer_label : "Is Negative Playlist Import Buffer",
        add_user_dialog_header : 'Add User',
        add_new_company_dialog_header:  'Add New Company',
        tenure_label: 'Tenure',
        screens_label: 'Screens',
        storage_label: 'Storage',
        plan_label: 'Plan',
        users_label: 'Users',
        please_enter_plan_name_error_message: 'Please Enter Plan Name',
        plan_tenure_should_be_greater_than_zero_error_message: 'Tenure should be greater than 0',
        plan_screens_should_be_greater_than_zero_error_message: 'Screens should be greater than 0',
        plan_storage_should_be_greater_than_zero_error_message: 'Storage should be greater than 0',
        please_add_atleast_one_user_error_message: 'Please Add Atleast One User',
        enter_your_pin_label: 'Enter Your PIN',
        please_select_company_error_message: 'Please Select Company',
        event_country_level: 'Event Country',
        please_enter_event_country_error_message: 'Please Enter Event Country',
        team_a_poster_url_label: 'Team A Poster Url',
        team_b_poster_url_label: 'Team B Poster Url',
        company_type_label: 'Company Type',
        tournament_group_dialog_header: 'Tournament Group',
        tournament_group_page_breadcrumb:'Tournament Group',
        tournament_group_sub_menu: 'Tournament Group',
        tournament_group_label:'Tournament Group',
        please_select_tournament_group:'Please Select Tournament Group',
        add_playlist_button_tooltip: 'Add Playlist',
        plans_label: 'Plans',
        change_password_button_tooltip: 'Change Password',
        change_password_dialog_header: 'Change Password',
        successfully_change_password_message: 'Successfully Changed Password',
        asrun_report_dialog_header: 'Asrun Report',
        playlist_name_column: 'Playlist Name',
        screen_name_column: 'Screen Name',
        serial_number_column: 'Serial Number',
        every_label : 'Every',
        is_auto_restart_enabled_label: 'Auto Restart',
        daily_reboot_label: 'Daily Reboot',
        restart_time_label: 'Restart At',
        screen_group_column: 'Screen Group',
        playlist_column: 'Playlist',
        epg_convertor_sub_menu : 'EPG Convertor',
        source_epg_file_label : 'Source EPG File',
        opta_file_label : 'OPTA File',
        image_url_file_label : 'Image Url File',
        source_epg_tab_label : 'Source EPG',
        opta_tab_label : 'OPTA',
        image_url_tab_label : 'Image Url',
        converted_epg_tab_label: 'Converted EPG',
        convert_button_label : "Convert",
        global_label: 'Global',
        media_template_sub_menu:'Media Template',
        media_template_page_breadcrumb: 'Media Template',
        media_template_dialog_header: 'Media Template',
        select_media_template_collection_header: 'Select Media Template',
        save_and_create_media_template_button_tooltip : 'Save and Create Media Template',
        select_media_template_button_tooltip : 'Select Media Template',
        save_as_media_template_button_text: 'Save as template',        
        startup_button_tooltip: 'Startup',
		startup_button_title: 'Startup',
        task_label : "Task",
        curl_label : "Curl / API",
        please_select_task_error_message : "Please Select Task",
        please_enter_command_error_message : "Please Enter Command",
        command_label : "Command",
        execute_shell_label : "Execute Shell",
        disable_lebel: "Disable",
        mudule_label: "Module",
        disable_lebel: "Disable",
        command_column: "Command",
        execute_shell_column : "Execute Shell",
        schedule_column  : "Schedule",
        enable_error_log_column : "Enable Error Log",
        disable_column : "Disable",
        success_count_column : "Success Count",
        last_activity_count_column : "Last Activity",
        next_schedule_time_column :"Next Schedule Time",
        please_add_atleast_one_publishing_rights_error_message: 'Please add atleast one publishing rights',
        genres_label: 'Genres',
        sub_genres_label: 'Sub Genres',
        avod_Configuration_sub_menu: "Avod Configuration",
        avod_Configuration_label: "Avod Configuration",
        ad_tags_column: "Ad Tags",
        avod_configuration_page_breadcrumb: "Avod Configuration",
        avod_feature_label: "Avod Feature",
        ad_tags_label: "Ad Tags",
        please_select_ad_tag_error_message: "Please Select ad Tag",
        linked_channels_label: "Linked Channels",
        gender_label: "Gender",
        please_select_gender_error_message: "Please Select Gender",
        parent_contract_label: "Parent Contract",
        select_contract_collection_header: "Contract Collection"
    }

};