import React, { forwardRef, memo, useImperativeHandle, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from '../../../framework/API/api'
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { CheckboxCell } from "../../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { utility } from "../../../framework/utility/utilityProvider";
import { useTranslation } from "../../../locale/useTranslation";
import ManageContentEditPanel from "./manageContentEditPanel";
import MultipleContentEditPanel from "./multipleContentEditPanel";
import MyStatusCell from "../../../components/MyStatusCell";
import { Gantt, GanttDayView, GanttMonthView, GanttWeekView } from "@progress/kendo-react-gantt";
import CustomPaginationDropDown from "../../../framework/forms/helpercomponents/CustomGridCells/CustomPaginationDropDown";
import { Loader } from "../../../framework/forms/helpercomponents/Loader";
import { toast } from "react-toastify";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MEDIACATEGORIES } from "../../../framework/constant/constant";
import { TimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { LookupCell } from "../../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { HrefCell } from "../../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { EnumCell } from "../../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import { IconCell } from "../../../framework/forms/helpercomponents/CustomGridCells/IconCell";
import { arrayEnumCell } from "../../../framework/forms/helpercomponents/CustomGridCells/PlatformCell";

const ManageContentCollection = (props, ref) => {

    const { view = 1 } = props;

    const lang = useTranslation();

    const SELECTED_FIELD = "selected";

    const [tileViewGridData, setTileViewGridData] = useState([]);
    const [timelineViewData, setTimelineViewData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState({
        skip: 0,
        take: 50
    });

    const [selectedItems, _setSelectedItems] = useState([]);
    const selectedItemsRef = useRef([]);
    const setSelectedItems = (data) => {
        props.setSelectedItemCount && props.setSelectedItemCount(data.length ?? 0);
        selectedItemsRef.current = data;
        _setSelectedItems(data);
    }
    const [gridColumns, setGridColumns] = useState([]);
    const [entityName, setEntityName] = useState(ENTITYNAME.MediaEpisode);
    const [mediaCategory, setMediaCategory] = useState({});
    const [searchText, setSearchText] = useState("");
    const [filterTemplate, setFilterTemplate] = useState([]);

    const taskModelFields = {
        id: '_id',
        start: 'start',
        end: 'end',
        title: 'Title',
        jobStatus: 'latestTranscodeStatus'
    };

    const columns = [
        { field: 'ImageUrl', title: '.', width: 67, cell: (p) => <ImageCell {...p} /> },
        { field: 'Title', title: `${lang.title_label}`, width: 150 },
        { field: 'start', title: `${lang.start_date_label}`, width: 100, cell: (props) => <DateOnlyCell {...props} /> },
        { field: 'end', title: `${lang.end_Date_label}`, width: 100, cell: (props) => <DateOnlyCell {...props} /> },
        { field: 'latestTranscodeStatus', title: `${lang.job_status_label}`, width: "125px", cell: (props) => <StatusCell {...props} /> },
    ];

    useImperativeHandle(ref, () => {
        return {
            handleLoad(mediaCategory){
                setMediaCategory(mediaCategory);
                setPage({ skip: 0, take: 50 });
                loadData({ per_page : 50, current_page : 1 }, mediaCategory, searchText, filterTemplate);
            },
            setSearchText(text){
                setSearchText(text);
                setPage({ skip: 0, take: 50 });
                loadData({ per_page : 50, current_page : 1 }, mediaCategory, text, filterTemplate);
            },
            setFilterTemplate(data){
                setFilterTemplate(data);
                setPage({ skip: 0, take: 50 });
                loadData({ per_page : 50, current_page : 1 }, mediaCategory, searchText, data);
            }
        }
    })

    const loadData = async (pagination={ per_page : 50, current_page : 1 }, mediaCategory={}, searchText=null, filterTemplate=[]) => {
        setSelectedItems([]); setIsLoading(true);
        let entityName = mediaCategory?.SID == MEDIACATEGORIES.Series ? ENTITYNAME.Series : mediaCategory?.SID == MEDIACATEGORIES.Season ? ENTITYNAME.Season : ENTITYNAME.MediaEpisode;      
        setEntityName(entityName);
        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let preferenceEntityName = mediaCategory?.SID == MEDIACATEGORIES.Trailer ? "program" : mediaCategory?.Description?.toLowerCase();
        let columns = await API.getFormData(preferenceEntityName, currentUserId);
        setGridColumns(columns);
        let json = {};
        if (mediaCategory?.isLong) {  
            json = await API.getEntitiesSearchWithMedia(
                entityName,
                { query: ["MediaCategory.SID", "=", mediaCategory?.SID], page: pagination?.current_page, perPage: pagination?.per_page, archive: false },
                columns,
                searchText,
                filterTemplate,
                mediaCategory?.SID
            );
        } else {
            json = await API.getEntitiesWithSearchForShortMedia(
                ENTITYNAME.MediaEpisode,
                { query: ["MediaCategory.SID", "=", mediaCategory?.SID], page: pagination?.current_page, perPage: pagination?.per_page, archive: false },
                columns,
                searchText,
                filterTemplate
            );
        }
        if (json.success) {
            setGridData(json.data);
            setTotalPages(json.pagination.total);
        } else {
            toast.error(json.message);
            setGridData([]);
        }
        setIsLoading(false);
    }

    // useEffect(() => {
    //     setTimelineViewData(gridData.map(item => {
    //         return {
    //             ...item,
    //             start: new Date(moment(item.PublishStartDate).utc().format("YYYY-MM-DD")),
    //             end: new Date(moment(item.PublishEndDate).utc().format("YYYY-MM-DD")),
    //         }
    //     }))
    //     setTileViewGridData(gridData.slice(0, props?.page?.take));
    // }, [props.data]);

    const onItemClick = (e, dataItem) => {
        //multiselect using ctrl key
        if (e.ctrlKey || e.metaKey) {
            if (selectedItems?.some(x => x?._id == dataItem?._id)) {
                setSelectedItems(selectedItems.filter(x => x._id != dataItem._id));
            } else {
                setSelectedItems([...selectedItems, dataItem]);
            }
        }
        //multiselect using shift key
        else if (e.shiftKey) {
            let items = [];
            let startIndex = gridData.findIndex(x => x._id == selectedItems[0]._id);
            let endIndex = gridData.findIndex(x => x._id == dataItem._id);
            if (startIndex > endIndex) {
                items = gridData.slice(endIndex, startIndex + 1);
            } else {
                items = gridData.slice(startIndex, endIndex + 1);
            }
            setSelectedItems(items);
        }
        else {
            if (selectedItems?.some(x => x?._id == dataItem?._id)) {
                setSelectedItems([]);
            } else {
                setSelectedItems([dataItem]);
            }
        }
    };

    const StatusCell = (props) => {
        return <MyStatusCell {...props}
            title={props.dataItem?.latestTranscodeStatus?.Description ?? 'N/A'}
            titleColor='#ffffff'
            tileBackColor={props.dataItem?.latestTranscodeStatus?.Description == 'To Do' ? "#f58d05" : props.dataItem?.latestTranscodeStatus?.Description == 'Done' ? "#1ca666" : '#5C469C'}
        />
    }

    const onPageChange = (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        setPage(e.page);
        loadData({ per_page: e.page.take, current_page: current_page }, mediaCategory, searchText, filterTemplate);
    }

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={gridColumns} />
    );

    const Mystatuscell = (props) => (
        <div>
            <MyStatusCell title={props.dataItem.CurrentStatus} tileBackColor="#72eda9" />
        </div>
    )

    const handleSelectedItem = (data) =>{
        if(Object.keys(data).length == 0){
            setSelectedItems([]);
            return;
        }
        let items = selectedItems.map(item => {
            if (item._id == data._id) {
                return data;
            }
            return item;
        })
        setSelectedItems(items);
    }

    return <div style={{ overflowX: "hidden", height: "80vh", marginRight: selectedItems.length > 0 ? "500px" : 0, transition: 'all 0.3s linear' }}>
        {isLoading ? <Loader /> :
            <>
                {view == 0
                    ?
                    // tile view
                    <div className="row" style={{ height: "95%", overflowY: "auto", overflowX: "hidden", display: "flex", alignItems: "start", justifyContent: "start", flexWrap: "wrap" }} onScroll={(e) => {
                        if (e.target.scrollTop + e.target.clientHeight + 50 > e.target.scrollHeight) {
                            setTileViewGridData([...tileViewGridData, ...gridData.slice(tileViewGridData.length, tileViewGridData.length + props?.page?.take)]);
                        } else if (e.target.scrollTop == 0) {
                            setTileViewGridData(gridData.slice(0, props?.page?.take));
                        }
                    }

                    }
                    >
                        {
                            tileViewGridData.map((item, index) => {
                                let genres = '';
                                if (item.Genres) {
                                    item.Genres.map(genre => {
                                        genres += genre.Description + ', ';
                                    })
                                    genres = genres.substring(0, genres.length - 2);
                                }
                                return <div className="col-lg-3 col-md-4 col-xs-6" style={{ flex: "25%" }} >
                                    <div style={{ cursor: "pointer", margin: "10px 40px", height: "220px", minWidth: "220px", maxWidth: "280px", backgroundColor: selectedItems.some(selectedItem => selectedItem?._id == item?._id) ? '#E8F6EF' : 'white', border: selectedItems.some(selectedItem => selectedItem?._id == item?._id) ? '1px solid black' : 'none' }} onClick={(e) => onItemClick(e, item)} className="card" key={index}
                                        title={item?.Title + "\n" +
                                            utility.convertMilisecondsToStringWithFrames(item?.Duration) + "\n\n" +
                                            item?.Description + "\n\n" +
                                            item?.MediaCategory?.Description + "\n" +
                                            genres
                                        }>
                                        <img onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = 'https://media.comicbook.com/files/img/default-movie.png';
                                        }} src={item?.ImageUrl} style={{ height: "150px", objectFit: "cover" }} className="card-img-top" alt={item?.Title} />
                                        <div style={{ display: "grid", alignContent: "space-around", height: "100%" }}>
                                            <p className="card-title" style={{ fontSize: "medium", fontWeight: "500" }}>{utility.subString(item?.Title, 15)}</p>
                                            <div className="card-subtitle mb-2 text-muted" style={{ fontSize: "small" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", padding: "0 13px" }}>
                                                    <div>{item?.MediaCategory?.Description}</div>
                                                    <div>{item?.AssetId}</div>

                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", padding: "0 13px" }}>
                                                    <div>{genres}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    :
                    view == 1
                        ?
                        // grid view
                        gridData.length == 0 ? <div style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}>{lang.no_record_found_error_message}</div> :
                        <Grid
                            style={{
                                height: "95%",
                            }}
                            rowHeight={50}
                            data={gridData.map(data => {
                                data[SELECTED_FIELD] = selectedItems.some(selectedItem => selectedItem?._id == data?._id);
                                return data;
                            })}
                            pageable={true}
                            skip={page?.skip ?? 0}
                            take={page?.take ?? 50}
                            total={totalPages ?? 0}
                            onPageChange={onPageChange}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                cell: false,
                                mode: {
                                    value: "multiple",
                                    label: "Multiple selection mode",
                                },
                            }}
                            resizable={true}
                            onSelectionChange={(event) => {
                                let rowIndex = event.startRowIndex;
                                let dataItem = event.dataItems[rowIndex];
                                onItemClick(event, dataItem);
                            }}
                            pager={(props) => <CustomPaginationDropDown {...props} />}

                        >
                            {gridColumns.map((column, index) => {
                                if (column.type === COLUMNSTYPE.date) {
                                    return (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            cell={DateOnlyCell}
                                            title={column.label}
                                            width={column.width ?? 200}
                                        />
                                    );
                                    // }else if (column.name === COLUMNSTYPE.time) {
                                } else if (column.type === COLUMNSTYPE.datetime) {
                                    return (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            cell={DateCell}
                                            title={column.label}
                                            width={column.width ?? 200}
                                        />
                                    );
                                } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                                    return (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            cell={TimeCell}
                                            title={column.label}
                                            width={column.width ?? 200}
                                        />
                                    );
                                } else if (column.name === "SID") {
                                    return;
                                } else if (column.type === COLUMNSTYPE.image) {
                                    return (
                                        <GridColumn
                                            sortable={false}
                                            key={index}
                                            field={column.name}
                                            cell={ImageCell}
                                            title={column.label}
                                            width={column.width ?? 100}
                                        />
                                    );
                                } else if (column.type === COLUMNSTYPE.select) {
                                    return (
                                        <GridColumn
                                            sortable={false}
                                            key={index}
                                            field={column.name}
                                            cell={MyDatalookupCell}
                                            title={column.label}
                                            width={column.width ?? 200}
                                        />
                                    );
                                }
                                else if (column.type === COLUMNSTYPE.checkbox) {
                                    return (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            cell={CheckboxCell}
                                            title={column.label}
                                            width={column.width ?? 80}
                                        />
                                    );
                                }
                                else if (column.type === COLUMNSTYPE.array) {
                                    return (
                                        <GridColumn
                                            sortable={false}
                                            key={index}
                                            field={column.name}
                                            cell={ArrayCell}
                                            title={column.label}
                                            width={column.width ?? 200}
                                            format={column.format ?? "Description"} //display item in object
                                        />
                                    );
                                }
                                else if (column.type === COLUMNSTYPE.href) {
                                    return (
                                        <GridColumn
                                            sortable={false}
                                            key={index}
                                            field={column.name}
                                            cell={HrefCell}
                                            title={column.label}
                                            width={column.width ?? 200}
                                            format={column.format}
                                        />
                                    );
                                }
                                else if (column.type === COLUMNSTYPE.enum) {
                                    return (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            cell={EnumCell}
                                            title={column.label}
                                            width={column.width ?? 200}
                                            format={column.format ?? "Description"} //display item in object
                                        />
                                    );
                                }
                                else if (column.type === COLUMNSTYPE.status) {
                                    return (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            cell={Mystatuscell}
                                            title={column.label}
                                            width={column.width ?? 200}
                                        />
                                    );
                                }
                                else if (column.type === COLUMNSTYPE.localdatetime) {
                                    return (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            cell={LocalDateTimeColumn}
                                            title={column.label}
                                            width={column.width ?? 200}
                                        />
                                    );
                                }
                                else if (column.type === COLUMNSTYPE.icon) {
                                    return (
                                        <GridColumn
                                            sortable={false}
                                            key={index}
                                            field={column.name}
                                            cell={IconCell}
                                            title={column.label}
                                            width={column.width ?? 80}
                                        />
                                    );
                                }
                                else if (column.type === COLUMNSTYPE.arrayenum) {
                                    return (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            cell={arrayEnumCell}
                                            title={column.label}
                                            width={column.width ?? 80}
                                        />
                                    );
                                }
                                else {
                                    return (
                                        column.type !== COLUMNSTYPE.hidden && (
                                            <GridColumn
                                                key={index}
                                                field={column.name}
                                                title={column.label}
                                                width={column.width ?? 200}
                                            />
                                        )
                                    );
                                }
                            })}


                        </Grid>
                        :
                        // timeline view
                        <Gantt
                            style={{ height: "95%" }}
                            taskData={timelineViewData}
                            taskModelFields={taskModelFields}
                            columns={columns}
                            onRowClick={(e) => { onItemClick(e, e.dataItem) }}
                        >
                            <GanttWeekView />
                            <GanttMonthView />
                        </Gantt>
                }

                {selectedItems.length > 1 && <MultipleContentEditPanel entityName={entityName} mediaCategory={mediaCategory} selectedItems={selectedItems} setSelectedItems={setSelectedItems} gridData={gridData}  setGridData={setGridData}/>}
                {selectedItems.length == 1 && <ManageContentEditPanel mediaCategory={mediaCategory} setGridData={setGridData} selectedItem={selectedItems[0]} entityName={entityName} gridData={gridData} transcodeStatus={selectedItems[0]?.latestTranscodeStatus?.Description ?? 'N/A'} setSelectedItem={handleSelectedItem} />}

            </>}
    </div >
}

export default memo(forwardRef(ManageContentCollection));