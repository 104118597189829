import { ACCESS_TYPE, AUTOMATION, BOOKINGPOSITIONS_ENUM, ENTITYNAME, FILEURL, FORMATVARIATIONTYPE, LOCALSTORAGE_KEY, MEDIACATEGORIES, MEDIATYPEENUM, PROGRAMSCHEDULEDRAFT_SAVETYPE, PROTOTYPEMEDIACATEGORYGROUP_ENUM, SCHEDULECONSTRAINTS, SCHEDULETYPE, SCHEDULE_CHILDREN_TYPE, SCHEDULE_DROP_DEST_TYPE, SEGMENTPOSITIONS_ENUM } from "../../../framework/constant/constant";
import { utility } from "../../../framework/utility/utilityProvider";
import * as API from '../../../framework/API/api';
import moment from "moment";
import { toast } from "react-toastify";

const IS_BREAK = 'IsBreak';
const UNDERRUN = 'UR';
const EXPAND_FIELD = "expanded";

export const SchedulingHelper = {};

SchedulingHelper.loadMediaCategoryTypeForSchedulePreference = async () => {
  var mediaCategoryType = await API.getDataLookup(ENTITYNAME.MediaCategoryType);
  return mediaCategoryType.data.map((object, index) => {
    return {
      Id: 4 + index,
      EventType: object.Description,
      FontSize: 13,
      ForegroundColor: '#000000',
      BackgroundColor: "#ffffff",
      ScheduleTypeID: 0,
      MediaCategoryTypeSID: object.SID
    }
  });
}

SchedulingHelper.getScheduleColorPreference = (dataItem) => {
  var localStorage = utility.getValue(LOCALSTORAGE_KEY.schedulepreference);
  var preferenceData = localStorage && localStorage.data && localStorage.data.length > 0 ? localStorage.data[0] : null;

  var preference = {};
  if (preferenceData && preferenceData.value && preferenceData.value.EventType) {
    if (dataItem?.Type ?? 0 != SCHEDULETYPE.ShortForm) {
      preference = preferenceData.value.EventType.find((obj) => obj.ScheduleTypeID == dataItem.Type);
    }
    else {
      preference = preferenceData.value.EventType.find((obj) => obj.ScheduleTypeID == dataItem?.Type && obj.MediaCategoryTypeSID == dataItem?.mediaEpisode?.MediaCategoryType?.SID);
    }
  }

  // if preference not found, just for handling 
  return preference == undefined
    ? { background: '#ffffff', color: '#000000', fontSize: '13px' }
    : { background: preference.BackgroundColor, color: preference.ForegroundColor, fontSize: `${preference.FontSize}px` };
}

SchedulingHelper.getCommercialOverRunUnderRunCounts = (finalData) => {
  const parentCount = finalData.length;
  var commercialCount = 0;
  var overRunUnderRunCount = 0;
  var playlistStartTime = 0;
  var playlistEndTime = 0;
  if (parentCount > 0) {
    playlistStartTime = finalData[0].SlotDateTime;
    if (parentCount > 1) {
      var childernLength = finalData[parentCount - 1].children.length;
      if (childernLength == 0) {
        playlistEndTime = finalData[parentCount - 1].SlotDateTime + finalData[parentCount - 1].Duration
      } else {
        var lastItem = finalData[parentCount - 1].children[childernLength - 1]
        if (lastItem.Type == SCHEDULETYPE.UnderRun || lastItem.Type == SCHEDULETYPE.OverRun) {
          lastItem = finalData[parentCount - 1].children[childernLength - 2];
        } else {
          playlistEndTime = lastItem.SlotDateTime + lastItem.Duration;
        }
      }

    } else {
      var childernLength = finalData[0].children.length;
      if (childernLength == 0) {
        playlistEndTime = finalData[0].SlotDateTime + finalData[0].Duration
      } else {
        var lastItem = finalData[0].children[childernLength - 1]
        if (lastItem.Type == SCHEDULETYPE.UnderRun || lastItem.Type == SCHEDULETYPE.OverRun) {
          lastItem = finalData[0].children[childernLength - 2];
        } else {
          playlistEndTime = lastItem.SlotDateTime + lastItem.Duration;
        }
      }
    }
  }
  finalData.map(data => {
    data.children.map(d => {
      if (d.Type == SCHEDULETYPE.UnderRun || d.Type == SCHEDULETYPE.OverRun) {
        overRunUnderRunCount += 1;
      }
      if (d?.mediaEpisode?.MediaCategory?.SID == MEDIACATEGORIES.Commercial) {
        commercialCount += 1;
      }
    })
  })

  return {
    parentCount: parentCount,
    commercialCount: commercialCount,
    overRunUnderRunCount: overRunUnderRunCount,
    playlistStartTime: playlistStartTime,
    playlistEndTime: playlistEndTime
  }
}

SchedulingHelper.loadBookingCommercialdata = async (ScheduleDate, ChannelSID) => {
  try {

    // console.log("Data pass   " + ScheduleDate + "---" + ChannelSID)
    if (!ChannelSID || !ScheduleDate) {
      //console.log("In valid Data pass  " + ScheduleDate + ChannelSID)
      return;
    }

    var payload = {
      ScheduleDate: ScheduleDate,
      channelSID: ChannelSID
    };
    //  console.log(payload);
    const json = await API.getScheduleBookedCommercial(payload)
    //  console.log(json);
    if (json.success) {
      return json.data;
    }
    else {
      // console.log(json);
    }
  } catch (error) {
    console.log("error", error);
    return null;
  }
}

SchedulingHelper.IsValidPrototype = (prototype, scheduleItem, schedulerData, setStatusMessage) => {

  // console.log("Checking Prototype Validity Details")
  console.log(prototype);
  console.log(scheduleItem);

  // check validation if any media episode is atached to it
  if (prototype.MediaEpisode && prototype.MediaEpisode._id) {
    if (prototype.MediaEpisode._id.toString() != scheduleItem.MediaEpisode_id) {
      setStatusMessage(prototype.Name + " is valid only for media: " + prototype.MediaEpisode.Title + " - " + prototype.MediaEpisode.AssetId);
      return false;
    }
  }

  // validate prototype restrictions
  if (prototype.SecondaryEventRestriction && prototype.SecondaryEventRestriction.length > 0) {

    console.log(prototype.SecondaryEventRestriction);
    console.log(scheduleItem.SlotDateTime);
    // filtering if is there any restriction combination
    var validDateRestrictions = prototype.SecondaryEventRestriction.filter(x =>
      x.Channel.SID == scheduleItem.Channel.SID // by channel
      && x.FromDate <= scheduleItem.SlotDateTime // by date
      && x.ToDate >= scheduleItem.SlotDateTime
      && x.ValidDays.some(d => d.ID == new Date(scheduleItem.SlotDateTime).getUTCDay())
    ); // by days

    console.log(validDateRestrictions);
    if (validDateRestrictions && validDateRestrictions.length > 0) {
      console.log("returning false")
      setStatusMessage(() => prototype.Name + " is restricted for this day")
      return false;
    }

  }




  // if Group Type is ALL Means Everything Allowed
  if (prototype.Group?.ID == PROTOTYPEMEDIACATEGORYGROUP_ENUM.All) {
    // console.log("returning true");
    return true;
  }
  // If Segment Group is allwoed but target is not segment
  if (prototype.Group?.ID == PROTOTYPEMEDIACATEGORYGROUP_ENUM.AllSegments) {
    console.log("coming in segments group")
    if (scheduleItem.Type != SCHEDULETYPE.Segment) {
      console.log("returning false")
      setStatusMessage(() => prototype.Name + " prototype is valid only for: " + prototype.Group.Description)
      return false;
    }

    if (prototype.SegmentPosition.ID != SEGMENTPOSITIONS_ENUM.All) {
      // means segment now we need to check segment position
      var maxBreakNumber = SchedulingHelper.GetMaxBreakNumber(scheduleItem.ParentProgramSchedule_id, schedulerData);
      console.log(maxBreakNumber);
      if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.First && scheduleItem.BreakNo != 1) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for first break only");
        return false;
      }
      else if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.Mid && (scheduleItem.BreakNo == 1 || scheduleItem.BreakNo == maxBreakNumber)) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for mid break only");
        return false;
      }
      else if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.Last && scheduleItem.BreakNo != maxBreakNumber) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for last break only");
        return false;
      }
      else if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.AllExceptFirst && scheduleItem.BreakNo == 1) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for all breaks except first");
        return false;
      }
      else if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.AllExceptLast && scheduleItem.BreakNo == maxBreakNumber) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for all breaks except last");
        return false;
      }
    }
  }
  else // else means, Filler Commercial and Any Category
  {
    console.log("coming in else")
    if (scheduleItem.mediaEpisode.MediaCategory.SID != prototype.Group.ID) {
      console.log("returning false in else")
      setStatusMessage(() => prototype.Name + " prototype is valid only for: " + prototype.Group.Description)
      return false;
    }
  }
  return true;
}

SchedulingHelper.IsValidPrototypeForFormat = (prototype, scheduleItem, maxBreakNumber, setStatusMessage) => {

  // if Group Type is ALL Means Everything Allowed
  if (prototype.Group?.ID == PROTOTYPEMEDIACATEGORYGROUP_ENUM.All) {
    // console.log("returning true");
    return true;
  }
  // If Segment Group is allwoed but target is not segment
  if (prototype.Group?.ID == PROTOTYPEMEDIACATEGORYGROUP_ENUM.AllSegments) {
    console.log("coming in segments group")
    if (scheduleItem.Type != SCHEDULETYPE.Segment) {
      console.log("returning false")
      setStatusMessage(() => prototype.Name + " prototype is valid only for: " + prototype.Group.Description)
      return false;
    }

    if (prototype.SegmentPosition.ID != SEGMENTPOSITIONS_ENUM.All) {
      // means segment now we need to check segment position
      if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.First && scheduleItem.BreakNo != 1) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for first break only");
        return false;
      }
      else if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.Mid && (scheduleItem.BreakNo == 1 || scheduleItem.BreakNo == maxBreakNumber)) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for mid break only");
        return false;
      }
      else if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.Last && scheduleItem.BreakNo != maxBreakNumber) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for last break only");
        return false;
      }
      else if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.AllExceptFirst && scheduleItem.BreakNo == 1) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for all breaks except first");
        return false;
      }
      else if (prototype.SegmentPosition.ID == SEGMENTPOSITIONS_ENUM.AllExceptLast && scheduleItem.BreakNo == maxBreakNumber) {
        console.log("returning false")
        setStatusMessage(() => prototype.Name + " prototype is valid for all breaks except last");
        return false;
      }
    }
  }
  else // else means, Filler Commercial and Any Category
  {
    console.log("coming in else")
    if (scheduleItem.mediaEpisode.MediaCategory.SID != prototype.Group.ID) {
      console.log("returning false in else")
      setStatusMessage(() => prototype.Name + " prototype is valid only for: " + prototype.Group.Description)
      return false;
    }
  }
  return true;
}

SchedulingHelper.getBreakChildInfo = (breakChild, allData) => {
  let breakChildParent = null;
  let level_0_index;
  let level_1_index;
  let level_2_index;

  //check third level
  allData.map((parent, index) => {
    let filterData = parent.children.filter(children => children._id == breakChild.ParentProgramSchedule_id);
    if (filterData.length > 0) {
      breakChildParent = filterData[0];
      level_0_index = index;
      level_1_index = parent.children.findIndex(child => child._id == breakChildParent._id);
    }
  });

  level_2_index = allData[level_0_index].children[level_1_index].children.findIndex(child => (breakChild._id ?? "") == "" ? child.local_id == breakChild.local_id : child._id == breakChild._id);

  return {
    level_0_index: level_0_index,
    level_1_index: level_1_index,
    level_2_index: level_2_index,
    breakChildParent: breakChildParent
  }
}

SchedulingHelper.getChildInfo = (child, allData) => {
  let level_0_index = null;
  let level_1_index = null;
  let level_2_index = null;
  let childParent = null;
  let breakChildParent = null;
  let isBreak = child[IS_BREAK] ?? false;
  let isHeader = !isBreak && (child.ParentProgramSchedule_id == null || child.ParentProgramSchedule_id == undefined);
  let isInsideBreak = false;
  let drop_des_type = child[IS_BREAK] ? SCHEDULE_DROP_DEST_TYPE.break : SCHEDULE_DROP_DEST_TYPE.header_children; //by default

  //means that child value is header at level 0
  //console.log(child)
  if (isHeader) {
    level_0_index = allData.findIndex(parent => parent._id == child._id);
    drop_des_type = SCHEDULE_DROP_DEST_TYPE.header;
    childParent = child // inthis case it is child is parent

    return {
      level_0_index: level_0_index,
      level_1_index: level_1_index,
      level_2_index: level_2_index,
      childParent: childParent,
      breakChildParent: breakChildParent,
      isBreak: isBreak,
      isHeader: isHeader,
      isInsideBreak: isInsideBreak,
      drop_des_type: drop_des_type
    }
  }

  //its a child and get the level 0  
  childParent = allData.filter((parent, index) => {
    if (parent._id == child.ParentProgramSchedule_id) {
      level_0_index = index;
      return parent;
    }
  });

  //it means it is insde child
  if (allData[level_0_index]?.children == undefined) {

    const { level_0_index, level_1_index, level_2_index, breakChildParent } = SchedulingHelper.getBreakChildInfo(child, allData)
    isInsideBreak = true;

    drop_des_type = SCHEDULE_DROP_DEST_TYPE.break_children

    return {
      level_0_index: level_0_index,
      level_1_index: level_1_index,
      level_2_index: level_2_index,
      childParent: childParent,
      breakChildParent: breakChildParent,
      isBreak: isBreak,
      isHeader: isHeader,
      isInsideBreak: isInsideBreak,
      drop_des_type: drop_des_type

    }
  }

  level_1_index = allData[level_0_index].children.findIndex(c => (child._id ?? "") == "" ? c.local_id == child.local_id : c._id == child._id);

  if (level_1_index < 0) {
    level_1_index = null;
  }

  //converting array to abject
  if (childParent.length > 0) {
    childParent = childParent[0];
  }

  return {
    level_0_index: level_0_index,
    level_1_index: level_1_index,
    level_2_index: level_2_index, //since it is brreak or header chilkdren here null
    childParent: childParent,
    breakChildParent: breakChildParent, //since it is brreak or header chilkdren here null
    isBreak: isBreak,
    isHeader: isHeader, //since it is brreak or header chilkdren here false
    isInsideBreak: isInsideBreak, //since it is brreak or header chilkdren here false
    drop_des_type: drop_des_type
  }
}

SchedulingHelper.getBreakItem = (isParent, dataItem, mediaEpisode, duration, selectedChannel) => {
  return {
    BreakNo: dataItem.BreakNo,
    MediaEpisode_id: mediaEpisode._id,
    mediaEpisode: mediaEpisode,
    ParentProgramSchedule_id: isParent ? dataItem._id : dataItem.ParentProgramSchedule_id,
    ScheduleDate: dataItem.ScheduleDate,
    SlotDateTime: dataItem.SlotDateTime + dataItem.Duration,
    Channel: selectedChannel,
    Duration: duration,
    TcIn: 0,
    TcOut: duration,
    Type: 0,
    MediaId: '-',
    SID: 0,
    [IS_BREAK]: true,
    children: [],
    Info: UNDERRUN + '-' + utility.convertMilisecondsToStringWithFrames(duration)
  };
}

SchedulingHelper.getParent = (parent_id, scheduleData) => {
  var parentData = scheduleData.filter(data => data._id == parent_id);
  return parentData[0];
}

SchedulingHelper.getFooter = (parent, rippledChildren, nextParent, selectedChannel) => {

  // last item of current footer
  var lastItem = {};
  var lastItemIsParent = false;

  if (rippledChildren.length > 0) {
    lastItem = rippledChildren[rippledChildren.length - 1];
  }
  else {
    lastItem = parent;
    lastItemIsParent = true;
  }

  // if parent, we cannot consider duration t match, if not parent, we will consider
  if (nextParent.SlotDateTime != (lastItemIsParent ? (lastItem?.SlotDateTime) : (lastItem?.SlotDateTime + lastItem?.Duration))) {
    var lastItemSlotEndDateTime = lastItem.SlotDateTime + (lastItemIsParent ? 0 : lastItem.Duration);
    var footerDuration = nextParent.SlotDateTime > lastItemSlotEndDateTime ? nextParent.SlotDateTime - lastItemSlotEndDateTime : lastItemSlotEndDateTime - nextParent.SlotDateTime;
    const footerItem = {
      _id: parent._id + "-footer", // giving a unique ID for selection
      BreakNo: lastItem.BreakNo,
      MediaEpisode_id: '',
      ParentProgramSchedule_id: lastItemIsParent ? lastItem._id : lastItem.ParentProgramSchedule_id,
      ScheduleDate: lastItemSlotEndDateTime,
      SlotDateTime: lastItemSlotEndDateTime,
      Channel: selectedChannel,
      Duration: footerDuration,
      mediaEpisode: { Title: lastItemSlotEndDateTime > nextParent.SlotDateTime ? 'Over Run' : 'Under Run' },
      TcIn: 0,
      TcOut: 0,
      Type: lastItemSlotEndDateTime > nextParent.SlotDateTime ? SCHEDULETYPE.OverRun : SCHEDULETYPE.UnderRun
    };

    return footerItem;
  }
  return null;
}

SchedulingHelper.getNextParent = (parent, parentData) => {
  var orderedParent = parentData.sort((a, b) => a.SlotDateTime - b.SlotDateTime);
  var parentIndex = orderedParent.indexOf(parent);

  if (parentIndex < orderedParent.length - 1) {
    return orderedParent[parentIndex + 1];
  }
  return null;
}

SchedulingHelper.validBookingDrop = (srcItem, destItem) => {
  // src item is dragItem whic is dragged
  // destItem is dataitem where it is dropped

  // destItem || dataItem
  let startTime = moment(destItem.SlotDateTime).utc().format("HH:mm:ss");
  let endTime = moment(destItem.SlotDateTime + destItem.TcOut).utc().format("HH:mm:ss");
  // src item || dragItem
  let dragStartTime = moment(srcItem.TimeRangeFrom).utc().format("HH:mm:ss");
  let dragEndTime = moment(srcItem.TimeRangeTo).utc().format("HH:mm:ss");

  if (startTime >= dragStartTime && endTime <= dragEndTime) {
    return true;
  } else {
    return false;
  }

}

SchedulingHelper.setPlanningPublishDataInLocalStorage = (data) => {
  utility.deleteSessionStorageItem(LOCALSTORAGE_KEY.planningPublishData);
  utility.setSessionValue(LOCALSTORAGE_KEY.planningPublishData, data);
}

SchedulingHelper.lockSchedule = async (selectedDate, selectedChannel, user) => {

  // user
  var currentDateTime = new Date();

  //check if any entry exists
  var scheduleEditStatusRes = await API.getData(ENTITYNAME.ScheduleEditStatus, { query: [['ScheduleDate', '=', selectedDate], ['Channel.SID', '=', selectedChannel.SID]] });

  if (scheduleEditStatusRes.data.length == 0) {
    //create entry for existing user if schedule is not locked
    var newEntryDataItem = {
      User: user,
      ScheduleDate: selectedDate,
      Channel: selectedChannel,
      EntryDateTime: currentDateTime.getTime(),
      Archive: false
    }
    //setAllowModification(true);

    var addRes = await API.saveData(ENTITYNAME.ScheduleEditStatus, newEntryDataItem);
    console.log(addRes);
    //setStatusMessage(`Locked by ${addRes.data.User.name}`);
    return { allowModification: true, message: `Locked by ${addRes.data.User.name}` };
  } else {
    console.log('here already locked');
    //setStatusMessage(`Locked by ${scheduleEditStatusRes.data[0].User.name}`);
    if (user._id == scheduleEditStatusRes.data[0].User._id) {
      //setAllowModification(true);
      return { allowModification: true, message: `Locked by ${scheduleEditStatusRes.data[0].User.name}` };
    }
    //setAllowModification(false);
    return { allowModification: false, message: `Locked by ${scheduleEditStatusRes.data[0].User.name}` };
  }
}


SchedulingHelper.downloadschedule = async (payloadref, autmationSID, parentSlotDateTime, doSftp = false, draftChannel) => {

  if (!payloadref) {
    toast.error("Missing Payload", {
      position: toast.POSITION.TOP_RIGHT
    });

  }

  var payload = {
    param:
    {
      query:
      {
        startDate: parentSlotDateTime > 0 ? parentSlotDateTime : payloadref.ScheduleDate,
        endDate: payloadref.ScheduleEndDate,
        channelSID: payloadref.channelSID,
        automationSID: autmationSID
      },
      doSftp: doSftp
    }
  }

  var res = await API.exportSchedule(payload)
  if (res.success) {
    // Export Schedule save as draft
    let draftPayload = {
      ScheduleDate: parentSlotDateTime > 0 ? parentSlotDateTime : payloadref?.ScheduleDate,
      ChannelSID: payloadref?.channelSID,
      AccessType: ACCESS_TYPE.PUBLIC,
      FileName: `Export-Schedule-Draft-${draftChannel?.FullChannelName}-${utility.convertMilisecondsToDateString(parentSlotDateTime > 0 ? parentSlotDateTime : payloadref?.ScheduleDate)}`,
      SaveType: PROGRAMSCHEDULEDRAFT_SAVETYPE.DATABASE,
      Remarks: "Export Schedule Draft"
    }
    API.programScheduleDraft(draftPayload);
    if (res.headersHavingFooter && res.headersHavingFooter.length > 0) {
      toast.info(res.headersHavingFooter.length + " header have under/over runs.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    let url = FILEURL.BASEURL + 'downloadReport/' + res.data;

    toast.success(res.message, {
      position: toast.POSITION.TOP_RIGHT
    });

    setTimeout(() => {
      window.open(url, "_blank");
    }, 2000);
  }
  else {
    toast.error(res.message, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}


SchedulingHelper.getMediaEpisodeValidSegment = async (selectedFormat, mediaEpisode) => {

  var segmentEntity = null;

  var res = await API.getData(ENTITYNAME.MediaEpisodeSegment, { query: ["MediaEpisodeSID", "=", mediaEpisode.SID] });
  if (res.success) {
    // ALL
    var segments = res.data;

    // Filter According to Segment Count (Selected Format)
    segments = segments.filter(x => x.SegmentType.SegmentCount == selectedFormat.SegmentType.SegmentCount);

    segmentEntity = segments.length > 0 ? segments[0] : segmentEntity;

  }

  return segmentEntity;
}

SchedulingHelper.getBreakFooter = (parent, childrens, selectedChannel) => {
  var parentDuration = parent.Duration;
  var childrenDuration = 0;
  var childrensLength = childrens.length;

  childrens.forEach(BreakChild => {
    childrenDuration += BreakChild.Duration;
  });
  console.log(childrenDuration);

  if (parentDuration != childrenDuration) {
    const footerItem = {
      _id: parent._id + "-footer", // giving a unique ID for selection
      BreakNo: parent.BreakNo,
      MediaEpisode_id: '',
      ParentProgramSchedule_id: parent._id ?? null,
      ScheduleDate: childrensLength > 0 ? childrens.at(-1).SlotDateTime : parent.SlotDateTime,
      SlotDateTime: childrensLength > 0 ? (childrens.at(-1).SlotDateTime + childrens.at(-1).Duration) : parent.SlotDateTime,
      Channel: selectedChannel,
      Duration: parentDuration < childrenDuration ? childrenDuration - parentDuration : parentDuration - childrenDuration,
      TcIn: 0,
      TcOut: 0,
      Type: parentDuration < childrenDuration ? SCHEDULETYPE.OverRun : SCHEDULETYPE.UnderRun,
      Info: ''
    };

    return footerItem;
  } else {
    return null;
  }

}


SchedulingHelper.GetParsedText = (scheduleItem, dynamicRule, schedulerDataRef) => {
  // if rule is empty return blank
  if (!dynamicRule.SecondaryEventDynamicRule) return "";

  // formula is like x-Title
  var splittedVal = dynamicRule.SecondaryEventDynamicRule.Formula.split("-");

  // order of item to pick,  if x means droppped item parent
  var findParentIndex = 0;

  // if it contains + then we need to use that index from dropped element
  if (splittedVal[0].includes("+") && splittedVal[0].split("+")[1]) {
    findParentIndex = parseInt(splittedVal[0].split("+")[1]);
  }

  console.log("getDynamicFormulaValue");

  var dynamicValue = SchedulingHelper.getDynamicFormulaValue(scheduleItem, findParentIndex, splittedVal[1], schedulerDataRef, dynamicRule);


  return dynamicValue

}

SchedulingHelper.PreparePrototypeData = (prototype) => {

  let final = {
    ...prototype,
    Channel: { _id: prototype.Channel?._id, SID: prototype.Channel?.SID, FullChannelName: prototype.Channel?.FullChannelName },
    ProtoTypeSource: { _id: prototype.ProtoTypeSource?._id, SID: prototype.ProtoTypeSource?.SID, Name: prototype.ProtoTypeSource?.Name, Layer: prototype.ProtoTypeSource?.Layer }
  };
  delete final.SecondaryEventDynamicRules;
  delete final.SecondaryEventRestriction;
  delete final.modBy;
  delete final.modOn;

  return final;

}

SchedulingHelper.getDynamicFormulaValue = (scheduleItem, indexFromScheduleItemParent, property, schedulerDataRef, dynamicRule) => {

  var parent = SchedulingHelper.getParent(scheduleItem.ParentProgramSchedule_id, schedulerDataRef);

  var orderedParent = schedulerDataRef.sort((a, b) => a.SlotDateTime - b.SlotDateTime);
  var parentIndex = orderedParent.indexOf(parent);

  var findindex = parentIndex + indexFromScheduleItemParent;

  if (findindex < orderedParent.length) {
    var resultParent = orderedParent[findindex];

    const finalvalue = resultParent && resultParent.mediaEpisode ? getNestedProperty(resultParent.mediaEpisode, property) : "";

    console.log("finalvalue");
    console.log(finalvalue);


    return finalvalue && finalvalue.toString().length > 0 ? ((dynamicRule.Prefix??"") + finalvalue) : "";
  }

  return "";
}

function getNestedProperty(obj, property) {
  return property.split('.').reduce((acc, part) => acc && acc[part], obj);
}

SchedulingHelper.getPayload = (selectedScheduleDate, selectedChannelSID, offsetTime) => {

  const finalScheduleDate = selectedScheduleDate + offsetTime;

  console.log(finalScheduleDate);

  return {
    BookingDate: selectedScheduleDate,
    ScheduleDate: finalScheduleDate,
    ScheduleEndDate: utility.addTimeToDateInMilliseconds(finalScheduleDate, 23, 59, 59, 999),
    channelSID: selectedChannelSID
  }
}

SchedulingHelper.IsValidBookingDrop = (destinationDataItem, draggedData, offsetTime) => {


  let isValidBookingDrop = true
  let baseStartTime = destinationDataItem.SlotDateTime + destinationDataItem.Duration;

  for (let index = 0; index < draggedData.length; index++) {

    const bookedSpot = draggedData[index];

    const validBookingStartTime = bookedSpot.BookingDate + bookedSpot.TimeRangeFrom;
    const validBookingEndTime = bookedSpot.BookingDate + bookedSpot.TimeRangeTo;

    //CASE FOR 6:00-6:00 SCHEDULE DAY BOOKING  23:00 - 04:00
    if (bookedSpot.TimeRangeFrom > bookedSpot.TimeRangeTo) {
      var date = new Date(validBookingEndTime);
      var updatedValidBookingEndTime = date.setDate(date.getDate() + 1);

      if (baseStartTime < validBookingStartTime || baseStartTime > updatedValidBookingEndTime) {
        isValidBookingDrop = false;
        break;
      }

    }  //CASE FOR 6:00-6:00 SCHEDULE DAY BOOKING SAME DAY  01:00 - 04:00
    else if (offsetTime > bookedSpot.TimeRangeFrom) {
      var startDate = new Date(validBookingStartTime);
      var endDate = new Date(validBookingEndTime)
      var updatedValidBookingStartTime = startDate.setDate(startDate.getDate() + 1);
      var updatedValidBookingEndTime = endDate.setDate(endDate.getDate() + 1);

      if (baseStartTime < updatedValidBookingStartTime || baseStartTime > updatedValidBookingEndTime) {
        isValidBookingDrop = false;
        break;
      }

    } else if (baseStartTime < validBookingStartTime || baseStartTime > validBookingEndTime) {
      isValidBookingDrop = false;
      break;
    }

    baseStartTime = baseStartTime + bookedSpot.Duration;

  }
  console.log(isValidBookingDrop);
  return isValidBookingDrop;

}

SchedulingHelper.IsValidDropDestinationSlot = (destinationDataItem, draggedData, offsetTime, scheduleDate, isInternal, channel) => {

  let isValidDrop = true

  let baseStartTime = destinationDataItem.SlotDateTime + destinationDataItem.Duration;

  console.log(draggedData)

  for (let index = 0; index < draggedData.length; index++) {

    const shortFormItem = draggedData[index];

    isValidDrop = SchedulingHelper.IsValidDropDestinationSlotForSingleItem(shortFormItem, baseStartTime, offsetTime, scheduleDate, isInternal, channel);
    if (!isValidDrop) {
      break;
    }
    baseStartTime = baseStartTime + shortFormItem.Duration;

  }
  console.log(isValidDrop);
  return isValidDrop;

}

SchedulingHelper.IsValidDropDestinationSlotForSingleItem = (item, startTime, offsetTime, scheduleDate, isInternal, channel) => {

  let isValidDrop = true;
  let baseStartTime = startTime;

  const publishings = isInternal ? item.mediaEpisode.Publishings : item.Publishings

  if (publishings && publishings.length > 0) {

    let validPublishings = publishings.filter((x) =>
      x.PublishStartDate <= scheduleDate && (x.PublishEndDate >= scheduleDate || x.TBA) && x.Channel.some((y) => y.SID == channel.SID)
    );

    console.log(validPublishings)

    for (let i = 0; i < validPublishings.length; i++) {
      let publishing = validPublishings[i];

      if(baseStartTime < publishing.PublishStartDate || baseStartTime > publishing.PublishEndDate){
        isValidDrop = false;
        continue;
      }

      if (publishing.TimeBound != undefined && publishing.TimeBound) {

        const validPublishingStartTime = scheduleDate + publishing.PublishStartDateTime;
        const validPublishingEndTime = scheduleDate + publishing.PublishEndDateTime;

        //CASE FOR 6:00-6:00 SCHEDULE DAY BOOKING  23:00 - 04:00
        if (publishing.PublishStartDateTime > publishing.PublishEndDateTime) {
          var date = new Date(validPublishingEndTime);
          var updatedValidBookingEndTime = date.setDate(date.getDate() + 1);

          if (baseStartTime < validPublishingStartTime || baseStartTime > updatedValidBookingEndTime) {
            isValidDrop = false;
            continue;
          }

        }  //CASE FOR 6:00-6:00 SCHEDULE DAY BOOKING SAME DAY  01:00 - 04:00
        else if (offsetTime > publishing.PublishStartDateTime) {
          var startDate = new Date(validPublishingStartTime);
          var endDate = new Date(validPublishingEndTime)
          var updatedValidBookingStartTime = startDate.setDate(startDate.getDate() + 1);
          var updatedValidBookingEndTime = endDate.setDate(endDate.getDate() + 1);

          if (baseStartTime < updatedValidBookingStartTime || baseStartTime > updatedValidBookingEndTime) {
            isValidDrop = false;
            continue;
          }

        } else if (baseStartTime < validPublishingStartTime || baseStartTime > validPublishingEndTime) {
          isValidDrop = false;
          continue;
        }

      } else {
        isValidDrop = true;
      }

      if (isValidDrop) {
        break;
      }

    }

  } else {

    isValidDrop = true;

  }

  return isValidDrop;

}

SchedulingHelper.GetMaxBreakNumber = (parentProgramSchedule_id, schedulerData) => {

  var maxBreakNo = 0;
  var parent = schedulerData.filter(data => {
    if (data._id.toString() == parentProgramSchedule_id) {
      return data;
    }
  });

  var children = parent && parent.length > 0 ? parent[0].children : [];

  for (var i = 0; i < children.length; i++) {
    if (children[i].BreakNo && children[i].BreakNo > maxBreakNo) {
      // console.log(children[i].BreakNo)
      maxBreakNo = children[i].BreakNo;
    }
  }
  return maxBreakNo;


}

SchedulingHelper.IsValidBookingDropPosition = (destinationDataItem, draggedData, schedulerData, isInternalDrag = false) => {

  let isValidBookingDrop = true;

  const maxBreakNo = SchedulingHelper.GetMaxBreakNumber(destinationDataItem.ParentProgramSchedule_id, schedulerData);

  for (let index = 0; index < draggedData.length; index++) {

    const bookedSpot = isInternalDrag ? draggedData[index].booking : draggedData[index];

    console.log(bookedSpot);

    if (bookedSpot && bookedSpot.Position && bookedSpot.Position.SID != BOOKINGPOSITIONS_ENUM.Any) {
      if (bookedSpot.Position.First && destinationDataItem.BreakNo != 1) {
        isValidBookingDrop = false;
        break;
      }
      else if (bookedSpot.Position.Last && destinationDataItem.BreakNo != maxBreakNo) {
        isValidBookingDrop = false;
        break;
      }
      else if (bookedSpot.Position.Mid && (destinationDataItem.BreakNo == 1 || destinationDataItem.BreakNo != maxBreakNo)) {
        isValidBookingDrop = false;
        break;
      }

    }
  }
  return isValidBookingDrop;

}

SchedulingHelper.IsValidBookingDropClientSpot = (destinationDataItem, draggedData, schedulerData, isInternalDrag = false) => {
  var result = { IsValid: true, Message: "" };
  var allChildrens = [];
  schedulerData.map((x) => {
    allChildrens = [...allChildrens, ...x.children.filter(x => x.Booking_id?.length ?? 0 > 0)]
  });

  for (let index = 0; index < draggedData.length; index++) {
    const bookedSpot = draggedData[index];
    if (bookedSpot && bookedSpot.Client && bookedSpot.Client?.SpotSeparation > 0) {
      var timeRangeFrom = destinationDataItem.SlotDateTime - bookedSpot.Client?.SpotSeparation;
      var timeRangeTo = destinationDataItem.SlotDateTime + bookedSpot.Client?.SpotSeparation;
      var filterData = allChildrens.filter((x) => x.Booking_id != bookedSpot.Booking_id && x.Client?.SID == bookedSpot.Client.SID && x.SlotDateTime >= timeRangeFrom && x.SlotDateTime <= timeRangeTo);
      if (filterData.length > 0) {
        result = { IsValid: false, Message: `Some Dragged Booked Spots Are Not under Valid Client Spot Seperation And Clashing With ${filterData[0].mediaEpisode.Title}  At - ${utility.convertMilisecondsToStringWithFrames(filterData[0].SlotDateTime)} ` }
        break;
      }
    }
  }
  return result;

}

SchedulingHelper.IsValidBookingDropInternal = (destinationDataItem, draggedData) => {

  let isValidBookingDrop = true
  let baseStartTime = destinationDataItem.SlotDateTime + destinationDataItem.Duration;

  for (let index = 0; index < draggedData.length; index++) {

    const item = draggedData[index];

    if (item.Booking_id && item.Booking_id.length > 0) {
      const validBookingStartTime = item.BookingDate + item.TimeRangeFrom;
      const validBookingEndTime = item.BookingDate + item.TimeRangeTo;

      if (baseStartTime < validBookingStartTime || baseStartTime > validBookingEndTime) {
        isValidBookingDrop = false;
        break;
      }
    }

    baseStartTime = baseStartTime + item.Duration;

  }

  return isValidBookingDrop;

}

SchedulingHelper.prepareData = (src, selectedChannel, draggedData, destinationDataItem, allData, isNew = false, isAutoFill = false, varianceSID = 0) => {
  const { isHeader, isBreak, level_0_index } = SchedulingHelper.getChildInfo(destinationDataItem, allData);
  const ParentProgramSchedule_id = isHeader || isBreak ? destinationDataItem._id : destinationDataItem.ParentProgramSchedule_id;
  const BreakNo = isHeader || isBreak ? 0 : destinationDataItem.BreakNo;

  const parentHeader = allData[level_0_index];




  var dataToSave = draggedData.map(data => {
    let id = (!isNew && (src == ENTITYNAME.ProgramSchedule || src == ENTITYNAME.BREAK_CHILDREN)) ? data._id : null

    return {
      _id: id,
      BreakNo: BreakNo,
      MediaEpisode_id: src == ENTITYNAME.ProgramSchedule || src == ENTITYNAME.BREAK_CHILDREN || src == ENTITYNAME.Booking ? data.MediaEpisode_id : data._id,
      ParentProgramSchedule_id: ParentProgramSchedule_id,
      //it will update in 
      // ScheduleDate: destinationDataItem.SlotDateTime + destinationDataItem.Duration,
      // SlotDateTime: destinationDataItem.SlotDateTime + destinationDataItem.Duration,
      Channel: { _id: selectedChannel._id?.toString(), SID: selectedChannel.SID, FullChannelName: selectedChannel.FullChannelName },
      TcIn: data.TcIn,
      TcOut: data.TcOut,
      Type: data.Type ?? 0,
      Booking_id: src == ENTITYNAME.Booking ? data._id : data.Booking_id ? data.Booking_id : '',
      Source: src == ENTITYNAME.Booking ? 'Booking' : 'Media',
      //TimeRange: src == ENTITYNAME.Booking ? utility.convertMilisecondsToShortTimeString(data.TimeRangeFrom) + " - " + utility.convertMilisecondsToShortTimeString(data.TimeRangeTo) : '',
      Duration: data.Duration,
      mediaEpisode: src == ENTITYNAME.ProgramSchedule || src == ENTITYNAME.BREAK_CHILDREN || src == ENTITYNAME.Booking ? data.mediaEpisode : data,
      [IS_BREAK]: data[IS_BREAK],
      children: data[IS_BREAK] ? data.children ?? [] : [],
      MediaId: src == ENTITYNAME.ProgramSchedule || src == ENTITYNAME.BREAK_CHILDREN || src == ENTITYNAME.Booking ? data.mediaEpisode.AssetId : data.AssetId,
      ParentHeaderMediaEpisode_id: parentHeader?.mediaEpisode?._id ?? '',
      campaign: src == ENTITYNAME.Booking ? data.campaign : data.booking ? data.booking.campaign : {},
      Client: src == ENTITYNAME.Booking ? data.Client : data.booking ? data.booking.Client : {},
      booking: src == ENTITYNAME.Booking ? data : data.booking ? data.booking : {},
      isAutoFill: isAutoFill,
      varianceSID: varianceSID
    };
  })


  return dataToSave;
}


SchedulingHelper.prepareDataforbackend = (dataItems) => {

  var dataToSave = dataItems.map(data => {

    return {
      _id: data._id,
      ParentProgramSchedule_id: data.ParentProgramSchedule_id,
      MediaEpisode_id: data.MediaEpisode_id,
      BreakNo: data.BreakNo,
      ScheduleDate: data.ScheduleDate,
      SlotDateTime: data.SlotDateTime,
      Type: data.Type ?? 0,
      Channel: data.Channel,
      TcIn: data.TcIn,
      TcOut: data.TcOut,
      Booking_id: data.Booking_id,
      Source: data.Source,
      [IS_BREAK]: data[IS_BREAK],
      Prototypes: data.Prototypes,
      ParentHeaderMediaEpisode_id: data.ParentHeaderMediaEpisode_id,
      Announcements: data.Announcements && data.Announcements.length > 0 ? data.Announcements.map(x => { return { _id: x._id, BookingDate: x.BookingDate, Duration: x.Duration, AssetId: x.AssetId, Title: x.Title, Description: x.Description, TimeRangeFrom: x.TimeRangeFrom, TimeRangeTo: x.TimeRangeTo, SpotRate: x.SpotRate } }) : [],
      VirtualSegmentKey: data?.VirtualSegmentKey ?? null,
      isAutoFill: data?.isAutoFill ?? false,
      varianceSID: data?.varianceSID ?? 0
    };
  })


  return dataToSave;
}


SchedulingHelper.prepareDataforVirtualSegmentation = (dataItems) => {

  var dataToSave = dataItems.map(data => {

    return {
      ParentProgramSchedule_id: data.ParentProgramSchedule_id,
      MediaEpisode_id: data.MediaEpisode_id,
      BreakNo: data.BreakNo,
      SlotDateTime: data.SlotDateTime,
      ScheduleDate: data.SlotDateTime,
      Type: data.Type ?? 0,
      SegmentDuration: data.Duration,
      Channel: data.Channel,
      TcIn: data.TcIn,
      TcOut: data.TcOut,
      MediaId: data?.MediaId ?? data?.mediaEpisode?.AssetId ?? null,
      Position: data?.Position ?? null,
      TXReady: data?.TXReady ?? null,
      VirtualSegmentKey: data?.VirtualSegmentKey ?? null,
      Prototypes: data?.Prototypes ?? []
    };
  })


  return dataToSave;
}


SchedulingHelper.getInsertTypeFromDesType = (destType) => {
  return destType == SCHEDULE_DROP_DEST_TYPE.header || destType == SCHEDULE_DROP_DEST_TYPE.header_children
    ? SCHEDULE_CHILDREN_TYPE.header_children
    : SCHEDULE_CHILDREN_TYPE.break_children
}


SchedulingHelper.getDestinationItemInfo = (itemInfo) => {

  return {
    level_0_index: itemInfo.level_0_index,
    level_1_index: itemInfo.isHeader ? 0 : itemInfo.isBreak || itemInfo.isInsideBreak ? itemInfo.level_1_index : itemInfo.level_1_index + 1,
    level_2_index: itemInfo.isBreak ? 0 : itemInfo.level_2_index + 1,
    insertType: SchedulingHelper.getInsertTypeFromDesType(itemInfo.drop_des_type)
  }
}

SchedulingHelper.getDestinationItemIndexes = (level_0_index, level_1_index = null) => {

  return {
    level_0_index: level_0_index,
    level_1_index: level_1_index,

    insertType: level_1_index == null
      ? SCHEDULE_CHILDREN_TYPE.header_children
      : SCHEDULE_CHILDREN_TYPE.break_children
  }
}

SchedulingHelper.convertMilisecondsToDateDDMMYYYY = function (ms) {
  const date = new Date(parseInt(ms))
  var day = date.getUTCDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
  var month = date.getUTCMonth().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
  var year = date.getUTCFullYear()
  // console.log(day + '-' + month + '-' + year)
  return day + '-' + month + '-' + year
}

SchedulingHelper.IsPromoBreakValidAccWeightage = function (breakNo, weightage) {
  var isValid = false;
  if (weightage > 0 && weightage <= 11) {
    return breakNo == 1 || breakNo % 10 == 1; // will go in breaks like 1,11,21,31 as of 10% filling
  }
  else if (weightage > 11 && weightage <= 22) {
    return breakNo == 1 || breakNo % 5 == 1; // will go in breaks like 1,6,11,16 as of 20% filling
  }
  else if (weightage > 22 && weightage <= 35) {
    return breakNo == 1 || breakNo % 3 == 1; // will go in breaks like 1,4,7 as of 33% filling
  }
  else if (weightage > 35 && weightage <= 52) {
    return breakNo == 1 || breakNo % 2 == 1; // will go in alternative breaks as of 50% filling
  }
  else if (weightage > 52 && weightage <= 75) {
    return breakNo == 1 || breakNo % 3 != 0; // will skip every 3rd break as of 66% filling
  }
  else if (weightage > 75 && weightage <= 85) {
    return breakNo == 1 || breakNo % 5 != 0; // will skip every 5th break as of 80% filling
  }
  else if (weightage > 85) {
    return true;// will be in every break as of 100% filling
  }
  return isValid;
}

SchedulingHelper.getCommercialPromoBreak = async (breakDuration, mediaCategorySID, mediaCategoryTypeSID) => {

  var breakData = [];

  if (breakDuration > 0) {
    var breakItem = await API.getData(ENTITYNAME.MediaEpisode, {
      query: [['IsBreak', '=', true], ['MediaCategory.SID', '=', mediaCategorySID], ['MediaCategoryType.SID', '=', mediaCategoryTypeSID], ['Duration', '=', breakDuration]]
    });

    //CREATE : if no available in DB
    if (breakItem.data.length == 0) {
      breakItem = await SchedulingHelper.createCommercialPromoBreak(breakDuration, mediaCategorySID, mediaCategoryTypeSID)
      breakData = [breakItem.data]
    } else {
      breakData = [breakItem.data[0]]
    }
  }

  return breakData;

}

//NEED TO CREATE FOR PROMO/COMMERCIAL
SchedulingHelper.createCommercialPromoBreak = async (duration, mediaCategorySID, mediaCategoryTypeSID) => {

  var mediaCategory = await API.getEntity(ENTITYNAME.MediaCategory, mediaCategorySID);
  var mediaCategoryType = await API.getEntity(ENTITYNAME.MediaCategoryType, mediaCategoryTypeSID);

  var durationString = utility.convertMilisecondsToStringWithFrames(duration)

  const saveItem = {
    AssetId: `${mediaCategoryType.data.Description}-BREAK-${durationString}`,
    Title: `${mediaCategoryType.data.Description} Break ${durationString}`,
    Content: {},
    Description: `${mediaCategoryType.data.Description} Break ${durationString}`,
    MediaCategory: mediaCategory.data,
    MediaCategoryType: mediaCategoryType.data,
    Genres: [],
    TcIn: 0,
    TcOut: 120000,
    Duration: duration,
    EpisodeNumber: 0,
    Brand: {},
    Product: {},
    IsBreak: true,
    ReleaseDate: 0,
    ImageUrl: "https://media.comicbook.com/files/img/default-movie.png",
    Archive: false,
    Publishings: []
  }

  var saveRes = await API.saveData(ENTITYNAME.MediaEpisode, saveItem);

  if (!saveRes.success) {
    console.log(saveRes)
  }

  return saveRes;

}

SchedulingHelper.getNextItemInSchedule = (destination, schedulerDataRef) => {

  var nextItem = {}

  const { level_0_index, level_1_index, level_2_index, isInsideBreak } = SchedulingHelper.getChildInfo(destination, schedulerDataRef.current);

  if (isInsideBreak) {
    const childLength = schedulerDataRef.current[level_0_index].children[level_1_index].children?.length ?? 0;
    nextItem = childLength > level_2_index + 1 ? schedulerDataRef.current[level_0_index].children[level_1_index].children[level_2_index + 1] : {}
  }
  else {
    const childLength = schedulerDataRef.current[level_0_index].children?.length ?? 0;
    nextItem = childLength > level_1_index + 1 ? schedulerDataRef.current[level_0_index].children[level_1_index + 1] : {};
  }

  return nextItem;

}

SchedulingHelper.isValidDestination = async (destination, enabledScheduleConstraint, bookedSpot, schedulerDataRef) => {

  if (enabledScheduleConstraint == undefined || enabledScheduleConstraint.length == 0) return true;

  if (destination == undefined || destination.mediaEpisode == undefined) {
    return false;
  }

  var nextItemToDestination = SchedulingHelper.getNextItemInSchedule(destination, schedulerDataRef);

  for (let index = 0; index < enabledScheduleConstraint.length; index++) {
    const constraint = enabledScheduleConstraint[index];

    switch (constraint.SID) {
      case SCHEDULECONSTRAINTS.BackToBackCommercial:

        if (destination.mediaEpisode?.AssetId == bookedSpot.mediaEpisode?.AssetId || (nextItemToDestination != {} && nextItemToDestination.mediaEpisode?.AssetId == bookedSpot.mediaEpisode?.AssetId)) {
          console.log("Returning false BackToBackCommercial");
          return false;
        }

        break;

      case SCHEDULECONSTRAINTS.BackToBackBrand:

        if ((destination.mediaEpisode?.Brand?.SID ?? 0 == bookedSpot.mediaEpisode.Brand.SID) || (nextItemToDestination != {} && (nextItemToDestination.mediaEpisode?.Brand?.SID ?? 0) == bookedSpot.mediaEpisode.Brand.SID)) {
          console.log("Returning false BackToBackBrand");
          return false;
        }

        break;

      case SCHEDULECONSTRAINTS.BackToBackProduct:

        if ((destination.mediaEpisode?.Product?.SID ?? 0 == bookedSpot.mediaEpisode.Product.SID) || (nextItemToDestination != {} && (nextItemToDestination.mediaEpisode?.Product?.SID ?? 0) == bookedSpot.mediaEpisode.Product.SID)) {
          console.log("Returning false BackToBackProduct");
          return false;
        }

        break;
    }

  }

  return true;
}

SchedulingHelper.isValidDestinationForInterstitial = async (destination, enabledScheduleConstraint, interstitial, schedulerDataRef) => {

  if (enabledScheduleConstraint == undefined || enabledScheduleConstraint.length == 0) return true;

  if (destination == undefined || destination.mediaEpisode == undefined) {
    return false;
  }

  var nextItemToDestination = SchedulingHelper.getNextItemInSchedule(destination, schedulerDataRef);

  for (let index = 0; index < enabledScheduleConstraint.length; index++) {
    const constraint = enabledScheduleConstraint[index];

    switch (constraint.SID) {

      case SCHEDULECONSTRAINTS.BackToBackPromo:

        if (destination.mediaEpisode?.AssetId == interstitial.mediaEpisode?.AssetId || (nextItemToDestination != {} && nextItemToDestination.mediaEpisode?.AssetId == interstitial.mediaEpisode?.AssetId)) {
          console.log("Returning false BackToBackPromo");
          return false;
        }

        break;

      case SCHEDULECONSTRAINTS.SameProgramSamePromo:

        var parent = SchedulingHelper.getParent(destination.ParentProgramSchedule_id, schedulerDataRef.current);

        if (parent && parent.mediaEpisode?.SID == interstitial?.Media?.SID) {
          console.log("Returning false SameProgramSamePromo");
          return false;
        }

        break;
    }

  }

  return true;
}

SchedulingHelper.getNextItemInSchedule = (destination, schedulerDataRef) => {

  var nextItem = {}

  const { level_0_index, level_1_index, level_2_index, isInsideBreak } = SchedulingHelper.getChildInfo(destination, schedulerDataRef.current);

  if (isInsideBreak) {
    const childLength = schedulerDataRef.current[level_0_index].children[level_1_index].children?.length ?? 0;
    nextItem = childLength > level_2_index + 1 ? schedulerDataRef.current[level_0_index].children[level_1_index].children[level_2_index + 1] : {}
  }
  else {
    const childLength = schedulerDataRef.current[level_0_index].children?.length ?? 0;
    nextItem = childLength > level_1_index + 1 ? schedulerDataRef.current[level_0_index].children[level_1_index + 1] : {};
  }

  return nextItem;

}

SchedulingHelper.isValidDestination = async (destination, enabledScheduleConstraint, bookedSpot, schedulerDataRef) => {

  console.log(destination);
  if (destination == undefined || destination.mediaEpisode == undefined) {
    return false;
  }

  var nextItemToDestination = SchedulingHelper.getNextItemInSchedule(destination, schedulerDataRef);

  for (let index = 0; index < enabledScheduleConstraint.length; index++) {
    const constraint = enabledScheduleConstraint[index];

    switch (constraint.SID) {
      case SCHEDULECONSTRAINTS.BackToBackCommercial:

        if (destination.mediaEpisode?.AssetId == bookedSpot.mediaEpisode?.AssetId || (nextItemToDestination != {} && nextItemToDestination.mediaEpisode?.AssetId == bookedSpot.mediaEpisode?.AssetId)) {
          console.log("Returning false BackToBackCommercial");
          return false;
        }

        break;

      case SCHEDULECONSTRAINTS.BackToBackBrand:

        if ((destination.mediaEpisode?.Brand?.SID ?? 0 == bookedSpot.mediaEpisode.Brand.SID) || (nextItemToDestination != {} && (nextItemToDestination.mediaEpisode?.Brand?.SID ?? 0) == bookedSpot.mediaEpisode.Brand.SID)) {
          console.log("Returning false BackToBackBrand");
          return false;
        }

        break;

      case SCHEDULECONSTRAINTS.BackToBackProduct:

        if ((destination.mediaEpisode?.Product?.SID ?? 0 == bookedSpot.mediaEpisode.Product.SID) || (nextItemToDestination != {} && (nextItemToDestination.mediaEpisode?.Product?.SID ?? 0) == bookedSpot.mediaEpisode.Product.SID)) {
          console.log("Returning false BackToBackProduct");
          return false;
        }

        break;
    }

  }

  return true;
}

SchedulingHelper.isValidDestinationForInterstitial = async (destination, enabledScheduleConstraint, interstitial, schedulerDataRef) => {

  console.log(destination);
  if (destination == undefined || destination.mediaEpisode == undefined) {
    return false;
  }

  var nextItemToDestination = SchedulingHelper.getNextItemInSchedule(destination, schedulerDataRef);

  for (let index = 0; index < enabledScheduleConstraint.length; index++) {
    const constraint = enabledScheduleConstraint[index];

    switch (constraint.SID) {

      case SCHEDULECONSTRAINTS.BackToBackPromo:

        if (destination.mediaEpisode?.AssetId == interstitial.mediaEpisode?.AssetId || (nextItemToDestination != {} && nextItemToDestination.mediaEpisode?.AssetId == interstitial.mediaEpisode?.AssetId)) {
          console.log("Returning false BackToBackPromo");
          return false;
        }

        break;

      case SCHEDULECONSTRAINTS.SameProgramSamePromo:

        var parent = SchedulingHelper.getParent(destination.ParentProgramSchedule_id, schedulerDataRef.current);

        if (parent && parent.mediaEpisode?.SID == interstitial?.Media?.SID) {
          console.log("Returning false SameProgramSamePromo");
          return false;
        }

        break;
    }

  }

  return true;
}

SchedulingHelper.IsAllHeadersSegmented = async (scheduleData) => {

  var result = { isvalid: true, headerstarttime: "" };

  for (let index = 0; index < scheduleData.length; index++) {
    const parent = scheduleData[index];

    const segments = parent.children.filter(c => c.Type == SCHEDULETYPE.Segment);
    if (segments == undefined || segments.length == 0) {
      result = { isvalid: false, headerstarttime: utility.convertMilisecondsToStringWithFrames(parent.SlotDateTime) };
      break;
    }

  }

  return result;
}

SchedulingHelper.revertVirtualSegment = async (selectedSegments, allData, loaddata) => {
  if (selectedSegments.length == 0) {
    toast.error("Select at least one Item")
    return;
  }
  else if (selectedSegments.length > 1) {
    toast.error("Select only one Item")
    return;

  } else if (selectedSegments.some(data => !SchedulingHelper.canModify(data, allData))) {
    toast.error('Locked header found. Please unlock and try again.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return;
  } else if (selectedSegments[0][IS_BREAK]) {
    toast.error("Virtual Segmentation not allowed on breaks");
    return;
  } else if (selectedSegments[0].VirtualSegmentKey == null) {
    toast.error("This is not a part of Virtual Segmentation");
    return;
  }
  let parent = SchedulingHelper.getParent(selectedSegments[0].ParentProgramSchedule_id, allData);
  let revertSegments = parent.children.filter(data => data.VirtualSegmentKey == selectedSegments[0].VirtualSegmentKey);

  let duration = 0;
  for (let index = 0; index < revertSegments.length; index++) {
    duration = duration + revertSegments[index].Duration;

  }

  let newSegment = SchedulingHelper.prepareDataforVirtualSegmentation(revertSegments)[0];
  let medID = newSegment.VirtualSegmentKey.split('-');
  newSegment = {
    ...newSegment,
    MediaId: medID[1] + '-' + medID[2] + '-' + medID[3],
    SegmentDuration: duration,
    TcOut: newSegment.TcIn + duration,
    VirtualSegmentKey: null,
    Position: medID[2].replace('0', '') + '/' + medID[3].replace('0', '')
  };
  let saveRes = await API.saveData(ENTITYNAME.ProgramSchedule, newSegment);
  if (!saveRes.success) {
    toast.error(saveRes.message);
  }
  for (let index = 0; index < revertSegments.length; index++) {
    const element = revertSegments[index];
    let deleteRes = await API.deleteData(ENTITYNAME.ProgramSchedule, element._id, '_id');
    if (!deleteRes.success) {
      toast.error(deleteRes.message);
      return;
    }

  }

  await loaddata();
}

SchedulingHelper.onSaveVirtualSegmentation = async (virtualSegmentationData, selectedSegment, onCancelVirtualSegmentationPopup, loaddata) => {

  if (virtualSegmentationData.gridData.length == 0) {
    toast.error("Split segments first");
    return;
  }

  if (virtualSegmentationData.diffDur != "00:00:00:00") {
    toast.error("There is difference in duration");
    return;
  }


  let saveDataArray = SchedulingHelper.prepareDataforVirtualSegmentation(virtualSegmentationData.gridData);

  let deleteRes = await API.deleteData(ENTITYNAME.ProgramSchedule, selectedSegment._id, '_id');
  if (!deleteRes.success) {
    toast.error(deleteRes.message);
    return;
  }
  let saveRes = await API.saveData(ENTITYNAME.ProgramSchedule, saveDataArray);
  if (!saveRes.success) {
    toast.error(saveRes.message);
  }
  console.log(saveDataArray);
  onCancelVirtualSegmentationPopup();
  await loaddata();
}

SchedulingHelper.inlineEdit = (event, virtualSegmentationData, setVirtualSegmentationData, selectedSegment) => {
  const field = event.field || "";
  const newData = virtualSegmentationData.gridData.map((item, index) => {
    if (field == "Duration") {
      if (item.MediaId == event.dataItem.MediaId) {
        console.log(event.value)
        item['Duration'] = utility.convertStringWithFramesToMilliseconds(event.value);
      }
    }
    return item;
  });
  console.log(newData)
  let totalDuration = 0;
  for (let index = 0; index < newData.length; index++) {
    totalDuration = totalDuration + newData[index].Duration;
  }
  let actualtotalDuration = utility.convertStringWithFramesToMilliseconds(virtualSegmentationData.totalDur);
  let diffDuration = "00:00:00:00";
  console.log(totalDuration);
  console.log(actualtotalDuration);


  if (actualtotalDuration < totalDuration) {
    diffDuration = utility.convertMilisecondsToStringWithFrames(totalDuration - actualtotalDuration);
  } else {
    diffDuration = utility.convertMilisecondsToStringWithFrames(actualtotalDuration - totalDuration);
  }
  let tcin = selectedSegment.TcIn;
  let sdt = selectedSegment.SlotDateTime;
  setVirtualSegmentationData({
    ...virtualSegmentationData,
    gridData: newData.map((data, i) => {
      let nData = {
        ...data,
        TcIn: tcin,
        TcOut: tcin + data.Duration,
        Duration: data.Duration,
        SlotDateTime: sdt,
        ScheduleDate: sdt,
      }
      tcin = tcin + data.Duration;
      sdt = sdt + data.Duration;
      return nData;
    }),
    diffDur: diffDuration
  });
}

SchedulingHelper.onVirtualSegmentationSplitClick = (virtualSegmentationData, setVirtualSegmentationData, selectedSegment) => {
  if (virtualSegmentationData.noOfSeg < 2) {
    toast.error("Number of segments should be greater than 1");
    return;
  }
  if (virtualSegmentationData.duration == "00:00:00:00") {
    toast.error("Please enter duration");
    return;
  }
  const Duration = utility.convertStringWithFramesToMilliseconds(virtualSegmentationData.duration) / virtualSegmentationData.noOfSeg;
  const mediaIDs = [];
  const virtualSegmentKey = selectedSegment._id + '-' + selectedSegment.MediaId;
  let mediaID = selectedSegment.MediaId.split('-');
  for (let index = 1; index <= virtualSegmentationData.noOfSeg; index++) {
    mediaIDs.push(mediaID[0] + '-' + mediaID[1] + '.' + index + '-' + mediaID[2]);
  }

  let tcin = selectedSegment.TcIn;
  let sdt = selectedSegment.SlotDateTime;
  setVirtualSegmentationData({
    ...virtualSegmentationData,
    gridData: mediaIDs.map((mID, i) => {
      let medID = mID.split('-');
      console.log(medID)
      let data = {
        ...selectedSegment,
        VirtualSegmentKey: virtualSegmentKey,
        MediaId: mID,
        Duration: Duration,
        TcIn: tcin,
        TcOut: tcin + Duration,
        SlotDateTime: sdt,
        ScheduleDate: sdt,
        Position: medID[1].replace('0', '') + "/" + medID[2].replace('0', ''),
      }
      tcin = tcin + Duration;
      sdt = sdt + Duration;
      delete data['SID'];
      delete data['_id'];
      return data;
    }),
    totalDur: virtualSegmentationData.duration
  })
}



SchedulingHelper.findBestElementToPlaceInSchedule = async (formatelement, variationlist, maxBreakNo, spaceLeft, formatElementBreakNo, parent, scheduleData, isLastHeader, dst) => {

  const variationType = formatelement.VariationType ?? FORMATVARIATIONTYPE.Fixed;
  console.log(variationType);
  // if fixed type, we will return same
  if (variationType == FORMATVARIATIONTYPE.Fixed) return formatelement.mediaEpisode;

  // if not fixed we need to find variance
  // if 0 break no then opening
  // if break == maxBreak then closing
  // else mid
  const mediaType = formatElementBreakNo == 0 ? MEDIATYPEENUM.OPENING : (formatElementBreakNo == maxBreakNo ? MEDIATYPEENUM.CLOSING : MEDIATYPEENUM.MID);
  var variationsConfgured = variationlist.find(x => x.MediaType.SID == mediaType);

  if (variationsConfgured && variationsConfgured.MediaEpisodes) {

    // if(dst && dst.mediaEpisode && dst.mediaEpisode.MediaCategoryType){
    //   if(dst.mediaEpisode.MediaCategoryType.SID == (formatelement.mediaEpisode?.MediaCategoryType?.SID??0)){
    //     return undefined;
    //   }
    // }



    // if variations available, we will find variation of same media category type
    // if available then good otherwise it will fall under fixed format media
    var sameMediaCategoryTypes = variationsConfgured.MediaEpisodes.filter(x => x.MediaCategoryType.SID == formatelement.mediaEpisode?.MediaCategoryType?.SID ?? 0);
    if (sameMediaCategoryTypes == undefined || sameMediaCategoryTypes.length == 0) return formatelement.mediaEpisode;

    // Fixed Family Filter
    if (variationType == FORMATVARIATIONTYPE.FixedFamily) {
      sameMediaCategoryTypes = sameMediaCategoryTypes.filter(x => (x.Variance?.SID ?? 0) == (formatelement.mediaEpisode?.Variance?.SID ?? 0));
    }

    // keeping this to use later
    var itemsOfSameMediaCategoryTypes = sameMediaCategoryTypes;

    if (!isLastHeader) sameMediaCategoryTypes = sameMediaCategoryTypes.filter(x => x.Duration <= (spaceLeft + 2000)); // adding 2 second extra so that can overrun

    // not repeating in same block if variation type
    if (variationType == FORMATVARIATIONTYPE.Variation && scheduleData.length > 0) {

      var allreadyFilledChild = scheduleData.find(x => x._id == parent._id)?.children ?? [];
      sameMediaCategoryTypes = sameMediaCategoryTypes.filter(x => allreadyFilledChild.filter(y => y.mediaEpisode?.SID == x.SID) == 0);

    }

    // checking if we over run item duration it should be less than under run
    var finalItem = sameMediaCategoryTypes.length > 0
      ? sameMediaCategoryTypes[Math.floor(Math.random() * sameMediaCategoryTypes.length)]
      : undefined;

    // check with smallest item of this category
    if (finalItem == undefined) {

      // not repeating in same block if variation type
      if (variationType == FORMATVARIATIONTYPE.Variation && scheduleData.length > 0) {
        var allreadyFilledChildren = scheduleData.find(x => x._id == parent._id)?.children ?? [];
        itemsOfSameMediaCategoryTypes = itemsOfSameMediaCategoryTypes.filter(x => allreadyFilledChildren.filter(y => y.mediaEpisode?.SID == x.SID) == 0);
      }

      var sorted = itemsOfSameMediaCategoryTypes.sort((a, b) => a.Duration - b.Duration);
      if (sorted && sorted.length > 0) {
        const smallest = sorted[0];
        const updatedDurationIfApplyOverRun = (smallest.Duration - spaceLeft);

        // if footer duration can be reduced by making underrun to over run, we will allow.
        return updatedDurationIfApplyOverRun < spaceLeft ? smallest : undefined;

      }
      else return undefined;

    }
    else return finalItem;


  }
  else {
    return formatelement.mediaEpisode;
  }

}

SchedulingHelper.findBestElementToPlaceInScheduleNew = async (formatelement, variationlist, maxBreakNo, spaceLeft, formatElementBreakNo, parent, scheduleData, isLastHeader, dst, completemediaLibrary) => {

  const variationType = formatelement.VariationType ?? FORMATVARIATIONTYPE.Fixed;
  // if fixed type, we will return same
  if (variationType == FORMATVARIATIONTYPE.Fixed) return formatelement.mediaEpisode;

  var variationsConfgured = variationlist.find(x => x._id.toString() == formatelement.Variation_id);

  if (variationsConfgured && variationsConfgured.MediaEpisodes) {

    // if available then good otherwise it will fall under fixed format media
    var sameMediaCategoryTypes = variationsConfgured.IsFetchFromLibrary && variationsConfgured.MediaCategoryType?.SID > 0 ? completemediaLibrary.filter(x => x.MediaCategoryType.SID == variationsConfgured.MediaCategoryType.SID) : variationsConfgured.MediaEpisodes;

    // keeping this to use later
    var itemsOfSameMediaCategoryTypes = sameMediaCategoryTypes;

    if (!isLastHeader) sameMediaCategoryTypes = sameMediaCategoryTypes.filter(x => x.Duration <= (spaceLeft + 2000)); // adding 2 second extra so that can overrun

    // not repeating in same block if variation type
    if (variationType == FORMATVARIATIONTYPE.Variation && scheduleData.length > 0) {

      var allreadyFilledChild = scheduleData.find(x => x._id == parent._id)?.children ?? [];
      sameMediaCategoryTypes = sameMediaCategoryTypes.filter(x => allreadyFilledChild.filter(y => y.mediaEpisode?.SID == x.SID) == 0);

    }

    // checking if we over run item duration it should be less than under run
    var finalItem = sameMediaCategoryTypes.length > 0
      ? sameMediaCategoryTypes[Math.floor(Math.random() * sameMediaCategoryTypes.length)]
      : undefined;

    // check with smallest item of this category
    if (finalItem == undefined) {

      // not repeating in same block if variation type
      if (variationType == FORMATVARIATIONTYPE.Variation && scheduleData.length > 0) {
        var allreadyFilledChildren = scheduleData.find(x => x._id == parent._id)?.children ?? [];
        itemsOfSameMediaCategoryTypes = itemsOfSameMediaCategoryTypes.filter(x => allreadyFilledChildren.filter(y => y.mediaEpisode?.SID == x.SID) == 0);
      }

      var sorted = itemsOfSameMediaCategoryTypes.sort((a, b) => a.Duration - b.Duration);
      if (sorted && sorted.length > 0) {
        const smallest = sorted[0];
        const updatedDurationIfApplyOverRun = (smallest.Duration - spaceLeft);

        // if footer duration can be reduced by making underrun to over run, we will allow.
        return updatedDurationIfApplyOverRun < spaceLeft ? smallest : undefined;

      }
      else return undefined;

    }
    else return finalItem;


  }
  else {
    return formatelement.mediaEpisode;
  }

}

SchedulingHelper.getDefaultFormat = async (selectedChannelSID) => {

  const defaultFormatRes = await API.getData(ENTITYNAME.Format, {
    query: [["Channel.SID", "=", selectedChannelSID], ["Archive", "=", false], ["IsDefault", "=", true]]
  });

  return defaultFormatRes.success && defaultFormatRes.data && defaultFormatRes.data.length > 0
    ? defaultFormatRes.data[0]
    : undefined;
}


SchedulingHelper.getFormatByDateAndChannel = async (selectedChannelSID, selectedDate) => {

  const formatConfigRes = await API.getData("formatSchedule", {
    query: [["Channel.SID", "=", selectedChannelSID], ["Archive", "=", false], ["FromDate", "<=", selectedDate], ["ToDate", ">=", selectedDate]]
  });

  return formatConfigRes.success && formatConfigRes.data && formatConfigRes.data.length > 0
    ? formatConfigRes.data
    : [];

}

SchedulingHelper.getRecursiveListSequencedByVarianceSID = async (list) => {

  if (!list || list.length == 0) return [];

  // order set
  list.sort((a, b) => a.SID - b.SID);


  // Step 1: Group objects by VarianceSID
  const grouped = list.reduce((acc, obj) => {
    acc[obj.Variance.SID] = acc[obj.Variance.SID] || [];
    acc[obj.Variance.SID].push(obj);
    return acc;
  }, {});

  // VarianceSID keys in sorted order
  const varianceOrder = Object.keys(grouped).sort((a, b) => a - b);
  console.log(varianceOrder);

  let result = [];
  let hasElements = true;

  // Step 3: Round-robin with pointers and reset
  while (hasElements) {
    hasElements = false;

    for (const key of varianceOrder) {
      const group = grouped[key];

      // If there are items in the current group, add the item at the pointer to the result
      if (group && group.length > 0) {
        result.push(group.shift());
        hasElements = true;
      }
    }
  }

  return result;

}


SchedulingHelper.getValidSlotSpecificFormat = async (formatschedules, slotDateTime) => {

  let format = undefined;

  // separating timeonly from datetime
  var slottime = utility.convertStringWithFramesToMilliseconds(utility.convertMilisecondsToStringWithFrames(slotDateTime));

  // filtering valid format schedule as per header slot time
  const slotspecificformat = formatschedules.filter(x => x.ValidFrom >= slottime && x.ValidTo <= slottime);

  var formatConfig = slotspecificformat && slotspecificformat.length > 0
    ? slotspecificformat[0]
    : undefined;

  // if exists then only we will hit API to pull format
  if (formatConfig && formatConfig.Format_id) {

    const formatRes = await API.getData(ENTITYNAME.Format, {
      query: [["_id", "=", formatConfig.Format_id]]
    });

    format = formatRes.success && formatRes.data && formatRes.data.length > 0
      ? formatRes.data[0]
      : undefined;
  }


  return format;
}


SchedulingHelper.findSpaceLeftInHeaderToPlace = async (header, currentSchedule) => {

  var latestHeader = currentSchedule.find(x => x._id == header._id);

  // cannot find header. so NO
  if (latestHeader == null || latestHeader == undefined) {
    console.log("header not found");
    return 0;
  }

  // if over run exists, straight NO
  if (latestHeader.children.filter(x => x.Type == SCHEDULETYPE.OverRun).length > 0) return 0;
  const underRun = latestHeader.children.find(x => x.Type == SCHEDULETYPE.UnderRun);

  return underRun && underRun.Duration ? underRun.Duration : 0;

}

SchedulingHelper.getVariationsData = async (payload) => {

  let finalData = [];
  console.log(payload);

  const variationRes = await API.getVariation({
    ChannelSid: payload.channelSID,
    Date: payload.ScheduleDate
  })


  if (variationRes.success && variationRes.data && variationRes.data.length > 0) {
    finalData = variationRes.data;
  }

  console.log({ success: variationRes.success, data: finalData, message: variationRes.message });
  console.log("VARIATIONSSS");
  return { success: variationRes.success, data: finalData, message: variationRes.message };
}

SchedulingHelper.findFooter = async (header, currentSchedule) => {

  var latestHeader = currentSchedule.find(x => x._id == header._id);

  // cannot find header. so NO
  if (latestHeader == null || latestHeader == undefined) {
    console.log("header not found");
    return false;
  }

  // if over run exists, straight NO
  if (latestHeader.children.filter(x => x.Type == SCHEDULETYPE.OverRun).length > 0) return false;

  return latestHeader.children.find(x => x.Type == SCHEDULETYPE.UnderRun || x.Type == SCHEDULETYPE.OverRun);
}

SchedulingHelper.IsPlaylist24HourReached = (payload, scheduledata) => {

  const expectedEndTime = (payload.ScheduleEndDate + 1);
  const lastheaderChildren = scheduledata[scheduledata.length - 1].children;
  const currentEndTime = lastheaderChildren && lastheaderChildren.length > 0
    ? (lastheaderChildren[lastheaderChildren.length - 1].SlotDateTime) + (lastheaderChildren[lastheaderChildren.length - 1].Duration)
    : (scheduledata[scheduledata.length - 1].SlotDateTime) + (scheduledata[scheduledata.length - 1].Duration);

  console.log("expectedEndTime:" + expectedEndTime);

  console.log("currentEndTime:" + currentEndTime);

  var difference = expectedEndTime > currentEndTime ? expectedEndTime - currentEndTime : 0;

  return difference == 0;
}

SchedulingHelper.getInterstitialsForSameDurationOfFooter = async (footerDuration, availableVariation, lastChild = undefined) => {

  var groupedInterstitials = [];

  var loopCount = 0;
  var durationRemaining = footerDuration;

  while (durationRemaining > 0 && loopCount < availableVariation.length) {

    var interstitialLessThenFooterDuration = availableVariation.filter(i => i.Duration <= durationRemaining);
    if (interstitialLessThenFooterDuration == undefined || interstitialLessThenFooterDuration.Count == 0) break;

    var bestPossibleFiller = await SchedulingHelper.getBestPossibleFiller(interstitialLessThenFooterDuration, durationRemaining, groupedInterstitials, lastChild);

    if (bestPossibleFiller && bestPossibleFiller.Duration > 0) {
      groupedInterstitials.push(bestPossibleFiller);
      durationRemaining = durationRemaining - bestPossibleFiller.Duration;
      loopCount = 0;// reset loop count
    }
    else {
      loopCount = loopCount + 1;
    }
  }

  return groupedInterstitials;
}

SchedulingHelper.getReplaceOption = async (currentItem, footerDuration, availableVaritations, isUnderRun, autoDeleteOffFooterOffset, allVariations) => {

  // fixed variation media episodes
  var fixedVariation = allVariations.data.find(x => x.MediaType.SID == MEDIATYPEENUM.FIXED);
  // if item is one of the fixed variation, we cannot change it
  if (fixedVariation && fixedVariation.MediaEpisodes && fixedVariation.MediaEpisodes.some(x => x._id.toString() == currentItem.mediaEpisode._id.toString())) return undefined;

  // variations other than currentItem of same mediacategoryType
  var sameCategoryVariations = availableVaritations.filter(x => x._id.toString() != currentItem.mediaEpisode._id.toString() && x.MediaCategoryType.SID == currentItem.mediaEpisode.MediaCategoryType.SID)

  // handling fixed family
  if (currentItem.varianceSID > 0) {
    sameCategoryVariations = sameCategoryVariations.filter(x => (x.Variance?.SID ?? 0) == currentItem.varianceSID);
  }

  var bestFit = [];
  if (isUnderRun) {
    // first we will check for exact match, removing frames
    var minDuration = (currentItem.mediaEpisode.Duration + footerDuration) - 1000;
    var maxDuration = (currentItem.mediaEpisode.Duration + footerDuration) + 1000;

    bestFit = sameCategoryVariations.filter(x => x.Duration >= minDuration && x.Duration <= maxDuration);
    if (bestFit.length > 0) {
      return bestFit[Math.floor(Math.random() * bestFit.length)];
    }

    // if not found we will look for item till auto delete footer range
    minDuration = (currentItem.mediaEpisode.Duration + footerDuration) - autoDeleteOffFooterOffset;
    maxDuration = (currentItem.mediaEpisode.Duration + footerDuration) + autoDeleteOffFooterOffset;

    bestFit = sameCategoryVariations.filter(x => x.Duration > minDuration && x.Duration <= maxDuration);
    if (bestFit.length > 0) {
      return bestFit[Math.floor(Math.random() * bestFit.length)];
    }


    // else we will check for better item close to our agenda
    minDuration = currentItem.mediaEpisode.Duration;
    maxDuration = (currentItem.mediaEpisode.Duration + footerDuration) + autoDeleteOffFooterOffset;

    bestFit = sameCategoryVariations.filter(x => x.Duration > minDuration && x.Duration <= maxDuration);
    if (bestFit.length > 0) {
      var sorted = bestFit.sort((a, b) => a.Duration - b.Duration);
      return sorted[sorted.length - 1]; // longest
    }

  }
  else // over run
  {
    // first we will check for exact match, removing frames
    var minDuration = (currentItem.mediaEpisode.Duration - footerDuration) - 1000;
    var maxDuration = (currentItem.mediaEpisode.Duration - footerDuration) + 1000;

    bestFit = sameCategoryVariations.filter(x => x.Duration >= minDuration && x.Duration <= maxDuration);
    if (bestFit.length > 0) {
      return bestFit[Math.floor(Math.random() * bestFit.length)];
    }

    // if not found we will look for item till auto delete footer range
    minDuration = (currentItem.mediaEpisode.Duration - footerDuration) - autoDeleteOffFooterOffset;
    maxDuration = (currentItem.mediaEpisode.Duration - footerDuration) + autoDeleteOffFooterOffset;

    bestFit = sameCategoryVariations.filter(x => x.Duration >= minDuration && x.Duration < maxDuration);
    if (bestFit.length > 0) {
      return bestFit[Math.floor(Math.random() * bestFit.length)];
    }


    // else we will check for better item close to our agenda
    minDuration = (currentItem.mediaEpisode.Duration - footerDuration) - autoDeleteOffFooterOffset;
    maxDuration = currentItem.mediaEpisode.Duration;

    bestFit = sameCategoryVariations.filter(x => x.Duration >= minDuration && x.Duration < maxDuration);
    if (bestFit.length > 0) {
      var sorted = bestFit.sort((a, b) => a.Duration - b.Duration);
      return sorted[0]; // smallest
    }

  }

  // nothing found
  return undefined;
}

SchedulingHelper.getReplaceOptionForAnyChildren = async (footerDuration, availableVaritations, isUnderRun, allchildren, allVariations) => {

  // fixed variation media episodes
  var fixedVariation = allVariations.data.find(x => x.MediaType.SID == MEDIATYPEENUM.FIXED);

  var bestFit = [];
  if (isUnderRun) {
    for (let index = 0; index < allchildren.length; index++) {
      const currentItem = allchildren[index];

      if (currentItem.Type == SCHEDULETYPE.Segment || currentItem.Type == SCHEDULETYPE.UnderRun || currentItem.Type == SCHEDULETYPE.OverRun || currentItem[IS_BREAK]) continue;

      // if item is one of the fixed variation, we cannot change it
      if (fixedVariation && fixedVariation.MediaEpisodes && fixedVariation.MediaEpisodes.some(x => x._id.toString() == currentItem.mediaEpisode._id.toString())) continue;

      // variations other than currentItem of same mediacategoryType
      var sameCategoryVariations = availableVaritations.filter(x => x._id.toString() != currentItem.mediaEpisode._id.toString() && x.MediaCategoryType.SID == currentItem.mediaEpisode.MediaCategoryType.SID)

      // handling fixed family
      if (currentItem.varianceSID > 0) {
        sameCategoryVariations = sameCategoryVariations.filter(x => (x.Variance?.SID ?? 0) == currentItem.varianceSID);
      }

      // first we will check for exact match, removing frames
      var minDuration = (currentItem.mediaEpisode.Duration + footerDuration) - 1000;
      var maxDuration = (currentItem.mediaEpisode.Duration + footerDuration) + 1000;

      bestFit = sameCategoryVariations.filter(x => x.Duration >= minDuration && x.Duration <= maxDuration);
      if (bestFit.length > 0) {
        return { destination: currentItem, replacement: bestFit[Math.floor(Math.random() * bestFit.length)] };
      }
    }

  }
  else {

    for (let index = 0; index < allchildren.length; index++) {
      const currentItem = allchildren[index];

      if (currentItem.Type == SCHEDULETYPE.Segment || currentItem.Type == SCHEDULETYPE.UnderRun || currentItem.Type == SCHEDULETYPE.OverRun || currentItem[IS_BREAK]) continue;

      // if item is one of the fixed variation, we cannot change it
      if (fixedVariation && fixedVariation.MediaEpisodes && fixedVariation.MediaEpisodes.some(x => x._id.toString() == currentItem.mediaEpisode._id.toString())) continue;

      // variations other than currentItem of same mediacategoryType
      var sameCategoryVariations = availableVaritations.filter(x => x._id.toString() != currentItem.mediaEpisode._id.toString() && x.MediaCategoryType.SID == currentItem.mediaEpisode.MediaCategoryType.SID)

      // handling fixed family
      if (currentItem.varianceSID > 0) {
        sameCategoryVariations = sameCategoryVariations.filter(x => (x.Variance?.SID ?? 0) == currentItem.varianceSID);
      }

      // first we will check for exact match, removing frames
      var minDuration = (currentItem.mediaEpisode.Duration - footerDuration) - 1000;
      var maxDuration = (currentItem.mediaEpisode.Duration - footerDuration) + 1000;

      bestFit = sameCategoryVariations.filter(x => x.Duration >= minDuration && x.Duration <= maxDuration);
      if (bestFit.length > 0) {
        return { destination: currentItem, replacement: bestFit[Math.floor(Math.random() * bestFit.length)] };
      }
    }

  }

  // nothing found
  return undefined;
}

SchedulingHelper.getVariation = async (duration, availableVariation, autoDeleteOffFooterOffset) => {

  var groupedInterstitials = [];

  var loopCount = 0;
  var durationRemaining = duration;

  while (durationRemaining > 0 && loopCount < availableVariation.length) {

    const maxDurVariation = durationRemaining + autoDeleteOffFooterOffset;

    var interstitialLessThenFooterDuration = availableVariation.filter(i => i.Duration < maxDurVariation);
    if (interstitialLessThenFooterDuration == undefined || interstitialLessThenFooterDuration.Count == 0) break;

    var bestPossibleFiller = await SchedulingHelper.getBestPossibleFiller(interstitialLessThenFooterDuration, durationRemaining, groupedInterstitials, undefined, false);

    if (bestPossibleFiller && bestPossibleFiller.Duration > 0) {
      groupedInterstitials.push(bestPossibleFiller);
      durationRemaining = durationRemaining - bestPossibleFiller.Duration;
      loopCount = 0;// reset loop count
    }
    else {
      loopCount = loopCount + 1;
    }
  }

  return groupedInterstitials;
}

SchedulingHelper.getBestPossibleFiller = async (availableVariation, remainingDuration, groupedInterstitials, lastChild, checkBackToBack = true) => {

  if (checkBackToBack && groupedInterstitials.length == 0 && lastChild && lastChild.mediaEpisode) {
    availableVariation = availableVariation.filter(x => x.MediaCategoryType.SID != lastChild.mediaEpisode.MediaCategoryType.SID)
  }
  else if (checkBackToBack && groupedInterstitials.length > 0) {
    const lastItem = groupedInterstitials[groupedInterstitials.length - 1];
    availableVariation = availableVariation.filter(x => x.MediaCategoryType.SID != lastItem.MediaCategoryType.SID)
  }

  var equalDurationItems = availableVariation.filter(i => i.Duration == remainingDuration);
  if (equalDurationItems && equalDurationItems.length > 0) return equalDurationItems[0];

  // else we will finbd closest one
  return availableVariation[Math.floor(Math.random() * availableVariation.length)];
}

SchedulingHelper.getBestChildPositionToDrop = async (children, dropItem) => {

  var validDestination = undefined;
  var childrenwithoutfooter = children.filter(x => x.Type != SCHEDULETYPE.OverRun && x.Type != SCHEDULETYPE.UnderRun);
  var isSegmentFound = false;


  for (let index = 0; index < childrenwithoutfooter.length; index++) {
    const child = childrenwithoutfooter[index];

    // we will drop in only after 1st segment start
    if (child.Type == SCHEDULETYPE.Segment) {
      isSegmentFound = true;
    }
    if (childrenwithoutfooter.some(x => x.Type == SCHEDULETYPE.Segment) && !isSegmentFound) continue;

    //means last children we just need to very last item should not be same 
    if (index == (childrenwithoutfooter.length - 1)) {

      if ((child.mediaEpisode.MediaCategoryType?.SID ?? 0) != (dropItem.MediaCategoryType?.SID ?? 0)) {
        validDestination = child;
        break;
      }

    }
    else {

      if ((child.mediaEpisode.MediaCategoryType?.SID ?? 0) != (dropItem.MediaCategoryType?.SID ?? 0) && (childrenwithoutfooter[index + 1].mediaEpisode.MediaCategoryType?.SID ?? 0) != (dropItem.MediaCategoryType?.SID ?? 0)) {
        validDestination = child;
        break;
      }

    }

  }

  return validDestination;
}

SchedulingHelper.isSpaceLeftInHeaderToPlace = async (elementToPlace, header, currentSchedule) => {

  var latestHeader = currentSchedule.find(x => x._id == header._id);

  // cannot find header. so NO
  if (latestHeader == null || latestHeader == undefined) {
    console.log("header not found");
    return false;
  }

  // if over run exists, straight NO
  if (latestHeader.children.filter(x => x.Type == SCHEDULETYPE.OverRun).length > 0) return false;

  const underRun = latestHeader.children.find(x => x.Type == SCHEDULETYPE.UnderRun);

  // if under run exists and not enough space then NO
  if (underRun && underRun.Duration < elementToPlace.Duration) return false;

  // else YES
  return true;

}

SchedulingHelper.getDestinationForAutoPlotPromo = async (currentSchedule, parentIndex, breakItem) => {

  var destinationDataItem = undefined;
  if (breakItem.Type != SCHEDULETYPE.Segment) {

    // all child of this header from this header forward
    var allChildOfThisHeader = currentSchedule[parentIndex].children.filter(x => x.Type != SCHEDULETYPE.UnderRun && x.Type != SCHEDULETYPE.OverRun && x.SlotDateTime >= breakItem.SlotDateTime);
    if (allChildOfThisHeader == undefined || allChildOfThisHeader.length == 0) return destinationDataItem;

    // Next Short
    const nextShort = allChildOfThisHeader.find(x => x.SlotDateTime > breakItem.SlotDateTime && x.mediaEpisode?.MediaCategoryType?.SID == breakItem.mediaEpisode?.MediaCategoryType?.SID);

    if (nextShort) {
      allChildOfThisHeader = allChildOfThisHeader.filter(x => x.SlotDateTime < nextShort.SlotDateTime);
      destinationDataItem = allChildOfThisHeader[allChildOfThisHeader.length - 1];
    }
    else {
      destinationDataItem = allChildOfThisHeader[allChildOfThisHeader.length - 1];
    }

  }
  else { // Normal Case

    const childrens = currentSchedule[parentIndex].children.filter(x => x.BreakNo == breakItem.BreakNo);
    if (childrens == undefined || childrens.length == 0) return destinationDataItem;
    destinationDataItem = childrens[childrens.length - 1];

  }

  return destinationDataItem;

}

SchedulingHelper.isSpaceLeftAsPerCompanyRulesInAutoFill = async (isLastHeader, currentSchedule, headerIndex, itemToPlace, payload) => {

  const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

  if ((company?.Name ?? "") == "The Q") {

    const spaceLeft = await SchedulingHelper.findSpaceLeftInHeaderToPlace(currentSchedule[headerIndex], currentSchedule);
    if (!isLastHeader && spaceLeft == 0) return false;

    // till 10 second over run is allowed
    const allowedLimit = spaceLeft + 10000;
    if (!isLastHeader && itemToPlace.Duration > allowedLimit) return false;

    if (isLastHeader && SchedulingHelper.IsPlaylist24HourReached(payload, currentSchedule)) return false;

    return true;

  }
  else return true; // for other companies auto fill allowed no matter footer

}



SchedulingHelper.getValidAutoPlotPromoBreaks = async (currentSchedule) => {

  var segmentChildrens = [];

  const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
  if ((company?.Name ?? "") == "The Q") {

    currentSchedule.map((x) => {
      // Short is also treated as segment for Promo Auto Fill
      var segments = x.children.filter((y) => y.Type == SCHEDULETYPE.Segment || (y.mediaEpisode?.MediaCategoryType?.Description ?? "").toLowerCase().includes("short"));
      segmentChildrens = [...segmentChildrens, ...segments]
    });

  }
  else {

    currentSchedule.map((x) => {
      var segments = x.children.filter((y) => y.Type == SCHEDULETYPE.Segment);
      segmentChildrens = [...segmentChildrens, ...segments]
    });

  }

  return segmentChildrens;

}


SchedulingHelper.getFlatScheduleData = (data, onlyHeader = false) => {

  let flatData = [];

  if (onlyHeader) {
    flatData = [...data]
    return flatData;
  }

  for (let i = 0; i < data.length; i++) {
    let parent = data[i];
    const children = parent.children;
    // delete parent.children;
    flatData.push(parent, ...children);

    if (children.some(x => x.IsBreak == true)) {

      let breakIndex = flatData.findIndex(child => child.IsBreak == true);
      flatData.splice(breakIndex + 1, 0, ...flatData[breakIndex].children);
    }

  }

  return flatData
}

SchedulingHelper.findDataItemById = (items, id) => {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item._id === id) {
      return item;
    }

    if (item.children) {
      const foundItem = SchedulingHelper.findDataItemById(item.children, id);
      if (foundItem) {
        return foundItem;
      }
    }
  }
  return null;
};


SchedulingHelper.getDataItemFromEvent = (e, schedulerData) => {
  const id = e?.target?.parentElement?.firstChild?.id?.toString();

  if (!id) {
    console.error('ID not found in event target.');
    return null;
  }


  let dataItem = SchedulingHelper.findDataItemById(schedulerData, id)

  if (!dataItem) {
    console.error('DataItem not found for ID:', id);
    return null;
  } else {
    return dataItem;
  }
};

SchedulingHelper.getScheduleRowIndex = (dataItem, schedulerData) => {
  let rowIndex = 0;
  let found = false;
  let newSchedulerData = schedulerData;
  for (let i = 0; i < schedulerData.length; i++) {
    if (found) {
      break;
    }
    rowIndex += 1;
    if (schedulerData[i]._id == dataItem._id) {
      found = true;
      break;
    } else {
      for (let j = 0; j < schedulerData[i].children.length; j++) {
        if (found) {
          break;
        }
        if (schedulerData[i][EXPAND_FIELD]) {
          rowIndex += 1;
        }
        const child = schedulerData[i].children[j];
        if (child._id == dataItem._id) {
          if (!schedulerData[i][EXPAND_FIELD]) {
            rowIndex += i - 1;
          }
          newSchedulerData[i][EXPAND_FIELD] = true;
          found = true;
          break;
        } else if (child[IS_BREAK]) {
          for (let k = 0; k < child.children.length; k++) {
            if (found) {
              break;
            }
            if (child[EXPAND_FIELD]) {
              rowIndex += 1;
            }
            if (child.children[k]._id == dataItem._id) {
              if (!child[EXPAND_FIELD]) {
                rowIndex += k - 1;
              }
              newSchedulerData[i][EXPAND_FIELD] = true;
              newSchedulerData[i].children[j][EXPAND_FIELD] = true;
              found = true;
              break;
            }
          }
        }
      }
    }
  }
  return { rowIndex: rowIndex - 1, updatedSchedulerData: newSchedulerData };
}

SchedulingHelper.getItemAbove = (dataItem, schedulerData) => {

  const { level_0_index, level_1_index, level_2_index } = SchedulingHelper.getChildInfo(dataItem, schedulerData);

  if (level_2_index != null) {
    if (level_2_index > 0) {
      return schedulerData[level_0_index].children[level_1_index].children[level_2_index - 1];
    } else {
      return schedulerData[level_0_index].children[level_1_index];
    }
  } else if (level_1_index != null) {
    if (level_1_index > 0) {
      const prevChild = schedulerData[level_0_index].children[level_1_index - 1];
      if (prevChild[EXPAND_FIELD]) {
        return prevChild.children[prevChild.children.length - 1];
      } else {
        return prevChild;
      }
    } else {
      return schedulerData[level_0_index];
    }
  } else {
    if (level_0_index > 0) {
      const prevParent = schedulerData[level_0_index - 1];
      if (prevParent[EXPAND_FIELD]) {
        const lastChild = prevParent.children[prevParent.children.length - 1];
        return lastChild[IS_BREAK] && lastChild[EXPAND_FIELD] ? lastChild.children[lastChild.children.length - 1] : lastChild;
      } else {
        return prevParent;
      }
    } else {
      return schedulerData[0];
    }
  }
}

SchedulingHelper.getItemBelow = (dataItem, schedulerData) => {

  const { level_0_index, level_1_index, level_2_index } = SchedulingHelper.getChildInfo(dataItem, schedulerData);
  if (level_2_index != null) {
    const childrenLength = schedulerData[level_0_index].children[level_1_index].children.length;
    if (level_2_index < childrenLength - 1 && childrenLength - 1 !== level_2_index) {
      return schedulerData[level_0_index].children[level_1_index].children[level_2_index + 1];
    }
    else {
      const nextChildIndex = level_1_index + 1;
      if (nextChildIndex < schedulerData[level_0_index].children.length) {
        return schedulerData[level_0_index].children[nextChildIndex];
      } else {
        return schedulerData[level_0_index + 1];
      }
    }
  } else if (level_1_index != null) {
    const childrenLength = schedulerData[level_0_index].children.length;
    if (level_1_index < childrenLength - 1 && childrenLength - 1 !== level_1_index) {
      if (schedulerData[level_0_index].children[level_1_index][EXPAND_FIELD]) {
        return schedulerData[level_0_index].children[level_1_index].children[0];
      } else {
        return schedulerData[level_0_index].children[level_1_index + 1];
      }
    } else {
      const nextParentIndex = level_0_index + 1;
      if (nextParentIndex === schedulerData.length) {
        return dataItem;
      } else {
        return schedulerData[nextParentIndex];
      }
    }
  } else {
    if (schedulerData[level_0_index][EXPAND_FIELD]) {
      return schedulerData[level_0_index].children[0];
    }
    const parentLength = schedulerData.length;
    if (level_0_index < parentLength - 1) {
      const nextParentIndex = level_0_index + 1;
      return schedulerData[nextParentIndex];
    } else {
      return dataItem;
    }
  }
}

SchedulingHelper.canDeleteFooter = (dataItem, schedulerData) => {
  const { level_0_index, level_1_index, level_2_index } = SchedulingHelper.getChildInfo(dataItem, schedulerData);
  if (level_1_index != null && level_2_index == null) {
    if (level_0_index == schedulerData.length - 1) {
      return true;
    }
    else if (schedulerData[level_0_index + 1]?.isLocked ?? false) {
      return false;
    }
    else {
      return true;
    }
  } else {
    // break level foote
    return true;
  }
}

SchedulingHelper.canModify = (dataItem, schedulerData) => {
  const { level_0_index, level_1_index, level_2_index, isInsideBreak } = SchedulingHelper.getChildInfo(dataItem, schedulerData);

  if(dataItem[IS_BREAK] || isInsideBreak){
    return true;
  }

  if (schedulerData[level_0_index].isLocked) {
    return false;
  } else {
    return true;
  }
}
